import React from "react";
import { useTranslation } from "react-i18next";

import { SelectionProps } from "./CurrentSelection";

export const EpriText = (props: SelectionProps) => {
  const { t } = useTranslation();
  const { parameter, classNames, newValue } = props;
  const epriText = t("t:epri.text", {
    selection: `"${parameter.translatedName}: ${newValue}"`,
  });
  return <div className={classNames.section}>{epriText}</div>;
};
