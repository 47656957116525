import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";

export function OldPathRedirect() {
  const { i18n } = useTranslation();
  const { article, session } = useParams();

  if (session && article) {
    return (
      <Navigate to={`/${i18n.language}/configuration/${article}/${session}`} />
    );
  }
  if (article) {
    return <Navigate to={`/${i18n.language}/configuration/${article}`} />;
  }
  return <Navigate to={"/"} />;
}
