import { VisualizationSettings } from "../../../../../settings";
import { appTheme } from "../../../../../theme";

export function refineVisualizationSettings(
  settings: typeof VisualizationSettings.visualization.settings,
) {
  return {
    ...settings,
    theme: {
      palette: appTheme.palette,
      semanticColors: appTheme.semanticColors,
    },
  };
}
