import { mergeStyleSets, Stack } from "@fluentui/react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ConfigurationRoutes } from "routes";

import { AppContext } from "../../context/useApp";
import { useConfigurationContext } from "../../context/useConfiguration";
import { useClipboard } from "../../hooks/useClipboard";
import { Section } from "../Section";
import { Dialog } from "./Dialog";
import { DialogButtons } from "./DialogButtons";

function simpleDialogStyle() {
  return mergeStyleSets({
    content: {
      padding: "3em 0",
      pre: {
        margin: 0,
        padding: "1em 0",
        overflowY: "auto",
      },
    },
  });
}

type SimpleDialogProps = {
  isOpen: boolean;
  label: string;
  okText: string;
  cancelText?: string;
  dismiss(): void;
  ok(): void;
};

export function ShareDialog({
  isOpen,
  dismiss,
  label,
  okText,
  cancelText,
  ok,
}: Readonly<SimpleDialogProps>) {
  const simpleDialogStyled = useMemo(() => simpleDialogStyle(), []);
  const { article, partnerId } = useParams<ConfigurationRoutes>();
  const configuration = useConfigurationContext();
  const { url } = useContext(AppContext);
  const [shareLink, setShareLink] = useState<string>("");
  const clipboard = useClipboard();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  useEffect(() => {
    if (isOpen) {
      getShareLink();
    }
  }, [isOpen]);

  useEffect(() => {
    if (shareLink !== "") {
      clipboard.copy(shareLink);
    }
  }, [shareLink]);

  function getShareLink() {
    configuration.actions
      .save()
      .then((uuid) => {
        if (partnerId) {
          setShareLink(
            `${url}/#/${currentLanguage}/partner/${partnerId}/load/${article}/${uuid}`,
          );
        } else {
          setShareLink(
            `${url}/#/${currentLanguage}/configuration/load/${article}/${uuid}`,
          );
        }
      })
      .catch((error) => {
        console.error("Failed to generate share link:", error);
        setShareLink("");
      });
  }

  return (
    <Dialog isOpen={isOpen} onDismiss={dismiss}>
      <Stack>
        <Section label={label} />
        <Stack.Item className={simpleDialogStyled.content}>
          <pre>
            <code>{shareLink}</code>
          </pre>
        </Stack.Item>
      </Stack>
      <DialogButtons
        onCancel={cancelText ? dismiss : undefined}
        cancelText={cancelText}
        okText={okText}
        onOk={ok}
      />
    </Dialog>
  );
}
