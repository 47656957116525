import { ExtendedProduct, Products } from "product";
import {
  equals,
  head,
  includes,
  isEmpty,
  keys,
  map,
  or,
  path,
  pathOr,
  replace,
  sort,
  sortBy,
  split,
  xor,
} from "ramda";

import { ParameterTO, Value } from "@encoway/c-services-js-client";

import { RowItem } from "./useConfTable";

export const COMPONENT_NAME_KEY = "KomponentenName";
export const DEFAULT_TABLE_MAX_HEIGHT = 680;

const MAX_HEIGHT_SECTION_CHARACTERISTICS = "Max_Height_Section";

export type ColumnHeading = {
  key: string;
  label: string;
};

export type SingleCell = {
  value: string;
  type: string;
};

function toCharacteristicValues(
  product: ExtendedProduct,
): (ele: string) => SingleCell {
  const getValuePath = (ele: string) => [
    "characteristicValues",
    ele,
    "values",
    0,
  ];
  const getTypePath = (ele: string) => [
    "characteristicValues",
    ele,
    "characteristicValues",
    "ref_primary_image_type",
    "values",
    0,
  ];
  const getSelectedPossibleValuePath = (ele: string, valueId: string) => [
    "characteristicValues",
    ele,
    "possibleValues",
    valueId,
    "value",
  ];

  return function (ele: string): SingleCell {
    const valueId = pathOr<string>("", getValuePath(ele), product);
    const selectedPossibleValue = pathOr<string>(
      valueId,
      getSelectedPossibleValuePath(ele, valueId),
      product,
    );

    return {
      value: selectedPossibleValue,
      type: pathOr<string>("text", getTypePath(ele), product),
    };
  };
}

export const getConfTableMaxHeight = (
  products: Products,
  article: string | undefined,
) => {
  const PATH = [
    article!,
    "characteristicValues",
    MAX_HEIGHT_SECTION_CHARACTERISTICS,
    "values",
    "0",
    "value",
  ];
  return pathOr<number>(DEFAULT_TABLE_MAX_HEIGHT, PATH, products);
};

export function getTableColumnKeys(
  data: ParameterTO,
  products: Products,
): ColumnHeading[] {
  const properties = path<string>(
    ["viewPortProperties", "ref-table-entries"],
    data,
  );
  const productKey = map<string | number, string>(
    (key) => key + "",
    keys(products),
  );
  const product = path([head(productKey)!, "characteristicValues"], products);
  if (properties) {
    return map(
      (key) => ({
        key,
        label: pathOr(key, [key, "name"], product),
      }),
      split(";", replace(/["{}]/g, "", properties)),
    );
  }
  throw new Error("Can not determine ref-table-entries from " + data.name);
}

export function toTableProducts(
  products: Products,
  columnHeadings: ColumnHeading[],
) {
  return function (
    acc: any,
    { key, selectable }: { key: string; selectable: boolean | undefined },
  ): RowItem[] {
    const product = path([key], products);
    const columnKeys = map(({ key }) => key, columnHeadings);
    if (product) {
      return [
        ...acc,
        {
          key: product.id,
          selectable,
          cells: map(toCharacteristicValues(product), columnKeys),
        },
      ];
    }
    return acc;
  };
}

export function sortRows(rows: RowItem[], selectedValue: RowItem | undefined) {
  return sort((value) => (equals(value.key, selectedValue?.key) ? 0 : 1), rows);
}

const TABLE_ARTICLE_NAME_PROPERTY_PATH = [
  "properties",
  "BomConfigurationConstants.ArticleName",
];
const MATCHING_ARTICLE_NAME_INSERT = "Einsatz";

export function isInsert(data: ParameterTO) {
  const articleName = path(TABLE_ARTICLE_NAME_PROPERTY_PATH, data);
  return equals(articleName, MATCHING_ARTICLE_NAME_INSERT);
}

const MATCHING_ARTICLE_NAME_CONTACT = "Contact";

export function isContact(data: ParameterTO) {
  const articleName = path(TABLE_ARTICLE_NAME_PROPERTY_PATH, data);
  return equals(articleName, MATCHING_ARTICLE_NAME_CONTACT);
}

export function sortValuesByTerminal(
  values: Value[],
  terminal: boolean | undefined,
): Value[] {
  return sortBy(
    (value: Value) => (terminal ? false : !value.selectable),
    values || [],
  );
}

const PRODUCT_ARTICLE_NAME_PATH = [
  "characteristicValues",
  "Artikelnummer",
  "values",
  0,
];

export function filterByProductCrossReferences(
  references: string[],
  data: ParameterTO,
): (product: ExtendedProduct) => boolean {
  return function (product: ExtendedProduct): boolean {
    const articleNumber = path<string>(PRODUCT_ARTICLE_NAME_PATH, product);

    return (
      isEmpty(references) ||
      includes(articleNumber, references) ||
      isInsert(data)
    );
  };
}

export function filterSelectedValue(
  data: ParameterTO,
  active: boolean,
): (item: RowItem) => boolean {
  const selected = pathOr("", ["selectedValues", 0, "value"], data);
  return function (item: RowItem): boolean {
    return or(!active, xor(active, equals(selected, item.key)));
  };
}
