import { ITheme, mergeStyleSets, Stack, useTheme } from "@fluentui/react";
import { useMemo } from "react";

export type CheckboxProps = {
  label: string;
  checked?: boolean;
  onChange: any;
};

function checkboxStyle(theme: ITheme) {
  return mergeStyleSets({
    root: {
      display: "flex",
      flexFlow: "column",
    },
    checkbox: {
      width: "24px",
      height: "24px",
      marginRight: "10px !important",
      position: "relative !important",
      ":checked": {
        accentColor: theme.palette.neutralLight,
      },
    },
    label: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      fontSize: "16px",
      lineHeight: "20px",
      marginBottom: "0 !important",
      color: theme.palette.neutralPrimary,
      ":hover": {
        background: theme.palette.white,
        color: theme.palette.black,
      },
    },
  });
}

export default function Checkbox(props: Readonly<CheckboxProps>) {
  const theme = useTheme();
  const checkboxStyled = useMemo(() => checkboxStyle(theme), [theme]);
  return (
    <Stack className={checkboxStyled.root}>
      <label className={checkboxStyled.label}>
        <input
          className={checkboxStyled.checkbox}
          type={"checkbox"}
          checked={props.checked}
          onChange={(e) => props.onChange(e)}
        />
        {props.label}
      </label>
    </Stack>
  );
}
