import { useEffect, useState } from "react";

import {
  distanceToPercent,
  percentToDistance,
} from "../../visualizationHelper/helper";
import { useCameraControls } from "./useCameraControls";

/**
 * Provides the relative position of the Camera between its min and max values.
 *
 */
export const useRelativeCameraPosition = () => {
  const { cameraControls, cameraDistance } = useCameraControls();

  const [relativeCameraPosition, setRelativeCameraPosition] =
    useState<number>(0);

  useEffect(() => {
    const defaultPosition = distanceToPercent(cameraDistance);
    setRelativeCameraPosition(defaultPosition);
  }, [cameraDistance]);

  const onChange = (value: number) => {
    const newCameraDistance = percentToDistance(value);
    cameraControls.dollyTo(newCameraDistance, true);
  };

  return { relativeCameraPosition, onChange };
};
