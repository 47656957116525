import { ExtendedCharacteristic } from "product";
import { forEachObjIndexed, path, pathOr } from "ramda";
import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from "react";

import { ProductContext } from "./useProducts";

type DropdownListItem = { [key: string]: string[] };

export type DialogState = {
  PERSONAL_DROPDOWN_LIST: DropdownListItem;

  getPersonalDialogData(): DropdownListItem;
};

export const DialogContext = createContext<DialogState>({
  PERSONAL_DROPDOWN_LIST: {},
  getPersonalDialogData: function () {
    throw new Error("getPersonalDialogData not initialized");
  },
});

export const DialogProvider = DialogContext.Provider;

const PERSONAL_DIALOG = "personalDialog";
const PERSONAL_DIALOG_CHARACTERISTIC_PATH = [
  "personalDialog",
  "characteristicValues",
];
const VALUES_PATH = ["values"];

function useDialog(): DialogState {
  const { getProducts } = useContext(ProductContext);
  const [personalDropdownList, setPersonalDropdownList] =
    useState<DropdownListItem>({});

  function addPersonDialogData(
    value: ExtendedCharacteristic,
    dataKey: string,
  ): void {
    const values = pathOr<string[]>([], VALUES_PATH, value);
    setPersonalDropdownList((prev) => ({
      ...prev,
      [dataKey]: values,
    }));
  }

  function getPersonalDialogData(): DropdownListItem {
    getProducts(PERSONAL_DIALOG).then((data) => {
      const characteristics = path(PERSONAL_DIALOG_CHARACTERISTIC_PATH, data);
      forEachObjIndexed(addPersonDialogData, characteristics);
    });
    return {};
  }

  return useMemo(
    () => ({
      PERSONAL_DROPDOWN_LIST: personalDropdownList,
      getPersonalDialogData,
    }),
    [personalDropdownList],
  );
}

export function DialogStore({ children }: Readonly<{ children: ReactNode }>) {
  return <DialogProvider value={useDialog()}>{children}</DialogProvider>;
}
