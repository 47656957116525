import { mergeStyleSets, Stack } from "@fluentui/react";
import { isEmpty, map, pathOr } from "ramda";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Dialog } from "../../../../components/Dialog/Dialog";
import { DialogButtons } from "../../../../components/Dialog/DialogButtons";
import { ForwardIcon } from "../../../../components/Icons";
import { Section } from "../../../../components/Section";
import { ProductContext } from "../../../../context/useProducts";
import {
  getInvalidProductNames,
  InvalidProduct,
} from "../../../../utils/moqUtils";

function simpleDialogStyle() {
  return mergeStyleSets({
    content: {
      display: "flex",
      gap: "1rem",
      padding: "3em 0",
    },
    icon: {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      svg: {
        height: "22px",
      },
    },
  });
}

type SimpleDialogProps = {
  invalidProducts: InvalidProduct[];
  isOpen: boolean;
  dismiss(): void;
  ok(): void;
};

export function MoqDialog({
  invalidProducts,
  isOpen,
  dismiss,
  ok,
}: Readonly<SimpleDialogProps>) {
  const simpleDialogStyled = useMemo(() => simpleDialogStyle(), []);
  const { t } = useTranslation();
  const [productNames, setProductNames] = useState<{ [key: string]: string }>(
    {},
  );
  const { getProducts } = useContext(ProductContext);

  async function getProductNameList() {
    const productIds = map(
      (product) => product.alternateProductId,
      invalidProducts,
    );
    if (isEmpty(productIds)) {
      setProductNames({});
      return;
    }
    const products = await getProducts(productIds);

    setProductNames(getInvalidProductNames(invalidProducts, products));
  }

  useEffect(() => {
    getProductNameList();
  }, [invalidProducts]);

  return (
    <Dialog isOpen={isOpen} onDismiss={dismiss}>
      <Stack>
        <Section label={t("t:glossary.moq.label")} />
        <Stack.Item className={simpleDialogStyled.content}>
          {map(
            (invalidProduct) => (
              <Stack>
                <Stack
                  style={{
                    display: "flex",
                    flexFlow: "row",
                    alignItems: "center",
                    gap: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  {invalidProduct.currentProduct}
                  <Stack className={simpleDialogStyled.icon}>
                    <ForwardIcon />
                  </Stack>
                  {pathOr(
                    invalidProduct.alternateProductId,
                    [invalidProduct.alternateProductId],
                    productNames,
                  )}
                </Stack>
                {invalidProduct.description}
              </Stack>
            ),
            invalidProducts,
          )}
        </Stack.Item>
      </Stack>
      <DialogButtons
        onCancel={dismiss}
        cancelText={t("t:glossary.moq.cancelMoq")}
        okText={t("t:glossary.moq.acceptMoq")}
        onOk={ok}
      />
    </Dialog>
  );
}
