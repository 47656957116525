import { DefaultButton, mergeStyleSets, PrimaryButton } from "@fluentui/react";
import { useMemo } from "react";

type DialogButtonsProps = {
  cancelText?: string;
  okText: string;
  onCancel?(): void;
  onOk(): void;
};

function buttonRowStyle() {
  return mergeStyleSets({
    root: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: "20px",
    },
  });
}

function buttonStyle() {
  return mergeStyleSets({
    root: {
      borderRadius: ".25em",
    },
  });
}

export function DialogButtons({
  cancelText,
  okText,
  onCancel,
  onOk,
}: Readonly<DialogButtonsProps>) {
  const { root } = useMemo(() => buttonRowStyle(), []);
  const defaultButton = useMemo(() => buttonStyle(), []);

  return (
    <div className={root}>
      {onCancel ? (
        <DefaultButton
          styles={defaultButton}
          onClick={onCancel}
          style={{ fontSize: "16px" }}
        >
          {cancelText}
        </DefaultButton>
      ) : (
        <div />
      )}
      <PrimaryButton
        styles={defaultButton}
        onClick={onOk}
        style={{ fontSize: "16px" }}
      >
        {okText}
      </PrimaryButton>
    </div>
  );
}
