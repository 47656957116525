import { TFunction } from "react-i18next";

import { ParameterTO } from "@encoway/c-services-js-client";
import {
  CHECKBOX_FALSE_VALUES,
  ComponentName,
} from "@encoway/cui-configurator-components";
import { ComponentFactory } from "@encoway/react-configurator";

export const isCheckBox = (parameter: ParameterTO) => {
  const viewPort: string | undefined = parameter.viewPort;
  return (
    viewPort &&
    (viewPort === ComponentName.Checkbox ||
      ComponentFactory.aliases[viewPort] === ComponentName.Checkbox)
  );
};

export const isChecked = (parameter: ParameterTO) => {
  return (
    parameter.selectedValues &&
    parameter.selectedValues.length === 1 &&
    CHECKBOX_FALSE_VALUES.indexOf(parameter.selectedValues[0].value) >= 0
  );
};

export const addUnit = (value: string, parameter: ParameterTO) => {
  if (value) {
    const unit = parameter.displayUnit?.translatedUnit;
    if (unit) {
      return `${value} ${unit}`;
    }
  }
  return value || "";
};

export const translatedValue = (parameter: ParameterTO) => {
  return parameter.values?.find((v) => v.selected)?.translatedValue;
};

export const checkboxValue = (
  parameter: ParameterTO,
  t: TFunction<"translation", undefined>,
) => {
  if (isCheckBox(parameter)) {
    return isChecked(parameter)
      ? t("t:epri.checkbox-selected")
      : t("t:epri.checkbox-deselected");
  }
};
