import { ITheme, mergeStyleSets, Stack, Text, useTheme } from "@fluentui/react";
import classNames from "classnames";
import { ConfiguratorComponentProps } from "configuration";
import { equals, find, pathOr, slice } from "ramda";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Select, { components, OptionProps } from "react-select";

import { ParameterTO, Value } from "@encoway/c-services-js-client";
import { ComponentName } from "@encoway/cui-configurator-components";

import { useConfigurationContext } from "../../../context/useConfiguration";
import { useSettings } from "../../../context/useSettings";
import { isValue, mapValues } from "./confSelectUtils";

function selectStyle(theme: ITheme) {
  return mergeStyleSets({
    root: {
      fontSize: "14px",
      selectors: {
        "&.mandatory > div": {
          borderColor: theme.palette.neutralLight,
        },
        div: {
          color: theme.palette.black,
        },
      },
    },
  });
}

function selectItemStyle(theme: ITheme) {
  return mergeStyleSets({
    root: {
      cursor: "pointer",
      borderRadius: 0,
      borderBottom: `1px solid ${theme.palette.neutralLight}`,
      selectors: {
        "&:hover": {
          borderBottom: `1px solid ${theme.palette.neutralLight}`,
        },
        "&.trigger-epri": {
          backgroundColor: theme.palette.neutralLighter,
        },
        "&.trigger-epri:hover": {
          backgroundColor: theme.palette.neutralLight,
        },
        "&.trigger-epri .text-trigger-epri": {
          color: theme.palette.neutralTertiary,
        },
        "&.trigger-epri:hover .text-trigger-epri": {
          color: theme.palette.black,
        },
      },
    },
    divider: {
      width: "100%",
      border: "1px solid #CCC",
      boxSizing: "border-box",
    },
    selectItem: {
      cursor: "pointer",
      flexDirection: "row",
    },
    selectImg: {
      padding: "0 .5em",
      height: "auto",
      width: "60px",
    },
    selectText: {
      padding: "0 .5em",
      alignSelf: "center",
      flex: "1",
      fontSize: "14px",
      color: theme.palette.black,
    },
  });
}

function isDisabled(sortedValues: any) {
  return !pathOr(false, [0, "enabled"], sortedValues);
}

const Option = (props: OptionProps<Value | { value: string }>) => {
  const { settings } = useSettings();
  const theme = useTheme();
  const { root, divider, selectItem, selectImg, selectText } =
    selectItemStyle(theme);
  if (!isValue(props.data)) {
    return <div className={divider} />;
  }
  return (
    <components.Option
      {...props}
      className={classNames(root, { "trigger-epri": !props.data.selectable })}
    >
      <Stack
        className={classNames(selectItem, {
          "has-image": props.data.imageUrl,
          "trigger-epri": !props.data.selectable,
        })}
      >
        {props.data.imageUrl && (
          <img
            className={selectImg}
            src={`${settings.showroom.url}/api/catalog/media?id=${props.data.imageUrl}`}
            alt={props.data.translatedValue}
          />
        )}
        <Text
          className={classNames(selectText, {
            "text-trigger-epri": !props.data.selectable,
            selected: props.isSelected,
          })}
          as="div"
        >
          {props.data.translatedValue}
        </Text>
      </Stack>
    </components.Option>
  );
};

type ConfSelectProps = ConfiguratorComponentProps<ParameterTO> & {
  isConnector?: boolean;
  isConnectorCounterpart?: boolean;
};

export function ConfSelect(props: ConfSelectProps) {
  const { data, isConnector, isConnectorCounterpart, onValueChanged } = props;
  const { settings } = useSettings();
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    actions: { checkIdentical },
    productCrossReferences,
    crossReferencesCharacteristics,
  } = useConfigurationContext();

  const hasUndo = equals(
    slice(
      2,
      3,
      pathOr(
        "0",
        [settings.studio.boms.configuration.undoable],
        props.data.viewPortProperties,
      ),
    ),
    "1",
  );
  const isUndoable = hasUndo && !!data.terminal && !!data.undoable;
  const { root } = useMemo(() => selectStyle(theme), [theme]);
  const sortedValues = useMemo(
    () =>
      mapValues(
        data.name,
        data.values,
        isUndoable,
        t("t:common.dropDown.undo"),
        crossReferencesCharacteristics,
        data.terminal,
        isConnector,
        isConnectorCounterpart,
      ),
    [data, productCrossReferences, crossReferencesCharacteristics],
  );

  function setSelectedValue(value: Value) {
    checkIdentical(data.name, value.value);
    onValueChanged(data, value.value);
  }

  return (
    <Select
      className={classNames(root, {
        mandatory: !data.terminal && data.mandatory,
      })}
      theme={(iTheme) => ({
        ...iTheme,
        borderRadius: 5,
        colors: {
          ...iTheme.colors,
          text: "orangered",
          primary25: theme.palette.neutralLighter,
          primary50: theme.palette.white,
          primary75: theme.palette.white,
          primary: theme.palette.neutralLight,
        },
      })}
      value={
        find((option) => isValue(option) && option.selected, sortedValues) ||
        t(`t:common.dropDown.pleaseSelect`)
      }
      getOptionLabel={(option) =>
        isValue(option) ? option.translatedValue : ""
      }
      isSearchable={equals(data.viewPort, ComponentName.FilterDropdown)}
      placeholder={t(`t:common.dropDown.pleaseSelect`)}
      isOptionSelected={(option) => isValue(option) && option.selected}
      onChange={(value: any) => setSelectedValue(value)}
      isDisabled={isDisabled(sortedValues)}
      options={sortedValues}
      components={{ Option, IndicatorSeparator: () => null }}
      menuPortalTarget={document.body}
      styles={{
        control: (baseStyle) => ({
          ...baseStyle,
          overflow: "hidden",
        }),
      }}
    />
  );
}
