import { useContext } from "react";

import {
  ProductContextProvider,
  ProductContextType,
} from "../context/ProductProvider";

export function useProduct(): ProductContextType {
  const context = useContext(ProductContextProvider);
  if (context === undefined) {
    throw new Error(
      "useProductCache must be used within a ProductCacheProvider",
    );
  }
  return context;
}
