import { useAuth0, User } from "@auth0/auth0-react";
import { ITheme, ThemeProvider } from "@fluentui/react";
import { equals, path, pathOr, reduce } from "ramda";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ConfigurationRoutes } from "routes";

import { Language } from "@encoway/c-services-js-client";

import { PROGRESS } from "../hooks/useProgress";
import { fetchLanguages, fetchResource } from "../service/appService";
import { appTheme } from "../theme";
import {
  getFullLocation,
  LocaleMapping,
  mapLocale,
  toLanguage,
} from "./appUtils";
import { ProductContext, ProductState } from "./useProducts";
import { useSettings } from "./useSettings";

export type Translation = { [p: string]: string } | undefined;

export type Translations = {
  [locale: string]: Translation;
};

export type LanguageState = {
  [parentLocale: string]: {
    [locale: string]: Language;
  };
};

export type AppState = {
  url: string;
  translations: Translations;
  languages: LanguageState;
  theme: ITheme;
  progress: PROGRESS;
  country: string | undefined;
  auth: {
    user: User | undefined;
    token: string | undefined;
  };
};

const initialState: AppState = {
  url: getFullLocation(""),
  translations: {},
  languages: {
    "de-DE": {
      "de-DE": {
        tag: "de-DE",
        displayName: "Deutsch",
      },
    },
  },
  theme: appTheme,
  progress: PROGRESS.NOT_LOADED,
  country: undefined,
  auth: {
    user: undefined,
    token: undefined,
  },
};

const initialStore: IAppStore = {
  ...initialState,
  login() {
    throw new Error("not initialized");
  },
};

type IAppStore = AppState & { login(path: string): Promise<void> };

export const AppContext = createContext<IAppStore>(initialStore);
export const AppProvider = AppContext.Provider;

const TRANSLATION_PATH = [
  "application_texts",
  "characteristicValues",
  "translations",
  "values",
  0,
  "uri",
];

function useApp({ getProducts }: ProductState): IAppStore {
  const { settings, axios } = useSettings();
  const { locale: rawLocale } = useParams<ConfigurationRoutes>();
  const [languageMapping, setLanguageMapping] = useState<LocaleMapping>({});
  const [locale, country] = mapLocale(languageMapping, rawLocale);
  const { i18n } = useTranslation();
  const [app, setApp] = useState<AppState>(initialState);
  const { user, isAuthenticated, getIdTokenClaims, loginWithRedirect } =
    useAuth0();

  const lang_mapping = settings.studio.lang_mapping;
  const PATH = [
    "lang_mapping",
    "characteristicValues",
    "lang_mapping_file",
    "values",
    0,
    "uri",
  ];
  const getLangMapping = async () => {
    const uri = await getProducts(lang_mapping);
    const uriValue = pathOr("", PATH, uri);
    setLanguageMapping(
      await fetchResource<LocaleMapping>(axios, uriValue, "de-DE"),
    );
  };

  useEffect(() => {
    getLangMapping();
  }, []);

  useEffect(() => {
    (async () => {
      const currentLanguage = i18n.language;

      if (
        !app.translations[currentLanguage] &&
        !equals(app.progress, PROGRESS.ERROR)
      ) {
        const newLanguages = reduce(
          toLanguage,
          {},
          await fetchLanguages(axios, currentLanguage),
        );
        const products = await getProducts(settings.studio.application);
        const languageUri = path<string>(TRANSLATION_PATH, products);
        if (languageUri) {
          const newTranslation = await fetchResource<Translation>(
            axios,
            languageUri,
            currentLanguage,
          );
          await i18n.addResourceBundle(currentLanguage, "t", newTranslation);
          setApp((prev) => ({
            ...prev,
            progress: PROGRESS.LOADED,
            languages: {
              ...app.languages,
              [currentLanguage]: newLanguages,
            },
            translations: {
              ...app.translations,
              [currentLanguage]: newTranslation,
            },
            theme: {
              ...app.theme,
              //...newTheme
            },
          }));
          //hack to switch cui configurator theme
          //loadTheme(newTheme);
        } else {
          setApp((prev) => ({
            ...prev,
            progress: PROGRESS.ERROR,
          }));
          console.warn(`language ${locale} is not set as language`);
        }
      }
    })();
  }, [getProducts, i18n.language]);

  useEffect(() => {
    i18n.changeLanguage(locale).then();
    setApp((prev) => ({ ...prev, country }));
  }, [locale, country]);

  useEffect(() => {
    if (isAuthenticated) {
      getIdTokenClaims().then((token) => {
        sessionStorage.setItem(
          "auth",
          JSON.stringify({ token: token?.__raw, user }),
        );
        setApp((prev) => ({ ...prev, auth: { token: token?.__raw, user } }));
      });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const newAuth = sessionStorage.getItem("auth");
    if (newAuth) {
      setApp((prev) => ({ ...prev, auth: JSON.parse(newAuth) }));
    }
  }, []);

  function login(path: string) {
    return loginWithRedirect({ appState: { path } });
  }

  return { ...app, login };
}

export function AppStore({ children }: Readonly<{ children: ReactNode }>) {
  const productStore = useContext(ProductContext);
  const appStore = useApp(productStore);

  if (
    equals(appStore.progress, PROGRESS.LOADED) ||
    equals(appStore.progress, PROGRESS.ERROR)
  ) {
    return (
      <AppProvider value={appStore}>
        <ThemeProvider theme={appStore.theme}>{children}</ThemeProvider>
      </AppProvider>
    );
  }
  return null;
}
