import {
  ITheme,
  mergeStyleSets,
  PrimaryButton,
  Stack,
  useTheme,
} from "@fluentui/react";
import classNames from "classnames";
import { ConfiguratorComponentProps } from "configuration";
import { addIndex, equals, includes, lt, map, reject } from "ramda";
import { useMemo, useState } from "react";

import { ContainerTO } from "@encoway/c-services-js-client";
import { ComponentFactory } from "@encoway/react-configurator";

import { mediaQuery } from "../../../theme";

function tabStyle(theme: ITheme) {
  return mergeStyleSets({
    root: {},
    button: {
      padding: "10px 5px",
      minWidth: "initial",
      height: "initial",
      display: "flex",
      justifyContent: "center",
      counterIncrement: "tabCount",
      flexGrow: "0",
      flex: "1",
      selectors: {
        ":before": {
          content: "counter(tabCount) '.'",
          paddingRight: "2px",
        },
        "&.past": {},
        "&.selected, &:focus": {
          color: theme.palette.white,
          backgroundColor: theme.palette.themePrimary,
        },
      },
      ":not(.selected)": {
        [mediaQuery.md]: {
          flex: ".5",
        },
        span: {
          [mediaQuery.md]: {
            display: "none",
          },
        },
      },
    },
    content: {
      flexDirection: "column",
      height: "100%",
      selectors: {
        "> div, > div > div": {
          height: "100%",
        },
      },
    },
    viewport: {
      width: "100%",
    },
    nextButtonContainer: {
      flex: "1 1 100%",
      alignSelf: "end",
      display: "grid",
      gridTemplateColumns: "1fr",
      padding: "50px 20%",
      gap: "20px",
    },
    nextButton: {
      padding: "20px 5px",
    },
  });
}

export function ConfTab({
  data,
  ...props
}: Readonly<ConfiguratorComponentProps<ContainerTO>>) {
  const theme = useTheme();
  const tabStyled = useMemo(() => tabStyle(theme), [theme]);
  const [selected, setSelected] = useState(0);

  const selectedViewPort = useMemo(
    () =>
      ComponentFactory.instanceOf(data.children[selected].viewPort!, {
        ...props,
        data: data.children[selected],
      }),
    [data, selected],
  );

  const children = reject(
    (child: ContainerTO) =>
      includes(child.name, ["Visualization", "Dimension"]),
    data.children,
  );

  if (equals(children.length, 1)) {
    return (
      <Stack className={tabStyled.content}>
        <Stack.Item className={tabStyled.viewport}>
          {selectedViewPort}
        </Stack.Item>
      </Stack>
    );
  }

  return (
    <>
      <Stack className={tabStyled.root}>
        {children &&
          addIndex<ContainerTO>(map)(
            (container: ContainerTO, index: number) => (
              <PrimaryButton
                key={`conf-tab-${container.id}`}
                className={classNames(tabStyled.button, {
                  selected: equals(index, selected),
                  past: lt(index, selected),
                })}
                onClick={() => setSelected(index)}
              >
                {container.translatedName}
              </PrimaryButton>
            ),
            children,
          )}
      </Stack>
      <Stack className={tabStyled.content}>
        <Stack.Item className={tabStyled.viewport}>
          {selectedViewPort}
        </Stack.Item>
      </Stack>
    </>
  );
}
