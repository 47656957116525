import { equals, isEmpty, path } from "ramda";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

import { createUrl, isStandalone } from "./utils";

export const ENCOWAY_ARTICLE_KEY = "encoway_article";
export const ENCOWAY_SESSION_KEY = "encoway_session";

function getDataAttribute(attribute: string): string | undefined {
  return path<string>(["dataset", attribute], document.querySelector("#root"));
}

function isStoredArticleValid(
  article: string,
  storedArticle: string | null,
): boolean {
  return equals(article, storedArticle || "");
}

const dataProduct = getDataAttribute("product");
const dataLanguage = getDataAttribute("language");

export function DetermineRoute() {
  const { i18n } = useTranslation();

  if (
    dataProduct &&
    !isStoredArticleValid(
      dataProduct,
      localStorage.getItem(ENCOWAY_ARTICLE_KEY),
    )
  ) {
    localStorage.removeItem(ENCOWAY_ARTICLE_KEY);
    localStorage.removeItem(ENCOWAY_SESSION_KEY);
  }

  const storedArticle = localStorage.getItem(ENCOWAY_ARTICLE_KEY) || "";
  const storedSession = localStorage.getItem(ENCOWAY_SESSION_KEY);
  const session = storedSession || "";

  if (isStandalone()) {
    const article = storedArticle || "Fiber_Optic_Configurator";
    return (
      <Navigate
        to={createUrl(i18n.language, "configuration", article, session)}
      />
    );
  }

  if (dataProduct && dataLanguage) {
    const article = storedArticle || dataProduct;
    const dataShareId = getDataAttribute("shareId");

    if (dataShareId && !isEmpty(dataShareId)) {
      const targetUrl = createUrl(
        dataLanguage,
        "configuration",
        "load",
        article,
        dataShareId,
      );

      return <Navigate to={targetUrl} />;
    }

    return (
      <Navigate
        to={createUrl(dataLanguage, "configuration", article, session)}
      />
    );
  }
  throw new Error(
    "Could not determine navigation route because dataset product or language is undefined",
  );
}
