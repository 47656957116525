import { ITheme, mergeStyleSets, Stack, useTheme } from "@fluentui/react";
import classNames from "classnames";
import { ConfiguratorComponentProps } from "configuration";
import { isNil } from "ramda";
import { ChangeEvent, useMemo } from "react";

import { ParameterTO } from "@encoway/c-services-js-client";

import Checkbox from "../../../components/Checkbox";
import { useConfigurationContext } from "../../../context/useConfiguration";
import { returnShortText } from "./ConfCheckboxUtils";

function sectionStyle(theme: ITheme) {
  return mergeStyleSets({
    root: {
      display: "flex",
      flexDirection: "row",
      ":hover .ms-Checkbox-checkbox": {
        background: theme.palette.white,
        borderColor: theme.palette.neutralTertiary,
      },
    },
  });
}

export function ConfCheckbox(
  props: Readonly<ConfiguratorComponentProps<ParameterTO>>,
) {
  const theme = useTheme();
  const sectionStyled = useMemo(() => sectionStyle(theme), [theme]);
  const textValue = useMemo(() => returnShortText(props.data), [props.data]);
  const {
    actions: { setIsIdenticalChecked },
  } = useConfigurationContext();

  function checkboxState(state: ChangeEvent<HTMLInputElement> | undefined) {
    if (!isNil(state)) {
      props.onValueChanged(props.data, state.target.checked ? 1 : 0);
      setIsIdenticalChecked(state.target.checked);
    }
  }

  return (
    <Stack key={props.data.id} className={classNames(sectionStyled.root)}>
      <Checkbox label={textValue} onChange={checkboxState} />
    </Stack>
  );
}
