import { ConfiguratorComponentProps } from "configuration";

import { ParameterTO } from "@encoway/c-services-js-client";

import { hiddenInvalidNumberValues } from "./confParameterUtils";

export const useConfParameter = (
  props: ConfiguratorComponentProps<ParameterTO>,
): ConfiguratorComponentProps<ParameterTO> => {
  const { originalMinValue, originalMaxValue } = props.data;

  return {
    ...props,
    data: {
      ...props.data,
      minValue: originalMinValue,
      maxValue: originalMaxValue,
      values: hiddenInvalidNumberValues(props.data),
    },
  };
};
