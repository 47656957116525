import { ITheme, mergeStyles, useTheme } from "@fluentui/react";
import { SelectedConnectorParameter } from "configuration";
import { Products } from "product";
import { equals, map, pathOr, pipe, toPairs } from "ramda";
import { useCallback, useContext, useMemo } from "react";
import { ReactSVG } from "react-svg";

import { getSelectedConnectorParameters } from "../../../../../context/configurationUtils";
import { useConfigurationContext } from "../../../../../context/useConfiguration";
import { ProductContext } from "../../../../../context/useProducts";
import { useTab } from "../../../../../context/useTab";
import { DEFAULT_IMAGE_PATHS } from "../../../../visualization/vis3d/vis3dConstants";

interface SectionProps {
  id: string;
  icon: string;
  style: string;
  onClick: () => void;
}

function componentStyle(
  theme: ITheme,
  configured: boolean,
  inProcess: boolean,
) {
  return mergeStyles({
    width: "40px",
    height: "40px",
    color: configured
      ? theme.palette.themePrimary
      : theme.palette.blackTranslucent40,
    transform: inProcess ? "scale(1.15)" : "scale(1)",
    cursor: "pointer",
    pointerEvents: "all",
    ":hover": {
      transform: "scale(1.15)",
    },
  });
}

function getConnectorDefaultImage(
  article: string | undefined,
  products: Products,
  imagePath: (string | number)[],
) {
  return pathOr("", [article!, ...imagePath], products);
}

export function VisualizationSectionIcons() {
  const { products } = useContext(ProductContext);
  const { article, guiTO } = useConfigurationContext();
  const theme = useTheme();

  const { openTab, setOpen } = useTab();

  const getConnectorDefaultImageWrapper = useCallback(
    (imagePath: (string | number)[]) =>
      getConnectorDefaultImage(article, products, imagePath),
    [article, products],
  );

  const setOpenHandler = (connectorParameter: string) => {
    if (isOpen(connectorParameter)) {
      setOpen("");
      return;
    }
    setOpen(connectorParameter);
  };

  const isSelected = (connectorParameter: string) =>
    getSelectedConnectorParameters(guiTO).some(
      (param: SelectedConnectorParameter) => {
        return param.name === connectorParameter && param.value;
      },
    );

  const isOpen = (connectorParameter: string) => {
    return equals(connectorParameter, openTab.active);
  };
  const sections = useMemo(
    () => ({
      insert: {
        icon: getConnectorDefaultImageWrapper(DEFAULT_IMAGE_PATHS.INSERT),

        style: (() => {
          return componentStyle(
            theme,
            isSelected("Einsatz"),
            isOpen("cb-insert"),
          );
        })(),
        onClick: () => setOpenHandler("cb-insert"),
      },
      casing: {
        icon: getConnectorDefaultImageWrapper(DEFAULT_IMAGE_PATHS.CASING),
        style: (() => {
          return componentStyle(
            theme,
            isSelected("Housing"),
            isOpen("cb-housing"),
          );
        })(),
        onClick: () => setOpenHandler("cb-housing"),
      },
      connector: {
        icon: getConnectorDefaultImageWrapper(DEFAULT_IMAGE_PATHS.CONNECTOR),
        style: (() => {
          return componentStyle(
            theme,
            isSelected("Contact"),
            isOpen("cb-contact"),
          );
        })(),
        onClick: () => setOpenHandler("cb-contact"),
      },
    }),
    [getConnectorDefaultImageWrapper, guiTO, theme, openTab],
  );

  return (
    <div>
      {pipe(
        toPairs,
        map(([id, props]: [string, SectionProps]) => ({ ...props, id })),
        map((section) => (
          <ReactSVG
            key={section.id}
            src={section.icon}
            className={section.style}
            onClick={section.onClick}
          />
        )),
      )(sections)}
    </div>
  );
}
