import { ITheme, mergeStyleSets, Stack, Text, useTheme } from "@fluentui/react";
import { dec, equals, gt, inc, not } from "ramda";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { BomContext } from "../../../context/useBom";
import { numberUtilsCurrency } from "../../../utils";
import { BomAmountInput } from "./BomAmountInput";

function bomTotalStyle(theme: ITheme) {
  return mergeStyleSets({
    totalPrice: {
      color: theme.palette.black,
      fontSize: "18px",
      fontWeight: "600",
      padding: "1.5em 0",
    },
    inputWrapper: {
      padding: "1em 0 .5em",
      background: theme.palette.white,
      display: "grid",
      gridTemplateColumns: "6fr 4fr",
      gridGap: ".2em",
    },
  });
}

export function BomTotal() {
  const { visibility, total, setAmount } = useContext(BomContext);
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const bomTotalStyled = useMemo(() => bomTotalStyle(theme), [theme]);
  const { totalPrice, inputWrapper } = bomTotalStyled;

  function onIncrease() {
    setAmount(inc(parseInt(`${total.amount}`)));
  }

  function onDecrease() {
    if (gt(parseInt(`${total.amount}`), 1)) {
      setAmount(dec(parseInt(`${total.amount}`)));
    }
  }

  return (
    <>
      {not(equals(visibility.amount, "hidden")) && (
        <Stack className={inputWrapper}>
          <Stack>
            <Stack>{t("t:glossary.checkout.amount_label")}</Stack>
            <BomAmountInput
              onChange={(e) => setAmount(e.currentTarget.value)}
              onIncrease={onIncrease}
              onDecrease={onDecrease}
              value={total.amount}
            />
          </Stack>
        </Stack>
      )}
      <Text className={totalPrice} variant="xLarge">
        {visibility.price
          ? numberUtilsCurrency(
              i18n.language,
              total.currencyCode,
            ).toLocalizedString(total.price * total.amount)
          : t("t:glossary.checkout.priceOnRequest")}
      </Text>
    </>
  );
}
