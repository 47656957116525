import { Stack } from "@fluentui/react";
import { BomProduct } from "bom";
import { equals, find, join, map, toLower } from "ramda";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ConfigurationRoutes } from "routes";

import { Dialog } from "../../../components/Dialog/Dialog";
import { DialogButtons } from "../../../components/Dialog/DialogButtons";
import { Section } from "../../../components/Section";
import { BomContext } from "../../../context/useBom";
import { useSettings } from "../../../context/useSettings";
import { useClipboard } from "../../../hooks/useClipboard";
import { BomItem } from "./BomItem";

type SimpleDialogProps = {
  isOpen: boolean;
  dismiss(): void;
};

function rowsToCSVString(bom: BomProduct[], metricTranslation: string): string {
  const bomItems = map(
    (item) => [
      item.translatedProductName,
      find(
        (char) => equals(char.characteristicId, "Artikelnummer"),
        item.productCharacteristics,
      )?.values[0] || item.productId,
      item.isCable ? item.amount + metricTranslation : item.amount,
    ],
    bom,
  );
  return join("\n", map(join(","), bomItems));
}

const modalStyles = {
  main: {
    width: "880px",
    overflowY: "hidden",
    borderRadius: "6px",
  },
};

export function BomCopyDialog({
  isOpen,
  dismiss,
}: Readonly<SimpleDialogProps>) {
  const { settings } = useSettings();
  const { article } = useParams<ConfigurationRoutes>();
  const { copy } = useClipboard();
  const { t } = useTranslation();
  const { bom } = useContext(BomContext);
  const isServo = equals(
    toLower(settings.studio.configurator.fiber),
    toLower(article || ""),
  );

  function copyAndClose() {
    copy(rowsToCSVString(bom, t("t:glossary.checkout.metres_short")));
    dismiss();
  }

  return (
    <Dialog isOpen={isOpen} onDismiss={dismiss} styles={modalStyles}>
      <Stack
        style={{ minHeight: "250px", paddingBottom: "1em", overflow: "hidden" }}
      >
        <Section label={t("t:common.copy.label")} />
        <Stack style={{ paddingRight: "1em", overflow: "auto" }}>
          <Stack.Item>
            {map(
              (bomProduct) => (
                <BomItem
                  key={bomProduct.translatedProductName}
                  withId={!isServo}
                  bomProduct={bomProduct}
                />
              ),
              bom,
            )}
          </Stack.Item>
        </Stack>
      </Stack>
      <DialogButtons
        onCancel={dismiss}
        cancelText={t("t:common.button.cancel")}
        okText={t("t:common.button.copyBom")}
        onOk={copyAndClose}
      />
    </Dialog>
  );
}
