import { createTheme } from "@fluentui/react";
import { MediaQuery } from "theme";

export const mediaQuery: MediaQuery = {
  sm: "@media only screen and (max-width:576px)",
  md: "@media only screen and (max-width:768px)",
  lg: "@media only screen and (max-width:992px)",
  xl: "@media only screen and (max-width:1200px)",
  xxl: "@media only screen and (max-width:1800px)",
};

/**
 * Application Theme
 * overwritten from useApp imported from studio characteristic "theme" in content block "application"
 */
export const appTheme = createTheme({
  palette: {
    themePrimary: "#ff7002",
    themeLighterAlt: "#fff9f5",
    themeLighter: "#ffe9d6",
    themeLight: "#ffd5b3",
    themeTertiary: "#ffab66",
    themeSecondary: "#ff841f",
    themeDarkAlt: "#e65100",
    themeDark: "#c25700",
    themeDarker: "#8f4000",
    neutralLighterAlt: "#fcfcfc",
    neutralLighter: "#f6f6f6",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralPrimaryAlt: "#3b3a39",
    neutralPrimary: "#707070",
    neutralDark: "#201f1e",
    tealDark: "#00838f",
    black: "#212121",
    white: "#FFFFFF",
    red: "#ff5252",
  },
  components: {
    body: {
      styles: {
        background: "black",
      },
    },
    PrimaryButton: {
      styles: {
        root: {
          borderRadius: "0",
          backgroundColor: "#ff7002",
          color: "#ffffff",
          border: "0",
          padding: "24px 28px",
          "font-size": "18px",
          selectors: {
            span: {
              fontWeight: "600",
            },
            "&.is-disabled": {
              color: "#b2b2b2",
              backgroundColor: "#e0e0e0",
              fontWeight: "normal",
            },
          },
        },
        rootFocused: {
          outline: "0",
          border: "0",
          backgroundColor: "#e65100",
        },
        rootHovered: {
          outline: "0",
          border: "0",
          color: "#ffffff",
          backgroundColor: "#e65100",
          boxShadow: "0 2px 8px 0 rgba(255, 112, 2, 0.44)",
        },
        rootPressed: {
          outline: "0",
          border: "0",
          backgroundColor: "#e65100",
          boxShadow: "0 2px 8px 0 rgba(255, 112, 2, 0.44)",
        },
      },
    },
    DefaultButton: {
      styles: {
        root: {
          borderRadius: "0",
          backgroundColor: "#f3f2f1",
          color: "#9d7b70",
          border: "0",
          fontSize: "18px",
          padding: "24px 28px",
          width: "100%",
          selectors: {
            "> span": {
              fontWeight: "600",
            },
          },
        },
        rootFocused: {
          outline: "0",
          border: "0",
          backgroundColor: "#edebe9",
        },
        rootHovered: {
          border: "0",
          color: "#9d7b70",
          backgroundColor: "#e8e8e8",
        },
        rootPressed: {
          outline: "0",
          border: "0",
          color: "#ffffff",
          backgroundColor: "#d9d9d9",
        },
      },
    },
  },
  semanticColors: {
    disabledText: "#434344",
    warningBackground: "#FFFFFF",
    warningIcon: "#FF7100",
    errorIcon: "#DD6364",
    errorText: "#DD6364",
    errorBackground: "#DD636426",
  },
  defaultFontStyle: {
    fontFamily: "Source Sans Pro",
  },
  fonts: {
    xxLargePlus: {
      color: "inherit",
      fontWeight: "600",
      fontSize: "36px",
    },
    xxLarge: {
      color: "inherit",
      fontWeight: "600",
      fontSize: "30px",
    },
    xLarge: {
      color: "inherit",
      fontWeight: "600",
      fontSize: "22px",
    },
    large: {
      color: "inherit",
      fontWeight: "600",
      fontSize: "20px",
    },
    mediumPlus: {
      fontSize: "18px",
    },
    medium: {
      fontSize: "16px",
    },
    small: {
      fontSize: "14px",
    },
    xSmall: {
      fontSize: "12px",
    },
  },
});
