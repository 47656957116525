import { mergeStyleSets, Text } from "@fluentui/react";
import { ConfiguratorComponentProps } from "configuration";
import { equals } from "ramda";
import { useMemo } from "react";
import urlRegex from "url-regex";

import { ParameterTO } from "@encoway/c-services-js-client";

import { getTranslatedValue } from "./confDisplayUtils";

function confDisplayStyle() {
  return mergeStyleSets({
    text: {
      a: {
        wordBreak: "break-word",
      },
    },
  });
}
function Value({
  value,
  unit,
}: Readonly<{ value: string; unit: string | undefined }>) {
  if (equals(value, "")) {
    return <>&nbsp;</>;
  }
  if (urlRegex().test(value)) {
    return (
      <a className="parameter" href={value} target="_blank" rel="noreferrer">
        {value}
      </a>
    );
  }
  return (
    <>
      {value} {value && unit}
    </>
  );
}

export const ConfDisplay = (props: ConfiguratorComponentProps<ParameterTO>) => {
  const confDisplayStyled = useMemo(() => confDisplayStyle(), []);
  const value = getTranslatedValue(props.data);
  const unit = props.data.baseUnit?.translatedUnit;
  return (
    <div className="parameter-wrapper-content">
      <div className="content is-label">
        <Text variant="medium" className={confDisplayStyled.text}>
          <Value value={value} unit={unit} />
        </Text>
      </div>
    </div>
  );
};
