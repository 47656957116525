import { AxiosInstance } from "axios";
import { BomProduct } from "bom";

export interface CheckoutDTO {
  partsList: BomProduct[];
  amount: number;
  salutation: string;
  surname: string;
  lastname: string;
  customerNumber: string;
  emailAddress: string;
  telephone: string;
  company: string;
  department: string;
  role: string;
  country: string;
  countryCode: string;
  location: string;
  street: string;
  comment: string;
  checkNewsletter: boolean;
  checkContactTelephone: boolean;
  checkContactEmail: boolean;
  configurationUrl: string;
  configurationType: string;
}

export async function sendConfirmationMailPublic(
  axios: AxiosInstance,
  configurationId: string,
  checkoutDTO: CheckoutDTO,
  locale: string,
): Promise<void> {
  await axios.post(
    `lapp-api/mailing/${configurationId}/confirmation`,
    checkoutDTO,
    { headers: { "Accept-Language": locale } },
  );
}

export async function sendConfirmationMail(
  axiosSimple: AxiosInstance,
  configurationId: string,
  checkoutDTO: CheckoutDTO,
  locale: string,
  token: string,
): Promise<void> {
  await axiosSimple.post(
    `lapp-api/mailing/auth/${configurationId}/confirmation`,
    checkoutDTO,
    {
      headers: { "Accept-Language": locale, Authorization: `Bearer ${token}` },
    },
  );
}
