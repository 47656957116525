import { ITheme, mergeStyleSets, Stack, useTheme } from "@fluentui/react";
import { ConfiguratorComponentProps } from "configuration";
import { map } from "ramda";
import { useMemo } from "react";

import { ContainerTO } from "@encoway/c-services-js-client";
import { ComponentFactory } from "@encoway/react-configurator";

import { useTab } from "../../../../context/useTab";
import { mediaQuery } from "../../../../theme";

function tabStyle(theme: ITheme) {
  return mergeStyleSets({
    root: {},
    button: {
      padding: "10px 5px",
      minWidth: "initial",
      height: "initial",
      display: "flex",
      justifyContent: "center",
      counterIncrement: "tabCount",
      flexGrow: "0",
      flex: "1",
      selectors: {
        ":before": {
          content: "counter(tabCount) '.'",
          paddingRight: "2px",
        },
        "&.past": {},
        "&.selected, &:focus": {
          color: theme.palette.white,
          backgroundColor: theme.palette.themePrimary,
        },
      },
      ":not(.selected)": {
        [mediaQuery.md]: {
          flex: ".5",
        },
        span: {
          [mediaQuery.md]: {
            display: "none",
          },
        },
      },
    },
    content: {
      flexDirection: "column",
      selectors: {
        "> div, > div > div": {
          height: "100%",
        },
      },
    },
    viewport: {
      display: "grid",
      gridTemplateColumns: "295px 1fr",
      gap: "0px",
      width: "100%",
      [mediaQuery.md]: {
        gridTemplateColumns: "1fr",
      },
    },
    nextButtonContainer: {
      flex: "1 1 100%",
      alignSelf: "end",
      display: "grid",
      gridTemplateColumns: "1fr",
      padding: "50px 20%",
      gap: "20px",
    },
    nextButton: {
      padding: "20px 5px",
    },
  });
}

export function ConnectorTab({
  data,
  ...props
}: Readonly<ConfiguratorComponentProps<ContainerTO>>) {
  const theme = useTheme();
  const tabStyled = useMemo(() => tabStyle(theme), [theme]);
  const { openTab, setOpen, onMore } = useTab();

  return (
    <Stack className={tabStyled.content}>
      <Stack.Item className={tabStyled.viewport}>
        {map(
          (container) =>
            ComponentFactory.instanceOf(container.viewPort!, {
              ...props,
              key: container.id,
              data: container,
              open: openTab,
              setOpen: setOpen,
              onMore,
            }),
          data.children,
        )}
      </Stack.Item>
    </Stack>
  );
}
