import { find, path, pathOr } from "ramda";
import { useEffect, useState } from "react";

import { GuiTO, ParameterTO } from "@encoway/c-services-js-client";

import { CableDimensionProps } from "../pages/configurator/fiber/visualization/CableDimension";

export function useDimensions(guiTO?: GuiTO) {
  const [dimensions, setDimensions] = useState<CableDimensionProps>(
    determineDimensions(guiTO),
  );

  function determineDimensions(guiTO?: GuiTO): CableDimensionProps {
    if (guiTO) {
      const dimensionsContainer = find(
        (container) => container.name === "Dimension",
        guiTO.rootContainer.children,
      );
      const visContainer = find(
        (container) => container.name === "Visualization",
        guiTO.rootContainer.children,
      );
      if (visContainer && dimensionsContainer) {
        const numberOfFibersParameter: ParameterTO = path(
          ["parameters", 2],
          dimensionsContainer,
        );
        const numberOfFibersName = pathOr(
          null,
          ["translatedName"],
          numberOfFibersParameter,
        );
        const numberOfFibers = pathOr(
          null,
          ["selectedValues", 0, "value"],
          numberOfFibersParameter,
        );
        const numberOfFibersLabel = numberOfFibers
          ? numberOfFibersName + " = " + numberOfFibers
          : null;
        const lengthParam = pathOr(
          null,
          ["parameters", 0, "selectedValues", 0, "value"],
          dimensionsContainer,
        );
        const lengthUnit = pathOr(
          "m",
          ["displayUnit", "translatedUnit"],
          lengthParam,
        );
        const length = lengthParam ? lengthParam + lengthUnit : null;
        const splitDimensions = Boolean(
          pathOr(
            0,
            ["parameters", 1, "selectedValues", 0, "value"],
            dimensionsContainer,
          ),
        );
        return {
          splitDimensions: splitDimensions,
          values: [
            null,
            numberOfFibersLabel,
            length,
            numberOfFibersLabel,
            null,
          ],
        };
      }
    }
    return {
      splitDimensions: false,
      values: [],
    };
  }

  useEffect(
    function () {
      setDimensions(determineDimensions(guiTO));
    },
    [guiTO],
  );

  return dimensions;
}
