import { mergeStyleSets, Stack, Text } from "@fluentui/react";
import classNames from "classnames";
import { addIndex, equals, map, path, pathOr } from "ramda";
import { useMemo } from "react";

import { useConfigurationContext } from "../../../../context/useConfiguration";
import { useDimensions } from "../../../../hooks/useDimensions";
import { useVisualization } from "../../../../hooks/useVisualization";

export type CableDimensionProps = {
  values: Array<string | null>;
  splitDimensions: boolean;
};

function componentStyle() {
  return mergeStyleSets({
    wrapper: {
      height: "44px",
      display: "flex",
      flexDirection: "row",
    },
    root: {
      fontSize: "11px",
      width: "100%",
      height: "20px",
      bottom: "-10px",
      padding: "0 5px",
      color: "c6c6c6",
    },
    cableDimension: {
      width: "100%",
      height: "100%",
      position: "relative",
      borderLeft: "1px #c6c6c6 solid",
      borderRight: "1px #c6c6c6 solid",
      "&:before": {
        content: "''",
        position: "absolute",
        borderBottom: "1px #c6c6c6 solid",
        height: "100%",
        width: "100%",
        transform: "translateY(-50%)",
      },
    },
    cableDimensionLabel: {
      color: "#c6c6c6",
      display: "flex",
      fontSize: "11px",
      justifyContent: "center",

      "&:first-child": {
        paddingTop: "12px",
      },
    },
  });
}

export function CableDimension() {
  const { guiTO } = useConfigurationContext();
  const { values, splitDimensions } = useDimensions(guiTO);
  const { vis, visSettings } = useVisualization();
  const { root, cableDimension, cableDimensionLabel, wrapper } = useMemo(
    () => componentStyle(),
    [],
  );
  return (
    <div
      className={wrapper}
      style={{ width: visSettings.width, padding: visSettings.padding }}
    >
      {addIndex(map)((value, index) => {
        if (value !== null && vis[index].parameter?.terminal) {
          return (
            <Stack
              className={classNames(root, [
                "cable-dimension-wrapper",
                { "split-dimensions": splitDimensions },
              ])}
              style={{
                minWidth: pathOr(0, [index, "vis_min_width"], vis),
                flex: path([index, "vis_stretched"], vis) ? 1 : 0,
              }}
            >
              <Stack className={cableDimension}>
                <Text key={index} className={cableDimensionLabel}>
                  {value}
                </Text>
              </Stack>
            </Stack>
          );
        }
        if (!equals(path([index, "vis_default_value"], vis), "0")) {
          return (
            <Stack
              className={root}
              style={{
                minWidth: pathOr(0, [index, "vis_min_width"], vis),
                flex: path([index, "vis_stretched"], vis) ? 1 : 0,
              }}
            />
          );
        }
        return null;
      }, values)}
    </div>
  );
}
