import { equals, head } from "ramda";

import { ParameterTO, Value } from "@encoway/c-services-js-client";

export function getTranslatedValue(data: ParameterTO) {
  const value = data.values ? head<Value>(data.values) : undefined;
  if (value && !equals(value.selectionSource, "NOT_SET")) {
    return value.translatedValue;
  }
  const selectedValue = data.selectedValues
    ? head<Value>(data.selectedValues)
    : undefined;
  if (selectedValue && !equals(selectedValue?.selectionSource, "NOT_SET")) {
    return selectedValue.translatedValue;
  }
  return "";
}
