import { Auth0Provider } from "@auth0/auth0-react";
import { ReactNode } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

import { useSettings } from "./context/useSettings";

function onRedirectCallback(navigate: NavigateFunction) {
  return function ({ path }: any) {
    if (path) {
      navigate(path, { replace: true });
    }
  };
}

export const AuthProvider = ({ children }: { children?: ReactNode }) => {
  const { settings } = useSettings();
  const navigate = useNavigate();
  return (
    <Auth0Provider
      domain={settings.oAuth.openIdIssuerUrl}
      clientId={settings.oAuth.clientId}
      redirectUri={`${window.location.origin}${window.location.pathname}`}
      useRefreshTokens={true}
      onRedirectCallback={onRedirectCallback(navigate)}
    >
      {children}
    </Auth0Provider>
  );
};
