import { pathOr } from "ramda";

import { ParameterTO } from "@encoway/c-services-js-client";

const SELECTED_VALUES = "selectedValues";
const VALUE = "value";
const SHORT_TEXT = "shortText";
const DEFAULT_VALUE_EMPTY_STRING = "";
const DEFAULT_VALUE_ZERO = 0;
const FIRST_ENTRY = 0;

export function returnSelectedValue(data: ParameterTO) {
  const PATH = [SELECTED_VALUES, FIRST_ENTRY, VALUE];
  return pathOr(DEFAULT_VALUE_ZERO, PATH, data);
}

export function returnShortText(data: ParameterTO) {
  const PATH = [SHORT_TEXT];
  return pathOr(DEFAULT_VALUE_EMPTY_STRING, PATH, data);
}
