import {
  IconButton,
  ITheme,
  mergeStyleSets,
  Stack,
  useTheme,
} from "@fluentui/react";
import { useMemo } from "react";

import { Maximize, Minimize } from "../../../../../components/Icons";
import { VisualizationSectionIcons } from "./VisualizationSectionIcons";
import { VisualizationZoomSlider } from "./VisualizationZoomSlider";

function componentStyle(theme: ITheme) {
  return mergeStyleSets({
    overlay: {
      position: "absolute",
      pointerEvents: "none",
      maxHeight: "100%",
      maxWidth: "100%",
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
    },

    column: {
      flex: 1,
      display: "flex",
      flexFlow: "column",
      justifyContent: "space-between",
      maxHeight: "100%",
      maxWidth: "100%",
    },
    row: {
      padding: "1em",
      flex: 1,
      flexDirection: "row",
      display: "flex",
      height: "100%",
      maxHeight: "100%",
      overflow: "hidden",
    },
    button: {
      width: "fit-content",
      height: "fit-content",
      pointerEvents: "all",
      border: "none",
      background: "none",
      color: theme.palette.blackTranslucent40,
    },
    displayFlex: {
      display: "flex",
    },
  });
}

export function VisualizationOverlay({
  inPopup,
  popupButtonHandler,
}: Readonly<VisualizationOverlayProps>) {
  const theme = useTheme();
  const { overlay, displayFlex, row, column, button } = useMemo(
    () => componentStyle(theme),
    [theme],
  );
  return (
    <Stack className={overlay}>
      <Stack style={{ flex: 1, overflow: "hidden" }}>
        <Stack className={row}>
          <Stack data-border="west" className={displayFlex}>
            <Stack className={column}>
              <VisualizationSectionIcons />
              <VisualizationZoomSlider />
            </Stack>
          </Stack>
          <Stack
            data-border="center"
            style={{ flex: 1, overflow: "auto" }}
          ></Stack>
          <Stack data-border="east" className={displayFlex}>
            <IconButton
              title="encoway-visualization-overlay"
              className={button}
              onClick={() => popupButtonHandler()}
            >
              {inPopup ? <Minimize /> : <Maximize />}
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
