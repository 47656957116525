import { VisualizationSettings } from "../../../../../settings";

const DIST_MIN =
  VisualizationSettings.visualization.settings.ui.controls.minDistance;
const DIST_MAX =
  VisualizationSettings.visualization.settings.ui.controls.maxDistance;

export function distanceToPercent(cameraDistance: number): number {
  const range = DIST_MAX - DIST_MIN;

  return Math.abs(
    100 - Math.round(((cameraDistance - DIST_MIN) / range) * 100),
  );
}

export function percentToDistance(relativeCameraPosition: number): number {
  const range = DIST_MAX - DIST_MIN;

  return ((100 - relativeCameraPosition) / 100) * range + DIST_MIN;
}
