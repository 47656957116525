export const EpriAccept = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-check"
    >
      <polyline points="20 6 9 17 4 12"></polyline>
    </svg>
  );
};

export const EpriClear = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-x"
    >
      <line x1="18" y1="6" x2="6" y2="18"></line>
      <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
  );
};

export const InfoClear = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <filter
          x="-30%"
          y="-30%"
          width="160%"
          height="160%"
          filterUnits="objectBoundingBox"
          id="v0mh8ahp5a"
        >
          <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation="2"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.380392157 0 0 0 0 0.380392157 0 0 0 0 0.380392157 0 0 0 0.2 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <circle id="htg3258xcb" cx="10" cy="10" r="10" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(4 4)">
          <use fill="#000" filter="url(#v0mh8ahp5a)" xlinkHref="#htg3258xcb" />
          <use fill="#FFF" xlinkHref="#htg3258xcb" />
        </g>
        <path
          d="M14.315 11.105a.84.84 0 0 0 .598-.22.75.75 0 0 0 .235-.575.77.77 0 0 0-.235-.58.828.828 0 0 0-.598-.226.828.828 0 0 0-.599.226.77.77 0 0 0-.235.58.75.75 0 0 0 .235.575.84.84 0 0 0 .599.22zm.669 5.724v-4.198a.675.675 0 1 0-1.35 0v4.198a.675.675 0 0 0 1.35 0z"
          fill="#616161"
        />
      </g>
    </svg>
  );
};

export const ForwardIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-arrow-right"
    >
      <line x1="5" y1="12" x2="19" y2="12"></line>
      <polyline points="12 5 19 12 12 19"></polyline>
    </svg>
  );
};

export const ChevronDownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-chevron-down"
    >
      <polyline points="6 9 12 15 18 9"></polyline>
    </svg>
  );
};

export const ChevronUpIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-chevron-up"
    >
      <polyline points="18 15 12 9 6 15"></polyline>
    </svg>
  );
};

export const BasicMinus = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M13.197 12.864h9.94a.864.864 0 0 0 0-1.728H.862a.864.864 0 0 0 0 1.728h12.334z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export const BasicPlus = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M13.197 22.803v-9.767h9.767a1.036 1.036 0 0 0 0-2.072h-9.767V1.197a1.197 1.197 0 0 0-2.394 0v9.767H1.036a1.036 1.036 0 1 0 0 2.072h9.767v9.767a1.197 1.197 0 1 0 2.394 0z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export const BasicArrowForwardBold = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M6.667 0c-.45 0-.859.173-1.187.493A1.56 1.56 0 0 0 5 1.633c0 .342.1.664.296.945l.123.155 9.637 9.23-9.576 9.345a1.56 1.56 0 0 0-.48 1.14c0 .427.157.818.452 1.138l.093.086c.212.165.466.26.748.295.178.022.328.033.457.033.133 0 .28-.012.442-.035l.114-.02a.929.929 0 0 0 .56-.33l.072-.11 10.554-10.297c.329-.321.508-.723.508-1.167 0-.38-.132-.73-.384-1.015l-.12-.12L7.827.492A1.555 1.555 0 0 0 6.665 0z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export const BasicCart = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M.64 1h3.471l.106.013c.098.023.19.079.279.163.079.076.144.156.2.253l.052.104 1.246 5.664h17.381l.1.009a.522.522 0 0 1 .204.077c.052.034.1.074.138.116l.051.064a.48.48 0 0 1 .123.3l.009.154v.096l-.004.112-2.976 9.329-.063.103a.693.693 0 0 1-.198.18.715.715 0 0 1-.266.1l-.092.007-11.964-.001.13.707h10.36l.184.009c.502.043.941.26 1.31.645.413.432.62.963.62 1.583 0 .605-.207 1.128-.62 1.56a2.007 2.007 0 0 1-1.5.653 2.032 2.032 0 0 1-1.524-.653 2.168 2.168 0 0 1-.613-1.365l-.008-.2.006-.177c.017-.24.066-.468.15-.682l.01-.022h-6.92l.024.056c.048.128.084.261.108.4l.027.211.008.22c0 .604-.208 1.127-.62 1.56A2.032 2.032 0 0 1 8.044 23c-.582 0-1.085-.22-1.5-.653a2.183 2.183 0 0 1-.621-1.56c0-.551.164-1.032.494-1.438l.13-.15.123-.118c.13-.117.267-.216.415-.295l.152-.074.014-.006-.333-1.429.003-.087L3.593 2.35H.626L.512 2.34a.606.606 0 0 1-.33-.181A.69.69 0 0 1 0 1.663a.66.66 0 0 1 .182-.47.606.606 0 0 1 .353-.185L.64 1zm18.28 18.901c-.268 0-.47.078-.617.231-.148.155-.223.37-.223.655 0 .269.075.476.223.632.147.153.349.23.617.23.253 0 .448-.077.595-.23.148-.156.223-.363.223-.632 0-.285-.076-.5-.223-.655-.147-.154-.342-.23-.595-.23zm-10.875 0c-.252 0-.447.077-.594.231-.148.155-.223.37-.223.655 0 .269.074.476.223.632.147.153.342.23.594.23.268 0 .47-.077.617-.23.149-.156.223-.363.223-.632 0-.285-.075-.5-.223-.655-.146-.153-.349-.23-.617-.23zM22.456 8.547H6.272l1.804 7.946h11.818l2.562-7.946z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export const Maximize = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-maximize-2"
    >
      <polyline points="15 3 21 3 21 9"></polyline>
      <polyline points="9 21 3 21 3 15"></polyline>
      <line x1="21" y1="3" x2="14" y2="10"></line>
      <line x1="3" y1="21" x2="10" y2="14"></line>
    </svg>
  );
};

export const Minimize = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-minimize-2"
    >
      <polyline points="4 14 10 14 10 20"></polyline>
      <polyline points="20 10 14 10 14 4"></polyline>
      <line x1="14" y1="10" x2="21" y2="3"></line>
      <line x1="3" y1="21" x2="10" y2="14"></line>
    </svg>
  );
};

export const InfoIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      focusable="false"
      viewBox="0 0 2048 2048"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M1024 0q141 0 272 36t244 104 207 160 161 207 103 245 37 272q0 141-36 272t-104 244-160 207-207 161-245 103-272 37q-141 0-272-36t-244-104-207-160-161-207-103-245-37-272q0-141 36-272t104-244 160-207 207-161T752 37t272-37zm128 1536v-256H896v256h256zm0-384V512H896v640h256z"
          fill="currentColor"
        ></path>
      </g>
    </svg>
  );
};

export const CheckmarkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-check"
    >
      <polyline points="20 6 9 17 4 12"></polyline>
    </svg>
  );
};
