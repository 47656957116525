import {
  DefaultButton,
  ITheme,
  mergeStyleSets,
  PrimaryButton,
  Stack,
  Text,
  useTheme,
} from "@fluentui/react";
import {
  compose,
  concat,
  head,
  isEmpty,
  isNil,
  keys,
  match,
  omit,
  path,
  reduce,
  toLower,
} from "ramda";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { CustomSpinner } from "../../../../components/CustomSpinner";
import { Dialog } from "../../../../components/Dialog/Dialog";
import { Section } from "../../../../components/Section";
import { useAnalyticsContext } from "../../../../context/useAnalytics";
import { AppContext } from "../../../../context/useApp";
import { BomContext } from "../../../../context/useBom";
import { useConfigurationContext } from "../../../../context/useConfiguration";
import { ProductContext } from "../../../../context/useProducts";
import { useSettings } from "../../../../context/useSettings";
import { CheckoutDTO } from "../../../../service/mailService";
import { getContactDataFromSAP } from "../../../../service/sapService";
import { saveConfiguration } from "../../../../service/shareService";
import { mediaQuery } from "../../../../theme";
import { getConfiguratorType } from "../../../../utils";
import { getShareLink } from "../../../../utils/shareUtils";
import { LegalInformation } from "./columns/LegalInformation";
import { PersonalInformation } from "./columns/PersonalInformation";
import { isAnyRequiredInputFieldValid } from "./requestUtils";
import { CheckoutState, ORDER_TYPE, useRequest } from "./useRequest";

interface CheckoutModalProps {
  isOpen: boolean;
  orderType: ORDER_TYPE;

  onModalCancelClick(): void;
  onModalSubmitClick(checkoutDTO: CheckoutDTO): void;
}

function checkoutStyle(theme: ITheme) {
  return mergeStyleSets({
    root: {
      padding: "1em",
    },
    header: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      margin: "0",
    },
    logo: {
      height: "2em",
      margin: "2px 6px 2px 0",
    },
    personal: {
      flexDirection: "row",
      flexWrap: "wrap",
      flexFlow: "column",
      [mediaQuery.md]: {
        flexDirection: "column",
      },
    },
    sendWrapper: {
      flexDirection: "row",
      justifyContent: "space-between",
      paddingTop: "20px",
      gap: "1em",
      [mediaQuery.sm]: {
        flexFlow: "column",
      },
    },
    defaultButton: {
      color: theme.palette.themePrimary,
      backgroundColor: theme.palette.white,
      border: `1px solid ${theme.palette.themePrimary}`,
      borderRadius: "6px",
      ":hover, :focus, :active": {
        color: theme.palette.themeDarkAlt,
        border: `1px solid ${theme.palette.themeDarkAlt}`,
        borderRadius: "6px",
      },
    },
    primaryButton: {
      color: theme.palette.white,
      borderRadius: "6px",
      backgroundColor: theme.palette.themePrimary,
    },
  });
}

export function Request({
  onModalSubmitClick,
  onModalCancelClick,
  orderType,
  isOpen,
}: Readonly<CheckoutModalProps>) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { settings, axiosSimple } = useSettings();
  const theme = useTheme();
  const { total, bom, text } = useContext(BomContext);
  const { auth, url } = useContext(AppContext);
  const { products } = useContext(ProductContext);
  const { send } = useAnalyticsContext();
  const configuration = useConfigurationContext();
  const { cfg, article } = configuration;
  const {
    checkoutState,
    setCheckoutValue,
    setCheckoutState,
    setCountry,
    disabledFields,
    setDisabledFields,
  } = useRequest(text, orderType, "de", article || "");
  const checkoutStyled = useMemo(() => checkoutStyle(theme), [theme]);
  const [isLoading, setIsLoading] = useState(false);

  const { header, personal, sendWrapper, defaultButton, primaryButton } =
    checkoutStyled;

  const isSendButtonEnabled = isAnyRequiredInputFieldValid(checkoutState);

  useEffect(() => {
    const region = compose(toLower, head, match(/[a-zA-Z]{2}$/))(language) + "";
    const selectedCountryCode = region || "de";
    setCountry(concat("country.", selectedCountryCode));
  }, [language]);

  useEffect(() => {
    if (!isNil(auth?.token)) {
      getContactDataFromSAP(axiosSimple, language, auth.token).then(
        (sapContact: CheckoutState) => {
          setCheckoutState((prev) => ({ ...prev, ...sapContact }));
          setDisabledFields(
            reduce<string, string[]>(
              (acc, val: string) =>
                isEmpty(path([val], sapContact)) ? acc : [...acc, val],
              [],
              keys(sapContact),
            ),
          );
        },
      );
    }
  }, [auth]);

  useEffect(() => {
    send(settings.analytics.content.configure, language, cfg);
  }, []);

  function sendRequest() {
    return function () {
      if (!isNil(article)) {
        setIsLoading(true);
        send(settings.analytics.content.checkout, language, cfg);
        saveConfiguration(configuration)
          .then((uuid) => {
            const link = getShareLink(url, language, article, uuid);
            const type = getConfiguratorType(path([article || ""], products));
            onModalSubmitClick({
              ...omit(["checkAnyContact"], checkoutState),
              amount: total.amount,
              partsList: bom,
              configurationUrl: link,
              configurationType: type,
            });
          })
          .finally(() => setIsLoading(false));
      }
    };
  }

  return (
    <Dialog
      isOpen={isOpen}
      onDismiss={onModalCancelClick}
      styles={{
        main: { maxWidth: "760px" },
        scrollableContent: { overflow: "hidden", transform: "translateY(0)" },
      }}
      showCloseButton={false}
    >
      <Section
        className={header}
        label={t("t:glossary.checkout.personalData")}
      />
      <Stack className={personal}>
        <PersonalInformation
          checkoutState={checkoutState}
          setCheckoutValue={setCheckoutValue}
          disabledFields={disabledFields}
        />
      </Stack>
      <LegalInformation />
      <Stack>
        <Text variant="small">
          {"* " + t("t:glossary.checkout.checkoutModal.required")}
        </Text>
      </Stack>
      <Stack className={sendWrapper}>
        <Stack.Item>
          <DefaultButton className={defaultButton} onClick={onModalCancelClick}>
            {t("t:common.button.cancel")}
          </DefaultButton>
        </Stack.Item>
        <Stack.Item>
          <PrimaryButton
            className={primaryButton}
            disabled={!isSendButtonEnabled}
            onClick={isSendButtonEnabled ? sendRequest() : () => null}
          >
            {isLoading ? <CustomSpinner /> : t("t:common.button.request")}
          </PrimaryButton>
        </Stack.Item>
      </Stack>
    </Dialog>
  );
}
