import {
  concat,
  filter,
  includes,
  isEmpty,
  length,
  map,
  pathOr,
  pipe,
  sortBy,
  splitWhen,
} from "ramda";

import { Value } from "@encoway/c-services-js-client";

const divider = { value: "divider" } as unknown as Value;

function undo(translatedValue: string) {
  return {
    value: "_undo_",
    selectable: true,
    translatedValue,
  } as Value;
}

export function mapValues(
  name: string,
  values: Value[] | undefined,
  undoable: boolean,
  undoValue: string,
  crossReferencesCharacteristics: { [key: string]: string[] },
  isTerminal: boolean = false,
  isConnector: boolean = false,
  isConnectorCounterPart: boolean = false,
): Value[] {
  if (values) {
    const undoItem = undoable ? [divider, undo(undoValue)] : [];
    const checkForCrossReferences = isConnector || isConnectorCounterPart;
    const [selectable, nonSelectable] = pipe(
      map((value: Value) => ({
        ...value,
        selectable: isTerminal ? true : value.selectable,
      })),
      filter(
        (value: Value) =>
          !checkForCrossReferences ||
          isEmpty(pathOr([], [name], crossReferencesCharacteristics)) ||
          includes(
            value.value,
            pathOr([], [name], crossReferencesCharacteristics),
          ),
      ),
      sortBy((value: Value) => !value.selectable),
      splitWhen((value: Value) => !value.selectable),
    )(values);
    if (length(nonSelectable) > 0 && length(selectable) > 0) {
      return concat(selectable, [divider, ...nonSelectable, ...undoItem]);
    }
    return [...selectable, ...nonSelectable, ...undoItem];
  }
  return [];
}

export function isValue(value: Value | { value: string }): value is Value {
  return (value as Value).translatedValue !== undefined;
}
