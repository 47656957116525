import { SelectedConnectorParameter } from "configuration";
import { Products } from "product";
import {
  and,
  compose,
  equals,
  filter,
  find,
  gt,
  includes,
  length,
  lt,
  map,
  not,
  or,
  path,
  pathOr,
  pipe,
  propEq,
  reduce,
  uniq,
  values,
} from "ramda";
import { SettingsProps } from "setting";

import {
  ContainerTO,
  GuiTO,
  ParameterTO,
  Value,
} from "@encoway/c-services-js-client";

import { ProductState } from "../../../../context/useProducts";

const COUNTERPART_VALUE_PATH = ["parameters", "0", "values", "0", "selected"];

export function isCounterpartChecked(container: ContainerTO): boolean {
  const counterpartContainer = find(
    propEq("name", "Gegenstueck"),
    container.children[0].children,
  );
  return pathOr(false, COUNTERPART_VALUE_PATH, counterpartContainer);
}

function getNumberOfSectionsPath(article: string) {
  return [
    "products",
    article,
    "characteristicValues",
    "Number_Of_Sections",
    "values",
    "0",
    "value",
  ];
}

export function calculateConnectorContentHeight(
  settings: SettingsProps,
  guiTO: GuiTO,
  products: ProductState,
  article: string | undefined,
  tableMaxHeight: number,
): number {
  const numberOfSections = pathOr<number>(
    1,
    getNumberOfSectionsPath(article!),
    products,
  );
  if (
    and(
      equals(article, settings.studio.configurator.connector),
      not(isCounterpartChecked(guiTO.rootContainer)),
    )
  ) {
    return tableMaxHeight - (numberOfSections - 3) * 64 || 336;
  }
  return tableMaxHeight - numberOfSections * 64 || 336;
}

function findCounterpart(parameter: SelectedConnectorParameter): boolean {
  return compose(
    includes("_Gegenstueck"),
    pathOr("", ["viewPortProperties", "ref-table"]),
  )(parameter.data);
}

export function getSelectedContactId(
  selectedItems: SelectedConnectorParameter[],
): string {
  return pathOr(
    "",
    ["value"],
    find(
      (parameter) =>
        propEq("name", "Einsatz", parameter) && !findCounterpart(parameter),
      selectedItems,
    ),
  );
}

export function getSelectedContactCounterpartId(
  selectedItems: SelectedConnectorParameter[],
): string {
  return pathOr("", ["value"], find(findCounterpart, selectedItems));
}

export function hasContacts(selectedProducts: Products): boolean {
  const checkForContacts = compose(
    equals("Ja"),
    pathOr("Nein", [
      "characteristicValues",
      "pdm_atr_ctcs_included",
      "values",
      0,
    ]),
  );
  return reduce(
    (acc, cur) => or(acc, checkForContacts(cur)),
    false,
    values(selectedProducts),
  );
}

export function getPossibleValues(guiTO: GuiTO): Value[] | undefined {
  const tableView = pipe(
    pathOr([], ["rootContainer", "children"]),
    find(propEq("name", "cb-tableview")),
  )(guiTO);
  const productDetails = pipe(
    pathOr([], ["children"]),
    find(propEq("name", "cb-productdetails")),
  )(tableView);
  const contact = pipe(
    pathOr([], ["parameters"]),
    find(propEq("name", "Contact")),
  )(productDetails);

  return filter(
    propEq("selectable", true),
    pathOr<Value[]>([], ["values"], contact),
  );
}

function extractParameters(products: Products, parameter: string): string[] {
  return pipe(
    map((product) =>
      pathOr("", ["characteristicValues", parameter, "values", 0], product),
    ),
    uniq,
  )(values(products));
}

export function toPossibleValueOptions(products: Products) {
  return (acc: { [key: string]: string[] }, parameter: string) => ({
    ...acc,
    [parameter]: extractParameters(products, parameter),
  });
}

export function extractParameterNames(container: ContainerTO) {
  return map<ParameterTO, string>(
    (parameter) => parameter.name,
    pathOr([], ["parameters"], container),
  );
}

function getMandatoryParameters(parameters: ParameterTO[]): {
  ready: ParameterTO[];
  total: ParameterTO[];
} {
  return reduce<ParameterTO, { ready: ParameterTO[]; total: ParameterTO[] }>(
    (acc, cur) => {
      const ready =
        equals("READY", path(["readyState"], cur)) && path(["mandatory"], cur)
          ? [...acc.ready, cur]
          : acc.ready;
      const total = path(["mandatory"], cur) ? [...acc.total, cur] : acc.total;
      return {
        ready,
        total,
      };
    },
    { ready: [], total: [] },
    parameters,
  );
}

export function getParameterStatus(
  parameters: ParameterTO[],
): [number, number] {
  const { ready, total } = getMandatoryParameters(parameters);
  return [length(ready), length(total)];
}

export function getSectionStatus(ready: number, total: number) {
  return {
    notReady: equals(ready, 0),
    partiallyReady: gt(ready, 0) && lt(ready, total),
    ready: equals(ready, total),
  };
}
