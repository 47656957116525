import {
  Image,
  ImageFit,
  ITheme,
  mergeStyleSets,
  PrimaryButton,
  Stack,
  Text,
  useTheme,
} from "@fluentui/react";
import classNames from "classnames";
import { ConfiguratorComponentProps } from "configuration";
import {
  addIndex,
  equals,
  isEmpty,
  isNil,
  map,
  not,
  path,
  pathOr,
  pipe,
  propEq,
  reject,
} from "ramda";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ParameterTO } from "@encoway/c-services-js-client";

import { useConfigurationContext } from "../../../context/useConfiguration";
import { useTab } from "../../../context/useTab";
import { useProduct } from "../../../hooks/useProduct";
import { isImage } from "../../../utils/imageUtils";
import { ComponentNameCell } from "./confTable/ComponentNameCell";
import {
  ColumnHeading,
  COMPONENT_NAME_KEY,
  SingleCell,
} from "./confTableUtils";
import { RowItem, useConfTable } from "./useConfTable";
import { useConfTableParams } from "./useConfTableParams";

const AUTO_ADVANCE_PATH = [
  "characteristicValues",
  "section-auto-advance",
  "values",
  0,
  "value",
];

type CellProps = {
  value: RowItem | undefined;
  heading: ColumnHeading[];
  tableMaxHeight: number;
};

function stylesTable(theme: ITheme, tableMaxHeight: number) {
  return mergeStyleSets({
    wrapper: {
      maxHeight: `${tableMaxHeight}px`,
      overflowY: "scroll",
      margin: "0",
    },
    selected: {
      background: "rgba(255,113,0,0.15) !important",
      borderBottom: `2px solid ${theme.palette.neutralTertiary} !important`,
    },
    table: {
      width: "100%",
      "td, th": {
        borderBottom: "1px solid #F3F3F3FF",
        padding: "1em 0",
      },
      "tr td:first-child .table-checkbox": {
        borderRadius: "100%",
        background: "transparent",
        padding: "6px",
        width: "5px",
        height: "5px",
        border: `1px solid ${theme.palette.neutralQuaternary}`,
      },
      "tr.alternateItem": {
        backgroundColor: theme.palette.neutralLighter,
        span: {
          color: theme.palette.neutralQuaternary,
        },
      },
      "tr.selectable td:first-child .table-checkbox": {
        border: `1px solid #2c2c2c`,
      },
      "tr.selected td:first-child .table-checkbox": {
        border: `1px solid ${theme.palette.themePrimary}`,
        height: "14px",
        padding: "2px",
        width: "14px",
        ":before": {
          background: theme.palette.themePrimary,
          borderRadius: "100%",
          content: "''",
          display: "block",
          height: "8px",
          width: "8px",
        },
      },
      thead: {
        position: "sticky",
        top: 0,
        zIndex: 1,
      },
      "thead tr": {
        background: "#f5f5f5",
        color: theme.palette.black,
        fontSize: "14px",
        fontWeight: 600,
        ":hover": {
          background: "#f5f5f5",
          cursor: "initial",
        },
      },
      "tr.selected, tr:not(.selectable)": {
        background: theme.palette.neutralLight,
      },
      "tr:hover": {
        cursor: "pointer",
        background: theme.palette.neutralQuaternaryAlt,
      },
    },
    moreButton: {
      marginTop: "1em",
      padding: "0 40%",
    },
    imgWrapper: {
      borderRadius: "6px",
      boxShadow: "0px 0px 8px 0px rgba(0,0,0,0.35)",
      padding: 8,
      backgroundColor: "white",
    },
    selectedItems: {
      alignItems: "flex-start",
      gap: ".4em",
      paddingBottom: "2em",
    },
    checkboxWrapper: {
      padding: "0 1em",
    },
    text: {
      display: "block",
      padding: "0 1em",
    },
  });
}

function ProductImage({
  className,
  src,
}: Readonly<{ className: string; src: string }>) {
  return (
    <Image
      className={className}
      imageFit={ImageFit.contain}
      height={76}
      width={76}
      src={src}
      alt={src}
    />
  );
}

function Cell({ value, heading, tableMaxHeight }: Readonly<CellProps>) {
  const theme = useTheme();
  const styledTable = useMemo(
    () => stylesTable(theme, tableMaxHeight),
    [theme, tableMaxHeight],
  );

  if (isNil(value)) {
    return null;
  }

  return (
    <>
      {addIndex<SingleCell>(map)(
        (cell, cellIndex) =>
          equals(path([cellIndex, "key"], heading), COMPONENT_NAME_KEY) ? (
            <ComponentNameCell
              key={`component-cell-name-${value.key}-${cellIndex}`}
              cell={cell}
              productId={value.key}
            />
          ) : (
            <td key={cellIndex}>
              {isImage(cell) ? (
                <ProductImage
                  className={styledTable.imgWrapper}
                  src={cell.value}
                />
              ) : (
                <Text variant="small" className={styledTable.text}>
                  {cell.value}
                </Text>
              )}
            </td>
          ),
        value.cells,
      )}
    </>
  );
}

export function ConfTable(
  props: ConfiguratorComponentProps<ParameterTO> & { onMore(): void },
) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { hasMore, heading, rows, selectedValue, selectValue } =
    useConfTable(props);
  const tableMaxHeight = useConfTableParams();
  const styledTable = useMemo(
    () => stylesTable(theme, tableMaxHeight),
    [theme, tableMaxHeight],
  );
  const { article } = useConfigurationContext();
  const { getProduct } = useProduct();
  const { next } = useTab();

  const findSelected = propEq("key", selectedValue?.key);

  const unselectedItems = useMemo(() => reject(findSelected, rows), [rows]);

  async function onSelect(key: string) {
    selectValue(key);
    const product = await getProduct(article!);
    const shouldAutoAdvance = pipe(
      pathOr(0, AUTO_ADVANCE_PATH),
      equals(1),
    )(product);

    if (shouldAutoAdvance) {
      next();
    }
  }

  if (isEmpty(rows)) {
    return <Stack className={styledTable.wrapper} />;
  }

  return (
    <Stack className={styledTable.wrapper}>
      <table className={styledTable.table} cellSpacing={0}>
        <thead>
          <tr className="selectable">
            <th />
            {map(
              ({ key, label }) => (
                <th key={key}>{label}</th>
              ),
              heading,
            )}
          </tr>
        </thead>
        <tbody>
          {selectedValue && (
            <tr
              className={classNames({
                selectable: selectedValue.selectable,
                selected: equals(selectedValue.key, selectedValue?.key),
              })}
              onClick={() => onSelect(selectedValue.key)}
            >
              <td>
                <Stack className={styledTable.checkboxWrapper}>
                  <div className="table-checkbox" />
                </Stack>
              </td>
              <Cell
                value={selectedValue}
                heading={heading}
                tableMaxHeight={tableMaxHeight}
              />
            </tr>
          )}
          {addIndex<RowItem>(map)(
            (value, valueIndex) => (
              <tr
                key={valueIndex}
                className={classNames({
                  selectable: value.selectable,
                  selected: equals(value.key, selectedValue?.key),
                  alternateItem: not(isNil(selectedValue)),
                })}
                onClick={() => onSelect(value.key)}
              >
                <td>
                  <Stack className={styledTable.checkboxWrapper}>
                    <div className="table-checkbox" />
                  </Stack>
                </td>
                <Cell
                  value={value}
                  heading={heading}
                  tableMaxHeight={tableMaxHeight}
                />
              </tr>
            ),
            unselectedItems,
          )}
        </tbody>
      </table>
      {hasMore && (
        <Stack className={styledTable.moreButton}>
          <PrimaryButton onClick={props.onMore}>
            {t("t:common.button.more")}
          </PrimaryButton>
        </Stack>
      )}
    </Stack>
  );
}
