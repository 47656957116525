import { useEffect, useRef } from "react";

import { isStandalone } from "../utils";

export function useDocumentTitle(title: string) {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    if (isStandalone()) {
      document.title = title;
    }
  }, [title]);

  useEffect(
    () => () => {
      if (isStandalone()) {
        document.title = defaultTitle.current;
      }
    },
    [],
  );
}
