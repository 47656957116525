import { Stack } from "@fluentui/react";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { Dialog, DialogFooter, DialogType } from "@fluentui/react/lib/Dialog";
import { IProcessedStyleSet, mergeStyles } from "@fluentui/react/lib/Styling";
import { classNamesFunction } from "@fluentui/react/lib/Utilities";
import { useTranslation } from "react-i18next";

import { EpriProps, IEpriStyles } from "@encoway/cui-configurator-components";
import { determineLayerClass } from "@encoway/cui-configurator-components/dist/esm/helperFunctions";

import { EpriAccept, EpriClear } from "../../../../components/Icons";
import { Adjustments } from "./Adjustments";
import { defaultDialogMinWidth } from "./ConfEpri";
import { CurrentSelection } from "./CurrentSelection";
import { EpriText } from "./EpriText";
import { addUnit, checkboxValue } from "./epriUtils";

/**
 * The Epri component resolves prioritization conflicts.
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/Epri/Epri.tsx)
 * - [EpriStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/Epri/Epri.styles.ts)
 * - [EpriProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/Epri/Epri.types.ts)
 * - [MS Fluent Dialog](https://developer.microsoft.com/de-DE/fluentui#/controls/web/dialog)
 *
 * @visibleName Epri
 */
export const EpriDialog = (props: EpriProps) => {
  const { t } = useTranslation();
  const { source, styles, theme, value } = props;
  const classNames = classNamesFunction()(
    styles,
    theme,
  ) as IProcessedStyleSet<IEpriStyles>;
  const dialogMinWidth = props.dialogMinWidth || defaultDialogMinWidth;
  const newValue = checkboxValue(source, t) || addUnit(value, source);
  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        type: DialogType.normal,
        showCloseButton: false,
        title: t("t:epri.title"),
      }}
      onDismiss={() => props.onDecline()}
      minWidth={`min(${dialogMinWidth}, 100%)`}
      modalProps={{
        className: mergeStyles(
          classNames.dialog,
          classNames.modal,
          determineLayerClass(props.modalProps?.layerProps, classNames.layer),
        ),
        isBlocking: true,
        overlay: {
          isDarkThemed: false,
          className: classNames.overlay,
        },
        ...props.modalProps,
      }}
    >
      <div className={classNames.content}>
        <EpriText
          classNames={classNames}
          parameter={source}
          newValue={newValue}
        />
        <CurrentSelection
          classNames={classNames}
          parameter={source}
          newValue={newValue}
        />
        <Adjustments
          classNames={classNames}
          affectedParameters={props.affectedParameters}
        />
      </div>
      <DialogFooter className={classNames.footer}>
        <PrimaryButton
          onClick={() => props.onAccept()}
          className={classNames.acceptButton}
          style={{ fontSize: "18px", height: "45px" }}
        >
          <Stack
            style={{
              margin: "0px 6px",
            }}
          >
            <EpriAccept />
          </Stack>
          {t("t:epri.accept")}
        </PrimaryButton>
        <DefaultButton
          onClick={() => props.onDecline()}
          className={classNames.rejectButton}
          style={{ fontSize: "18px", height: "45px" }}
        >
          <Stack
            horizontal
            style={{
              margin: "0px 6px",
            }}
          >
            <EpriClear />
          </Stack>
          {t("t:epri.reset")}
        </DefaultButton>
      </DialogFooter>
    </Dialog>
  );
};
