import { ConfiguratorComponentProps } from "configuration";
import { Products } from "product";
import {
  compose,
  equals,
  filter,
  find,
  isEmpty,
  isNil,
  length,
  map,
  not,
  pathOr,
  prepend,
  propEq,
  reduce,
  slice,
  split,
} from "ramda";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ParameterTO, Value } from "@encoway/c-services-js-client";

import { useConfigurationContext } from "../../../context/useConfiguration";
import { ProductContext } from "../../../context/useProducts";
import {
  ColumnHeading,
  SingleCell,
  filterByProductCrossReferences,
  getTableColumnKeys,
  isInsert,
  sortRows,
  sortValuesByTerminal,
  toTableProducts,
} from "./confTableUtils";

export interface RowItem {
  key: string;
  selectable: boolean | undefined;
  cells: SingleCell[];
}

interface UseTableState {
  heading: ColumnHeading[];
  rows: RowItem[];
  selectedValue: RowItem | undefined;
  selectValue: (value: string) => void;
  hasMore: boolean;
}

const PRODUCT_CROSS_REFERENCE_PATH = [
  "characteristicValues",
  "ProductCrossReference",
  "values",
  0,
];

export function useConfTable(
  props: ConfiguratorComponentProps<ParameterTO>,
): UseTableState {
  const { data, onValueChanged, open } = props;
  const [rows, setRows] = useState<RowItem[]>([]);
  const { getProducts } = useContext(ProductContext);
  const {
    productCrossReferences,
    actions: { setProductCrossReferences },
  } = useConfigurationContext();
  const { i18n } = useTranslation();
  const [columnKeys, setColumnKeys] = useState<ColumnHeading[]>([]);

  const selectedValue = useMemo(
    () =>
      find(
        (value) =>
          equals(pathOr("", ["selectedValues", 0, "value"], data), value.key),
        rows,
      ),
    [data, rows],
  );

  useEffect(() => {
    if (!isNil(data.values)) {
      getProducts(map((value) => value.value, data.values || []))
        .then(
          filter(filterByProductCrossReferences(productCrossReferences, data)),
        )
        .then((products) => {
          const newColumnKeys = getTableColumnKeys(data, products);
          setColumnKeys(newColumnKeys);
          return determineRows(products, newColumnKeys);
        })
        .then(filter(propEq("selectable", true)))
        .then(setRows);
    }
  }, [i18n.language, data]);

  function determineRows(
    products: Products,
    columnKeys: ColumnHeading[],
  ): RowItem[] {
    if (products) {
      const sortedValues = map<Value, RowItem>(
        (value: Value): RowItem => ({
          key: value.value,
          selectable: data.terminal ? true : value.selectable,
          cells: [],
        }),
        sortValuesByTerminal(data.values || [], data.terminal),
      );

      return reduce(toTableProducts(products, columnKeys), [], sortedValues);
    }
    return [];
  }

  function getProductCrossReference(id: string) {
    const path = prepend(id, PRODUCT_CROSS_REFERENCE_PATH);
    getProducts(id)
      .then(pathOr("", path))
      .then(split(", "))
      .then(filter(compose(not, isEmpty)))
      .then(setProductCrossReferences);
  }

  function selectValue(value: string) {
    if (isInsert(data)) {
      getProductCrossReference(value);
    }
    onValueChanged(data, value);
  }

  return {
    rows: slice(0, open.numValues, sortRows(rows || [], selectedValue)),
    selectedValue,
    selectValue,
    heading: columnKeys,
    hasMore: open.numValues < length(rows),
  };
}
