import { AxiosInstance } from "axios";
import { Bus } from "baconjs";
import { BomProduct, BomTotalDTO } from "bom";
import { CfgBus } from "configuration";

import { GuiTO } from "@encoway/c-services-js-client";

import { CUSTOM_CONSTANTS } from "../utils";
import { InvalidProduct } from "../utils/moqUtils";

// http://localhost:8081/lapp-cable-server/lapp-api/pricing/f70fa741-c9f2-4fd4-af4e-f14d79a2a9ad/total
export async function getTotal(
  axios: AxiosInstance,
  configurationId: string,
  language: string,
): Promise<BomTotalDTO> {
  const { data } = await axios.get<BomTotalDTO>(
    `lapp-api/pricing/${configurationId}/total`,
    {
      headers: {
        "Accept-Language": language,
      },
    },
  );
  return data;
}

// http://localhost:8081/lapp-cable-server/lapp-api/f70fa741-c9f2-4fd4-af4e-f14d79a2a9ad/partList
export async function getBom(
  axios: AxiosInstance,
  configurationId: string,
  language: string,
): Promise<BomProduct[]> {
  const { data } = await axios.get<BomProduct[]>(
    `lapp-api/${configurationId}/partList`,
    {
      headers: {
        "Accept-Language": language,
      },
    },
  );
  return data;
}

export async function forceInvalidProducts(
  axios: AxiosInstance,
  configurationId: string,
  invalidProducts: InvalidProduct[],
  eventBus: Bus<CfgBus>,
) {
  invalidProducts.forEach((invalidProduct) => {
    const productId = invalidProduct.alternateProductId;
    axios
      .get<GuiTO>(
        `lapp-api/custom-configuration/configure/${configurationId}/forceProduct/${productId}`,
      )
      .then(() =>
        eventBus.push({
          event: CUSTOM_CONSTANTS.MOQ_TRIGGERED,
          name: "Nutzlaenge",
          value: "",
        }),
      );
  });
}
