import {
  HoverCard,
  HoverCardType,
  IPlainCardProps,
  ITheme,
  Stack,
  Text,
  mergeStyleSets,
  useTheme,
} from "@fluentui/react";
import { Products } from "product";
import { isEmpty, isNil, not, path, pathOr } from "ramda";
import { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { InfoClear } from "../../../../components/Icons";
import { ProductContext } from "../../../../context/useProducts";

type ComponentNameCellProps = {
  cell: any;
  productId: string;
};

type LinkInfo = {
  url: string;
  text: string;
};

type CellInfo = {
  articleName: string;
  articleNumber: string;
  description: string;
  link: LinkInfo;
};

function stylesComponentName(theme: ITheme) {
  return mergeStyleSets({
    root: {},
    wrapper: {
      flexFlow: "row",
      gap: ".8rem",
      justifyContent: "space-between",
      padding: "0 1em",
      lineHeight: "1.5",
    },
    articleName: {
      fontSize: "14px",
      fontWeight: "bold",
    },
    articleNumber: {
      fontSize: "14px",
    },
    articleLink: {
      fontSize: "14px",
      color: theme.palette.themePrimary,
      textDecoration: "none",
    },
  });
}

function tooltipStyle(theme: ITheme) {
  return mergeStyleSets({
    icon: {
      cursor: "pointer",
      color: theme.palette.black,
      display: "flex",
      alignItems: "center",
      selectors: {
        "&:hover": {
          color: theme.palette.neutralSecondary,
        },
        "&.mandatory": {
          color: theme.palette.themePrimary,
        },
        "&.mandatory:hover": {
          color: theme.palette.themeDark,
        },
      },
    },
    hoverCardHost: {
      display: "flex",
      alignItems: "center",
    },
    articleName: {
      fontWeight: "bold",
      lineHeight: "1.5",
      padding: "0 0 .5rem",
    },
    articleNumber: {
      lineHeight: "1.5",
      padding: "0 0 1rem",
    },
    description: {
      lineHeight: "1.5",
    },
    linkContainer: {
      paddingTop: ".4em",
    },
    link: {
      color: theme.palette.themePrimary,
      textDecoration: "none",
    },
  });
}

const tooltipStyling = mergeStyleSets({
  plainCard: {
    fontSize: "14px",
    padding: "1rem",
    maxWidth: "320px",
  },
});

const onRenderPlainCard = (renderData: ReactNode): JSX.Element => (
  <div className={tooltipStyling.plainCard}>{renderData}</div>
);

const LINK_PATH = ["characteristicValues", "LINK", "values", 0];
const LINK_TEXT_PATH = ["characteristicValues", "LINK_TEXT", "values", 0];
const DESCRIPTION_PATH = [
  "characteristicValues",
  "pdm_atr_hybris_top5_string",
  "values",
  0,
];
const ARTICLE_NUMBER_PATH = [
  "characteristicValues",
  "Artikelnummer",
  "values",
  0,
];

export function ComponentNameCell(props: Readonly<ComponentNameCellProps>) {
  const theme = useTheme();
  const { getProducts } = useContext(ProductContext);
  const tooltipStyled = useMemo(() => tooltipStyle(theme), [theme]);
  const componentNameStyled = useMemo(
    () => stylesComponentName(theme),
    [theme],
  );
  const [info, setInfo] = useState<CellInfo | null>(null);

  const { productId } = props;
  const extractDescription = (product: Products | undefined) => {
    const url = pathOr("", LINK_PATH, product);
    setInfo({
      articleName: pathOr("", ["name"], product),
      articleNumber: pathOr("", ARTICLE_NUMBER_PATH, product),
      description: pathOr("", DESCRIPTION_PATH, product),
      link: {
        url,
        text: pathOr(url, LINK_TEXT_PATH, product),
      },
    });
  };

  useEffect(() => {
    getProducts(productId)
      .then(path<Products>([productId]))
      .then(extractDescription);
  }, [productId]);

  if (isNil(info)) {
    return null;
  }

  const { articleName, articleNumber, description, link } = info;

  const plainCardProps: IPlainCardProps = {
    onRenderPlainCard: onRenderPlainCard,
    renderData: (
      <Stack>
        <Text className={tooltipStyled.articleName}>{articleName}</Text>
        <Text className={tooltipStyled.articleNumber}>{articleNumber}</Text>
        <Text className={tooltipStyled.description}>{description}</Text>
        {not(isEmpty(link.url)) && (
          <Text className={tooltipStyled.linkContainer}>
            <Link to={link.url} target="_blank" className={tooltipStyled.link}>
              {link.text}
            </Link>
          </Text>
        )}
      </Stack>
    ),
  };

  return (
    <td className={componentNameStyled.root}>
      <Stack className={componentNameStyled.wrapper}>
        <Stack>
          <Text className={componentNameStyled.articleName}>{articleName}</Text>
          <Text className={componentNameStyled.articleNumber}>
            {articleNumber}
          </Text>
          {not(isEmpty(link.url)) && (
            <Link
              className={componentNameStyled.articleLink}
              to={link.url}
              target="_blank"
              onClick={(event) => event.stopPropagation()}
            >
              {link.text}
            </Link>
          )}
        </Stack>
        <HoverCard
          instantOpenOnClick={true}
          cardDismissDelay={100}
          type={HoverCardType.plain}
          plainCardProps={plainCardProps}
          className={tooltipStyled.hoverCardHost}
        >
          <Stack
            className={tooltipStyled.icon}
            onClick={(e) => e.stopPropagation()}
          >
            <InfoClear />
          </Stack>
        </HoverCard>
      </Stack>
    </td>
  );
}
