import { ITheme, mergeStyleSets, Stack, useTheme } from "@fluentui/react";
import classNames from "classnames";
import { addIndex, map } from "ramda";
import { ReactNode, useMemo } from "react";

import { VisProperty } from "../../context/VisualizationProvider";
import { useVisualization } from "../../hooks/useVisualization";
import { VisEntry } from "./VisEntry";

function componentStyle(theme: ITheme) {
  return mergeStyleSets({
    visualisationWrapper: {
      padding: "0.5em",
    },
    visualisationSpacer: {
      alignItems: "center",
      backgroundColor: theme.palette.white,
      borderRadius: "5px",
      boxShadow: "rgba(33, 33, 33, 0.2) 0px 2px 8px 0px",
      display: "flex",
      height: "320px",
      justifyContent: "center",
      padding: "1.5em",
      position: "relative",
      flexDirection: "column",
    },
    visualisation: {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
    },
  });
}

export function Visualization({
  children,
}: Readonly<{ children?: ReactNode }>) {
  const theme = useTheme();
  const { visualisationWrapper, visualisation, visualisationSpacer } = useMemo(
    () => componentStyle(theme),
    [theme],
  );
  const { vis, visSettings } = useVisualization();

  return (
    <Stack className={visualisationWrapper}>
      <Stack className={visualisationSpacer}>
        <Stack
          className={classNames(visualisation, "visualisation")}
          style={{
            height: visSettings.height,
            width: visSettings.width,
            padding: visSettings.padding,
          }}
        >
          {addIndex<VisProperty>(map)(
            (visProperty, index) => (
              <VisEntry key={index} visProperty={visProperty} />
            ),
            vis,
          )}
        </Stack>
        {children}
      </Stack>
    </Stack>
  );
}
