import { SettingsContextProvider } from "./context/useSettings";
import { LappRouter } from "./router/LappRouter";

import "./App.css";

function App() {
  return (
    <div className="App">
      <SettingsContextProvider>
        <LappRouter />
      </SettingsContextProvider>
    </div>
  );
}
export default App;
