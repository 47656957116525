import { IProcessedStyleSet } from "@fluentui/react";
import { Separator } from "@fluentui/react/lib/Separator";
import { Text } from "@fluentui/react/lib/Text";
import { useTranslation } from "react-i18next";

import { ParameterTO } from "@encoway/c-services-js-client";
import { IEpriStyles } from "@encoway/cui-configurator-components";

import { ParameterItem } from "./ParameterItem";

export interface SelectionProps {
  parameter: ParameterTO;
  classNames: IProcessedStyleSet<IEpriStyles>;
  newValue: string;
}

export const CurrentSelection = (props: SelectionProps) => {
  const { t } = useTranslation();
  const { parameter, classNames, newValue } = props;
  return (
    <div className={classNames.section}>
      <Text variant="mediumPlus" className={classNames.headline}>
        {t("t:epri.selection-title")}
      </Text>
      <Separator />
      <ParameterItem
        classNames={classNames}
        parameter={parameter}
        value={newValue}
      />
    </div>
  );
};
