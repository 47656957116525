export const PARAMETER_KEYS: { [key: string]: string } = {
  INSERT: "cb-insert",
  CONNECTOR: "cb-contact",
  CASING: "cb-housing",
  INSERT_COUNTERPART: "Einsatz_Gegenstueck",
  CONNECTOR_COUNTERPART: "Contact_Gegenstueck",
  CASING_COUNTERPART: "Housing_Gegenstueck",

  IC_CABLE: "IC_Cable_Baustein",
} as const;
export const DEFAULT_IMAGE_PATHS = {
  CASING: ["characteristicValues", "casing_default_image", "values", 0, "uri"],
  CONNECTOR: [
    "characteristicValues",
    "connector_default_image",
    "values",
    0,
    "uri",
  ],
  INSERT: ["characteristicValues", "insert_default_image", "values", 0, "uri"],
  CASING_COUNTERPART: [
    "characteristicValues",
    "casing_cp_default_image",
    "values",
    0,
    "uri",
  ],
  CONNECTOR_COUNTERPART: [
    "characteristicValues",
    "connector_cp_default_image",
    "values",
    0,
    "uri",
  ],
  INSERT_COUNTERPART: [
    "characteristicValues",
    "insert_cp_default_image",
    "values",
    0,
    "uri",
  ],
  IC_CABLE: [
    "characteristicValues",
    "ic_cable_default_image",
    "values",
    0,
    "uri",
  ],
};
export const VISUALIZATION_POSITIONS = {
  ON_TOP: "ON_TOP",
  BETWEEN_COLUMNS: "BETWEEN_COLUMNS",
  NOT_SHOWN: "NOT_SHOWN",
} as const;
