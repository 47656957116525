import { ITheme, mergeStyleSets, Stack, Text, useTheme } from "@fluentui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

function legalStyle(theme: ITheme) {
  return mergeStyleSets({
    root: {
      flexFlow: "row",
      paddingBottom: "15px",
    },
    content: {
      padding: "0",
      flex: "1",
      textAlign: "justify",
      span: {
        color: theme.palette.black,
      },
      a: {
        color: "inherit",
      },
    },
  });
}

export const LegalInformation = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { root, content } = useMemo(() => legalStyle(theme), [theme]);
  return (
    <Stack className={root}>
      <div className={content}>
        <Text variant="medium">
          {t("t:validation.checkout.legalData.newsletter")}
        </Text>
        <div className="column is-11 checkbox-label ">
          <div className="column is-full no-p">
            <Text variant="medium">
              {t("t:validation.checkout.legalData.aboutInformation")}
            </Text>
          </div>
          <div className="column is-full no-pl no-pr no-pt">
            <Text variant="medium">
              {t("t:validation.checkout.legalData.additionalInfoEmails")}
            </Text>
          </div>
          <div className="column is-full no-p">
            <Text variant="medium">
              {t("t:validation.checkout.legalData.additionalInfoPrivacyPart1")}
            </Text>
            <a
              href={t("t:common.dataPrivacy")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("t:validation.checkout.legalData.dataPrivacy")}
            </a>
            <Text variant="medium">
              {t("t:validation.checkout.legalData.additionalInfoPrivacyPart2")}
            </Text>
          </div>
        </div>
      </div>
    </Stack>
  );
};
