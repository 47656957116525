import { ITheme, mergeStyleSets, useTheme } from "@fluentui/react";
import { useEffect, useMemo } from "react";

import {
  ComponentName,
  registerDefaultConfiguratorComponents,
} from "@encoway/cui-configurator-components";

import { useConfigurationContext } from "../../../context/useConfiguration";
import { mediaQuery } from "../../../theme";
import { Visualization } from "../../visualization/Visualization";
import { Configurator } from "../Configurator";
import { Bom } from "../bom/Bom";
import { ConfDisplay } from "../components/ConfDisplay";
import { ConfInput } from "../components/ConfInput";
import { ConfParameter } from "../components/ConfParameter";
import { ConfRadio } from "../components/ConfRadio";
import { ConfSelect } from "../components/ConfSelect";
import { ConfTab } from "../components/ConfTab";
import { ConfEpri } from "../components/epri/ConfEpri";
import { ServoSection } from "./components/ServoSection";
import { ServoStore } from "./useServo";

function configurationStyle(theme: ITheme) {
  return mergeStyleSets({
    root: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gap: "0",
    },
    configurator: {
      backgroundColor: theme.palette.white,
      flex: "0 1 75%",
      padding: ".5em",
      [mediaQuery.md]: {
        padding: ".5em 0 0",
      },
    },
    bom: {
      flex: "0 0 286px",
      padding: ".5em",
      [mediaQuery.md]: {
        padding: ".5em .5em 1em",
      },
    },
    wrapper: {
      display: "flex",
      flexDirection: "row",
      gap: "1em",
      [mediaQuery.md]: {
        flexDirection: "column",
      },
    },
  });
}

export function ServoConfigurator() {
  const theme = useTheme();
  const configurationStyled = useMemo(() => configurationStyle(theme), [theme]);
  const { root, configurator, bom, wrapper } = configurationStyled;
  const { cfg } = useConfigurationContext();

  useEffect(() => {
    registerDefaultConfiguratorComponents()
      .override(ComponentName.Section, ServoSection)
      .override(ComponentName.DummyViewport, ConfTab)
      .override(ComponentName.Parameter, ConfParameter)
      .override(ComponentName.DisplayOnly, (props: any) => (
        <ConfParameter {...props}>
          <ConfDisplay {...props} />
        </ConfParameter>
      ))
      .override(ComponentName.RadioButton, (props: any) => (
        <ConfParameter {...props}>
          <ConfRadio {...props} />
        </ConfParameter>
      ))
      .override(ComponentName.Dropdown, (props: any) => (
        <ConfParameter {...props}>
          <ConfSelect {...props} />
        </ConfParameter>
      ))
      .override(ComponentName.InputField, (props: any) => (
        <ConfParameter {...props}>
          <ConfInput {...props} />
        </ConfParameter>
      ))
      .override(ComponentName.Epri, ConfEpri)
      .override(ComponentName.Spinner, () => null);
  }, [cfg]);

  return (
    <ServoStore>
      <div className={root}>
        <Visualization />
        <div className={wrapper}>
          <div className={configurator}>
            <Configurator />
          </div>
          <div className={bom}>
            <Bom />
          </div>
        </div>
      </div>
    </ServoStore>
  );
}
