import { ITheme, mergeStyleSets, useTheme } from "@fluentui/react";
import { useMemo } from "react";

function loadingStyle(theme: ITheme) {
  return mergeStyleSets({
    customSpinner: {
      boxSizing: "border-box",
      display: "block",
      border: "3px solid",
      margin: "auto",
      borderTopColor: "#e0e0e0",
      borderBottomColor: "#e0e0e0",
      borderRightColor: "#e0e0e0",
      borderLeftColor: theme.palette.themePrimary,
      borderRadius: "50%",
      pointerEvents: "none",
      width: "20px",
      height: "20px",
      animation: "custom-lapp-spinner 1s infinite linear",
    },
  });
}

export function CustomSpinner({
  className,
}: Readonly<{
  className?: string;
}>) {
  const theme = useTheme();
  const { customSpinner } = useMemo(() => loadingStyle(theme), [theme]);

  return (
    <div className={className}>
      <div className={customSpinner} />
    </div>
  );
}
