import { Products } from "product";
import { equals, pathOr } from "ramda";

import { VISUALIZATION_POSITIONS } from "./vis3dConstants";

const get3DVisualizationEnabledPath = (article: string) => [
  article,
  "characteristicValues",
  "Enable_3D_Visualization",
  "values",
  0,
];

export const is3DVisualizationEnabled = (
  article: string,
  products: Products,
): boolean => {
  return equals(get3DVisualizationEnabled(article, products), "enable");
};

const get3DVisualizationEnabled: (
  article: string | undefined,
  products: Products,
) => string = (article: string | undefined, products: Products) =>
  pathOr(
    VISUALIZATION_POSITIONS.NOT_SHOWN,
    get3DVisualizationEnabledPath(article!),
    products,
  );
