import { useContext, useEffect, useState } from "react";

import { useConfigurationContext } from "../../../context/useConfiguration";
import { ProductContext } from "../../../context/useProducts";
import {
  DEFAULT_TABLE_MAX_HEIGHT,
  getConfTableMaxHeight,
} from "./confTableUtils";

export function useConfTableParams() {
  const { article } = useConfigurationContext();
  const { products } = useContext(ProductContext);
  const [tableMaxHeight, setTableMaxHeight] = useState<number>(
    DEFAULT_TABLE_MAX_HEIGHT,
  );

  useEffect(() => {
    setTableMaxHeight(getConfTableMaxHeight(products, article));
  }, [article, products]);

  return tableMaxHeight;
}
