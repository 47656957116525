import { equals, isEmpty, isNil, length, match, replace, test } from "ramda";

const SPECIAL_CHARACTER_REGEX = new RegExp(
  [
    "(?:[ßA-Za-z!-#%-*-/:;?@\\[-\\]_{}\\xA1\\xA7\\xAB\\xB6\\xB7\\xBB\\xBF\\u037E\\u0387\\u055A-\\u055F",
    "\\u0589\\u058A\\u05BE\\u05C0\\u05C3\\u05C6\\u05F3\\u05F4\\u0609\\u060A\\u060C\\u060D\\u061B\\u061E",
    "\\u061F\\u066A-\\u066D\\u06D4\\u0700-\\u070D\\u07F7-\\u07F9\\u0830-\\u083E\\u085E\\u0964\\u0965\\u0970",
    "\\u09FD\\u0A76\\u0AF0\\u0C84\\u0DF4\\u0E4F\\u0E5A\\u0E5B\\u0F04-\\u0F12\\u0F14\\u0F3A-\\u0F3D\\u0F85",
    "\\u0FD0-\\u0FD4\\u0FD9\\u0FDA\\u104A-\\u104F\\u10FB\\u1360-\\u1368\\u1400\\u166D\\u166E\\u169B\\u169C",
    "\\u16EB-\\u16ED\\u1735\\u1736\\u17D4-\\u17D6\\u17D8-\\u17DA\\u1800-\\u180A\\u1944\\u1945\\u1A1E\\u1A1F",
    "\\u1AA0-\\u1AA6\\u1AA8-\\u1AAD\\u1B5A-\\u1B60\\u1BFC-\\u1BFF\\u1C3B-\\u1C3F\\u1C7E\\u1C7F\\u1CC0-\\u1CC7",
    "\\u1CD3\\u2010-\\u2027\\u2030-\\u2043\\u2045-\\u2051\\u2053-\\u205E\\u207D\\u207E\\u208D\\u208E\\u2308-\\u230B",
    "\\u2329\\u232A\\u2768-\\u2775\\u27C5\\u27C6\\u27E6-\\u27EF\\u2983-\\u2998\\u29D8-\\u29DB\\u29FC\\u29FD",
    "\\u2CF9-\\u2CFC\\u2CFE\\u2CFF\\u2D70\\u2E00-\\u2E2E\\u2E30-\\u2E4E\\u3001-\\u3003\\u3008-\\u3011\\u3014-\\u301F",
    "\\u3030\\u303D\\u30A0\\u30FB\\uA4FE\\uA4FF\\uA60D-\\uA60F\\uA673\\uA67E\\uA6F2-\\uA6F7\\uA874-\\uA877\\uA8CE\\uA8CF",
    "\\uA8F8-\\uA8FA\\uA8FC\\uA92E\\uA92F\\uA95F\\uA9C1-\\uA9CD\\uA9DE\\uA9DF\\uAA5C-\\uAA5F\\uAADE\\uAADF\\uAAF0\\uAAF1",
    "\\uABEB\\uFD3E\\uFD3F\\uFE10-\\uFE19\\uFE30-\\uFE52\\uFE54-\\uFE61\\uFE63\\uFE68\\uFE6A\\uFE6B\\uFF01-\\uFF03",
    "\\uFF05-\\uFF0A\\uFF0C-\\uFF0F\\uFF1A\\uFF1B\\uFF1F\\uFF20\\uFF3B-\\uFF3D\\uFF3F\\uFF5B\\uFF5D\\uFF5F-\\uFF65]|",
    "\\uD800[\\uDD00-\\uDD02\\uDF9F\\uDFD0]|\\uD801\\uDD6F|\\uD802[\\uDC57\\uDD1F\\uDD3F\\uDE50-\\uDE58\\uDE7F\\uDEF0-",
    "\\uDEF6\\uDF39-\\uDF3F\\uDF99-\\uDF9C]|\\uD803[\\uDF55-\\uDF59]|\\uD804[\\uDC47-\\uDC4D\\uDCBB\\uDCBC\\uDCBE-",
    "\\uDCC1\\uDD40-\\uDD43\\uDD74\\uDD75\\uDDC5-\\uDDC8\\uDDCD\\uDDDB\\uDDDD-\\uDDDF\\uDE38-\\uDE3D\\uDEA9]|",
    "\\uD805[\\uDC4B-\\uDC4F\\uDC5B\\uDC5D\\uDCC6\\uDDC1-\\uDDD7\\uDE41-\\uDE43\\uDE60-\\uDE6C\\uDF3C-\\uDF3E]|",
    "\\uD806[\\uDC3B\\uDE3F-\\uDE46\\uDE9A-\\uDE9C\\uDE9E-\\uDEA2]|\\uD807[\\uDC41-\\uDC45\\uDC70\\uDC71\\uDEF7",
    "\\uDEF8]|\\uD809[\\uDC70-\\uDC74]|\\uD81A[\\uDE6E\\uDE6F\\uDEF5\\uDF37-\\uDF3B\\uDF44]|\\uD81B[\\uDE97-\\uDE9A]|",
    "\\uD82F\\uDC9F|\\uD836[\\uDE87-\\uDE8B]|\\uD83A[\\uDD5E\\uDD5F])",
  ].join(""),
  "gi",
);

/**
 * Create a number utility with specific formatting options.
 *
 * @param {string} language - The language/locale for formatting.
 * @param {number} [minimalDigits=0] - The minimum number of fractional digits.
 * @param {number} [decimalDigits=2] - The maximum number of fractional digits.
 * @param {boolean} [currency=false] - Whether to format as currency.
 * @returns {Object} - The number utility functions.
 */
export function numberUtils(
  language: string,
  minimalDigits = 0,
  decimalDigits = 2,
  currency = false,
) {
  const numberFormat = Intl.NumberFormat(language, {
    minimumFractionDigits: !isNil(minimalDigits)
      ? minimalDigits
      : decimalDigits,
    maximumFractionDigits: decimalDigits,
    ...(currency && {
      style: "currency",
      currency: "EUR",
    }),
  });

  const testValue = numberFormat.format(1111.1);
  const decimalSeparator = testValue.charAt(5);
  const thousandsSeparator = testValue.charAt(1);

  /**
   * Checks if a string represents a decimal number.
   *
   * @param {string} value - The value to check.
   * @returns {boolean} - Whether the value is a decimal number.
   */
  function isDecimal(value: string): boolean {
    if (replace(/\d+/g, "", value).length === 1) {
      return true;
    }
    return test(
      new RegExp(`\\${decimalSeparator}\\d+$`, "g"),
      replace(new RegExp(`[^\\d${decimalSeparator}]`, "g"), "", value),
    );
  }

  /**
   * Converts a formatted number string to a decimal number.
   *
   * @param {string} value - The formatted number string.
   * @returns {number} - The corresponding decimal number.
   */
  function toDecimalNumber(value: string): number {
    const correctMatchRegex = new RegExp(
      `^(-?\\d+)([${thousandsSeparator}](\\d+))([${decimalSeparator}](\\d+))$`,
    );
    const correctMatchGroups = match(correctMatchRegex, value);

    if (length(correctMatchGroups) > 0) {
      const thousands = correctMatchGroups[2].replace(thousandsSeparator, "");
      return Number(
        `${correctMatchGroups[1]}${thousands}.${correctMatchGroups[5] || ""}`,
      );
    }

    if (
      test(new RegExp(`^(-?\\d+)([${thousandsSeparator}](\\d{3,}))$`), value)
    ) {
      return Number(replace(/[^\d-]/, "", value));
    }

    const numberGroups = match(/(-?)(.+)(?=[^\d])[.,](\d+)$/, value);
    if (length(numberGroups) > 0) {
      const integerValue = numberGroups[2].replace(
        new RegExp(/[^\d]/, "g"),
        "",
      );
      return Number(
        `${numberGroups[1] || ""}${integerValue}.${numberGroups[3]}`,
      );
    }

    return Number(
      value
        .replace(new RegExp(`[^0-9${decimalSeparator}-]`, "g"), "")
        .replace(decimalSeparator, "."),
    );
  }

  /**
   * Converts a string value to a number.
   *
   * @param {string} value - The value to convert.
   * @returns {number} - The converted number.
   */
  function convertToNumber(value: string): number {
    if (
      !equals(typeof value, "string") ||
      !isEmpty(match(new RegExp(SPECIAL_CHARACTER_REGEX), value))
    ) {
      return Number.NaN;
    }
    if (equals(Number(value), 0)) {
      return 0;
    }
    if (isDecimal(value)) {
      return Number(toDecimalNumber(value));
    }
    return Number(replace(/[^\d]+/g, "", value));
  }

  /**
   * Converts a value to a localized string representation.
   *
   * @param {*} value - The value to convert.
   * @returns {string} - The localized string representation.
   * @throws {Error} - If the value is not a valid number.
   */
  function convertToLocalizedString(value: any): string {
    if (equals(typeof value, "number")) {
      return numberFormat.format(value);
    }
    throw new Error("not a valid number");
  }

  return { convertToNumber, convertToLocalizedString, toDecimalNumber };
}
