import { IProcessedStyleSet } from "@fluentui/react";
import { Separator } from "@fluentui/react/lib/Separator";
import { Text } from "@fluentui/react/lib/Text";
import React from "react";
import { useTranslation } from "react-i18next";

import { ParameterTO } from "@encoway/c-services-js-client";
import { IEpriStyles } from "@encoway/cui-configurator-components";

import { ParameterItem } from "./ParameterItem";
import { translatedValue, isCheckBox, addUnit } from "./epriUtils";

export interface AdjustmentsProps {
  classNames: IProcessedStyleSet<IEpriStyles>;
  affectedParameters: ParameterTO[];
}

export const Adjustments = (props: AdjustmentsProps) => {
  const { t } = useTranslation();
  const { classNames, affectedParameters } = props;
  const renderParameterItem = (parameter: ParameterTO) => {
    let value = translatedValue(parameter);
    if (value) {
      if (value === parameter.translatedName) {
        // Workaround to identify checked checkboxes,
        // because the parameter may not have information about its display component
        value = t("t:epri.checkbox-selected");
      } else if (isCheckBox(parameter)) {
        value = t("t:epri.checkbox-deselected");
      } else {
        value = addUnit(value, parameter);
      }
    }
    return (
      <ParameterItem
        key={parameter.id}
        classNames={classNames}
        parameter={parameter}
        value={value}
      />
    );
  };
  const adjustments = affectedParameters.map(renderParameterItem);
  return (
    <div className={classNames.section}>
      <Text variant="mediumPlus" className={classNames.headline}>
        {t("t:epri.adjustments-title")}
      </Text>
      <Separator />
      <div className={classNames.section}>{adjustments}</div>
    </div>
  );
};
