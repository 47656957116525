import { Stack, mergeStyleSets } from "@fluentui/react";
import { ConfiguratorComponentProps } from "configuration";
import { equals, find, isNil, not, pathOr, pipe, propEq } from "ramda";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ParameterTO } from "@encoway/c-services-js-client";

import Checkbox from "../../../components/Checkbox";

function sectionStyle() {
  return mergeStyleSets({
    wrapper: {
      padding: "2em 0",
    },
    root: {
      display: "flex",
      flexDirection: "row",
    },
  });
}

const CHECKED_VALUE = "Ja";
const SELECTED_VALUE_PATH = ["selectedValues", 0, "value"];

export function ConfCounterpartRadio(
  props: Readonly<ConfiguratorComponentProps<ParameterTO>>,
) {
  const { t } = useTranslation();
  const sectionStyled = useMemo(() => sectionStyle(), []);
  const isChecked = equals(
    pathOr("Nein", SELECTED_VALUE_PATH, props.data),
    CHECKED_VALUE,
  );

  function onChange(state: boolean | undefined) {
    if (not(isNil(state))) {
      props.onValueChanged(
        props.data,
        pipe(
          pathOr([], ["values"]),
          find(propEq("selected", false)),
          pathOr("Nein", ["value"]),
        )(props.data),
      );
    }
  }

  return (
    <Stack className={sectionStyled.wrapper}>
      <Checkbox
        label={t("t:glossary.test_stecker.counterpart")}
        onChange={onChange}
        checked={isChecked}
      />
    </Stack>
  );
}
