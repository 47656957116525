import { useEffect, useState } from "react";
import { Scene } from "three";

import { useVisualization } from "./useVisualization";

export const useScene = (): Scene | null => {
  const { vis } = useVisualization();
  const [scene, setScene] = useState<Scene | null>(null);

  useEffect(() => {
    if (vis.context && !scene) {
      setScene(vis.context.cloud.graph().scene());
    }
  }, [vis.context]);

  return scene;
};
