import {
  IModalStyleProps,
  IModalStyles,
  IStyleFunctionOrObject,
  ITheme,
  mergeStyleSets,
  Modal,
  Stack,
  useTheme,
} from "@fluentui/react";
import React, { ReactNode, useMemo } from "react";

import { mediaQuery } from "../../theme";
import { EpriClear } from "../Icons";

type DialogProps = {
  children: ReactNode;
  isOpen: boolean | undefined;
  showCloseButton?: boolean;
  onDismiss: () => void;

  styles?: IStyleFunctionOrObject<IModalStyleProps, IModalStyles>;
};

function dialogStyle(theme: ITheme) {
  return mergeStyleSets({
    innerRoot: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      minWidth: "50wv",
      overflowY: "auto",
      color: theme.palette.black,
      background: theme.palette.white,
      padding: "2em",
      maxHeight: "80vh",
      selectors: {
        [mediaQuery.lg]: {
          maxWidth: "initial",
        },
      },
    },
    icon: {
      alignItems: "center",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      position: "absolute",
      right: "30px",
      top: "2em",
      svg: {
        height: "22px",
      },
      ":hover": {
        color: theme.palette.neutralTertiary,
      },
    },
    main: {
      overflowY: "hidden",
      maxWidth: "760px",
      borderRadius: "6px",
    },
  });
}

export function Dialog({
  children,
  isOpen,
  styles,
  onDismiss,
  showCloseButton = true,
}: Readonly<DialogProps>) {
  const theme = useTheme();
  const dialogStyled = useMemo(() => dialogStyle(theme), [theme]);

  const { main } = dialogStyled;
  try {
    return (
      <Modal isOpen={isOpen} onDismiss={onDismiss} styles={{ main, ...styles }}>
        <div>
          <div className={dialogStyled.innerRoot}>
            {showCloseButton && (
              <Stack onClick={onDismiss} className={dialogStyled.icon}>
                <EpriClear />
              </Stack>
            )}
            {children}
          </div>
        </div>
      </Modal>
    );
  } catch (e) {
    // eslint-disable-next-line no-restricted-globals
    return <>{location.reload()}</>;
  }
}
