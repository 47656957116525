import { IChoiceGroupOption } from "@fluentui/react";
import { equals, isEmpty, isNil, path, reduce } from "ramda";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { validate } from "./requestUtils";

export interface CheckoutState {
  salutation: string;
  surname: string;
  lastname: string;
  customerNumber: string;
  emailAddress: string;
  phone: string;
  telephone: string;
  company: string;
  department: string;
  role: string;
  country: string;
  countryCode: string;
  location: string;
  street: string;
  comment: string;
  checkNewsletter: boolean;
  checkAnyContact: boolean;
  checkContactTelephone: boolean;
  checkContactEmail: boolean;
  standardOrder: boolean;
  ePlanOrder: boolean;
  article: string;
}

export interface ValidationState {
  validation: boolean;
  validationMessage: string | null;
}

export interface UseCheckoutModalState {
  checkoutState: CheckoutState;
  disabledFields?: string[];

  setCheckoutValue(
    key: string | string[],
    validationType?: string[],
  ): (event: ChangeEvent<HTMLElement> | string | boolean) => any;
}

export enum ORDER_TYPE {
  STANDARD_ORDER = "STANDARD_ORDER",
  E_PLAN_ORDER = "E_PLAN_ORDER",
}

export interface CheckoutConfiguration {
  MAX_LENGTH_INPUT: number;
  MAX_LENGTH_COMMENT: number;
  MANDATORY_FIELDS: string[];
  VALIDATION_FIELDS: {
    value: string;
    validation: RegExp;
  }[];
  RADIO_TRANSLATION_LIST: {
    [key: string]: IChoiceGroupOption[];
  };
}

export const CHECKOUT_MODAL_CONFIGURATION: CheckoutConfiguration = {
  MAX_LENGTH_INPUT: 255,
  MAX_LENGTH_COMMENT: 1024,
  MANDATORY_FIELDS: [
    "surname",
    "lastname",
    "emailAddress",
    "country",
    "countryCode",
    "location",
    "street",
    "company",
    "telephone",
  ],
  VALIDATION_FIELDS: [
    {
      value: "emailAddress",
      validation:
        /(?:[a-z\d!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z\d!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z\d](?:[a-z\d-]*[a-z\d])?\.)+[a-z\d](?:[a-z\d-]*[a-z\d])?|\[(?:(?:(2(5[0-5]|[0-4]\d)|1\d\d|[1-9]?\d))\.){3}(?:(2(5[0-5]|[0-4]\d)|1\d\d|[1-9]?\d)|[a-z\d-]*[a-z\d]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gm,
    },
    {
      value: "telephone",
      validation: /^(\(?([\d \-\\)–+/(]+)\)?([ .\-–/]?)(\d+))$/g,
    },
  ],
  RADIO_TRANSLATION_LIST: {
    salutation: [
      { key: "salutation.mister", text: "salutation.mister" },
      { key: "salutation.misses", text: "salutation.misses" },
      { key: "salutation.other", text: "salutation.other" },
    ],
  },
};

export const useRequest = (
  comment: string,
  orderType: ORDER_TYPE,
  selectedCountryCode: string,
  article: string,
) => {
  const { t } = useTranslation();
  const [checkoutState, setCheckoutState] = useState<CheckoutState>({
    salutation: "",
    surname: "",
    lastname: "",
    customerNumber: "",
    emailAddress: "",
    phone: "",
    telephone: "",
    company: "",
    department: "",
    role: "",
    country: t(
      `t:glossary.checkout.checkoutModal.dropDowns.${selectedCountryCode}`,
    ),
    countryCode: selectedCountryCode,
    location: "",
    street: "",
    comment,
    checkNewsletter: false,
    checkAnyContact: false,
    checkContactTelephone: false,
    checkContactEmail: false,
    standardOrder: orderType === ORDER_TYPE.STANDARD_ORDER,
    ePlanOrder: orderType === ORDER_TYPE.E_PLAN_ORDER,
    article,
  });
  const [disabledFields, setDisabledFields] = useState<string[]>([]);

  function setCountry(country: string) {
    setCheckoutState((prev) => ({
      ...prev,
      country: t(`t:glossary.checkout.checkoutModal.dropDowns.${country}`),
      countryCode: `${country}`,
    }));
  }

  useEffect(() => {
    setCheckoutState((prev) => ({ ...prev, comment }));
  }, [comment]);

  useEffect(() => {
    setCheckoutState((prev) => ({
      ...prev,
      standardOrder: orderType === ORDER_TYPE.STANDARD_ORDER,
      ePlanOrder: orderType === ORDER_TYPE.E_PLAN_ORDER,
    }));
  }, [orderType]);

  const setCheckoutValue =
    (key: string | string[], validationTypes: string[]) =>
    (eventValue: ChangeEvent<HTMLElement> | boolean | string) => {
      let value = path(["target", "value"], eventValue);
      if (
        equals(typeof eventValue, "string") ||
        equals(typeof eventValue, "boolean")
      ) {
        value = eventValue;
      }
      const setValue = () => {
        if (typeof key === "object") {
          return setCheckoutState(
            reduce(
              (state, stateKey) => ({ ...state, [stateKey]: value }),
              checkoutState,
              key,
            ),
          );
        }
        if (key === "countryCode") {
          return setCountry(String(value));
        }
        return setCheckoutState({ ...checkoutState, [key]: value });
      };

      if (isNil(validationTypes) || isEmpty(validationTypes)) {
        setValue();
        return { validation: true, validationMessage: null };
      }

      setValue();
      return typeof key === "string"
        ? validate(validationTypes, key, value + "")
        : null;
    };

  return {
    checkoutState,
    setCheckoutValue,
    setCheckoutState,
    setCountry,
    disabledFields,
    setDisabledFields,
  };
};
