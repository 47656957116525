import { isNil } from "ramda";
import { useContext } from "react";

import { VisualizationContext } from "../context/VisualizationProvider";

export function useVisualization() {
  const context = useContext(VisualizationContext);
  if (isNil(context)) {
    throw new Error(
      "visualizationProvider must be used within VisualizationContextProvider",
    );
  }
  return context;
}
