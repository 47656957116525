import {
  DefaultButton,
  ITheme,
  mergeStyleSets,
  Stack,
  Text,
  useTheme,
} from "@fluentui/react";
import { SelectedConnectorParameter } from "configuration";
import {
  addIndex,
  compose,
  equals,
  filter,
  includes,
  isEmpty,
  isNil,
  join,
  map,
  not,
  pathOr,
} from "ramda";
import { CSSProperties, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ParameterTO } from "@encoway/c-services-js-client";

import { EpriClear } from "../../../../components/Icons";

type SelectedItemsProps = {
  parameters: Array<SelectedConnectorParameter>;
  style?: CSSProperties;
  onUndo: (parameter: ParameterTO) => void;
};

type SelectedItemProps = {
  data: ParameterTO;
  translatedName: string | undefined;
  translatedValue: string | undefined;
  onUndo: () => void;
};

function stylesSelectedItems(theme: ITheme) {
  return mergeStyleSets({
    selectedItems: {
      alignItems: "flex-start",
      gap: ".4em",
      padding: ".8em",
    },
    selectedItem: {
      alignItems: "center",
      background: theme.palette.white,
      border: `1px solid ${theme.palette.tealDark}`,
      borderRadius: ".3em",
      display: "flex",
      flexFlow: "row",
      fontSize: "16px",
      height: "auto",
      lineHeight: "1.3",
      padding: ".2em .4em",
      width: "auto",
      ":hover, :focus, :active, :disabled": {
        background: theme.palette.white,
        border: `1px solid ${theme.palette.tealDark}`,
      },
      span: {
        display: "flex",
        flexFlow: "row",
        gap: ".4em",
        textAlign: "left",
      },
    },
    selectedItemName: {
      color: `${theme.palette.tealDark}`,
      fontWeight: "bold",
      fontSize: ".8em",
      lineHeight: "1.3",
    },
    selectedItemValue: {
      color: `${theme.palette.tealDark}`,
      fontSize: ".8em",
      lineHeight: "1",
    },
    undoIcon: {
      color: `${theme.palette.tealDark}`,
      width: "8px",
      svg: {
        width: "8px",
      },
    },
  });
}

function SelectedItem(props: Readonly<SelectedItemProps>) {
  const { data, translatedName, translatedValue, onUndo } = props;

  const { t } = useTranslation();
  const theme = useTheme();
  const styledTable = useMemo(() => stylesSelectedItems(theme), [theme]);

  const isCounterpart = useMemo(
    () =>
      compose(
        includes("_Gegenstueck"),
        pathOr("", ["viewPortProperties", "ref-table"]),
      )(data),
    [data],
  );
  const undoable = useMemo(() => pathOr(false, ["undoable"], data), [data]);

  if (isNil(translatedName)) {
    return null;
  }

  const name = isCounterpart
    ? join(" ", [
        translatedName,
        `(${t("t:glossary.test_stecker.counterpartChip")})`,
      ])
    : translatedName;

  return (
    <DefaultButton
      className={styledTable.selectedItem}
      disabled={not(undoable)}
      onClick={onUndo}
    >
      <Text className={styledTable.selectedItemName}>{name}: </Text>
      <Text className={styledTable.selectedItemValue}>{translatedValue}</Text>
      {undoable && (
        <Stack className={styledTable.undoIcon}>
          <EpriClear />
        </Stack>
      )}
    </DefaultButton>
  );
}

export function SelectedItems(props: Readonly<SelectedItemsProps>) {
  const { parameters, style, onUndo } = props;
  const theme = useTheme();
  const styledTable = useMemo(() => stylesSelectedItems(theme), [theme]);

  const filteredParameters = filter(
    (parameter) =>
      !isNil(parameter.value) && !equals(parameter.value, "NO_VALUE"),
    parameters,
  );

  if (isNil(filteredParameters) || isEmpty(filteredParameters)) {
    return null;
  }

  return (
    <Stack className={styledTable.selectedItems} style={style}>
      {addIndex<SelectedConnectorParameter>(map)(
        ({ data, translatedName, translatedValue }, index) => (
          <SelectedItem
            key={`selected-item-${index}`}
            data={data}
            translatedName={translatedName}
            translatedValue={translatedValue}
            onUndo={() => onUndo(data)}
          />
        ),
        filteredParameters,
      )}
    </Stack>
  );
}
