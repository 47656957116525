import {
  Bom,
  BomParameter,
  SelectedConnectorParameter,
  SelectedParameters,
} from "configuration";
import {
  any,
  equals,
  find,
  includes,
  indexOf,
  isEmpty,
  map,
  path,
  pathOr,
  pipe,
  propEq,
  reduce,
  split,
} from "ramda";

import { ContainerTO, GuiTO, ParameterTO } from "@encoway/c-services-js-client";

/**
 * Find a Parameter by its name
 *
 * @param {Array<Parameter>} parametersToSearch - current paramters to search
 * @param {string} name - name to find
 * @returns {Container | null} - fount container or null if none found
 */
export const findParameterByName = (
  parametersToSearch: Array<ParameterTO>,
  name: string,
) => find((child: ParameterTO) => equals(child.name, name), parametersToSearch);

function toBomParameter(parameter: ParameterTO): BomParameter {
  return {
    id: parameter.name,
    value: path(["selectedValues", 0, "value"], parameter),
  };
}

export function toBom(acc: Bom[], container: ContainerTO): Bom[] {
  const initialBom = {
    id: container.name,
    parameter: map(toBomParameter, container.parameters),
  };
  if (isEmpty(container.children)) {
    return [...acc, initialBom];
  }
  return [...acc, initialBom, ...reduce(toBom, [], container.children)];
}

/**
 * Determines if any selection by the user is in the active parameterTO array
 * @param parameters the parameters to search with
 * @returns boolean true if any selection had taken place
 */
export function anySelectionByUser(parameters: ParameterTO[]): boolean {
  const selectionSources = map(
    (param) => path(["selectedValues", 0, "selectionSource"], param),
    parameters,
  );
  return any((val) => equals("SET_BY_USER", val), selectionSources);
}

/**
 * Function to determine all selected parameters to the given reducer
 * @param acc the selected parameters accumulator
 * @param parameter the parameter to reduce
 * @returns selectedParameters the selected parameters object
 */
export function toSelectedParameters(
  acc: SelectedParameters,
  parameter: ParameterTO,
): SelectedParameters {
  return {
    ...acc,
    [parameter.name]: path(["selectedValues", 0, "value"], parameter),
  };
}

/**
 * Finds a specific parent container from parameter id
 * @param _id given id
 * @param containers containers to search
 * @returns undefined or container
 */
export function findParentContainer(
  _id: string,
  containers: ContainerTO[],
): ContainerTO | undefined {
  return reduce(
    (acc: ContainerTO | undefined, container: ContainerTO) => {
      if (acc) {
        return acc;
      } else if (any(({ id }) => equals(id, _id), container.parameters)) {
        return container;
      }
      return findParentContainer(_id, container.children);
    },
    undefined,
    containers,
  );
}

/**
 * Takes a parameter name and checks if its permitted and returns the index
 * @param parameterName parameter name
 * @param permittedParameters permitted parameters
 * @returns the index
 */
export function findParameterIndex(
  parameterName: string,
  permittedParameters: string,
): number {
  return reduce(
    (acc, currentParameter) => {
      const parameterArray = split(",", currentParameter);
      return includes(parameterName, parameterArray)
        ? indexOf(currentParameter, split(";", permittedParameters))
        : acc;
    },
    -1,
    split(";", permittedParameters),
  );
}

/**
 * Gets a container and returns a list of column widths if they are correctly stored under "CONFIGURATION_BOX" > "Column_widths".
 * Otherwise an empty list is returned.
 * @param containerTO the container to check for column_widths
 * @returns a list with column widths or an empty list
 */
export function getColumnWidthArray(containerTO: ContainerTO) {
  if (!propEq("name", "CONFIGURATION_BOX", containerTO)) {
    return [];
  }
  const columnWidths = findParameterByName(
    containerTO.parameters,
    "Column_widths",
  );
  const columnWidthsValue = path<string>(
    ["selectedValues", 0, "value"],
    columnWidths,
  );
  return columnWidthsValue ? split(",", columnWidthsValue) : [];
}

const ROOT_CHILDREN_PATH = ["rootContainer", "children"];
const PARAMTERS_PATH = ["children", 0, "parameters"];
const NAME_PATH = ["name"];
const TRANSLATED_NAME_PATH = ["translatedName"];
const TRANSLATED_VALUE_PATH = ["selectedValues", 0, "translatedValue"];
const VALUE_PATH = ["selectedValues", 0, "value"];
/**
 * Gets the currently selected parameters for the connector configurator
 * @param guiTO the guiTO of the current configuration
 * @returns a list of the selected parameters or an empty list.
 */
export function getSelectedConnectorParameters(
  guiTO: GuiTO | undefined,
): Array<SelectedConnectorParameter> {
  return pipe(
    pathOr([], ROOT_CHILDREN_PATH),
    find(propEq("name", "cb-tableview")),
    pathOr([], PARAMTERS_PATH),
    map((parameter) => ({
      data: parameter,
      name: pathOr("", NAME_PATH, parameter),
      translatedName: pathOr("", TRANSLATED_NAME_PATH, parameter),
      translatedValue: path<string>(TRANSLATED_VALUE_PATH, parameter),
      value: path<string>(VALUE_PATH, parameter),
    })),
  )(guiTO);
}
