import {
  HoverCard,
  HoverCardType,
  IHoverCard,
  IPlainCardProps,
  IRefObject,
  ITheme,
  mergeStyleSets,
  Stack,
  useTheme,
} from "@fluentui/react";
import { ReactNode, useMemo } from "react";

import { mediaQuery } from "../theme";
import { disableGlobalScrolling, enableGlobalScrolling } from "../utils";
import { InfoClear } from "./Icons";

type TooltipProps = {
  children: ReactNode;
  componentRef: IRefObject<IHoverCard>;
};

function tooltipStyle(theme: ITheme) {
  return mergeStyleSets({
    icon: {
      cursor: "pointer",
      color: theme.palette.black,
      selectors: {
        "&:hover": {
          color: theme.palette.neutralSecondary,
        },
        "&.mandatory": {
          color: theme.palette.themePrimary,
        },
        "&.mandatory:hover": {
          color: theme.palette.themeDark,
        },
      },
      svg: {
        verticalAlign: "middle",
      },
    },
  });
}

const tooltipStyling = mergeStyleSets({
  hoverCardHost: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },
  plainCard: {
    maxWidth: "600px",
    maxHeight: "600px",
    [mediaQuery.sm]: {
      maxHeight: "100%",
    },
  },
  textField: {
    paddingRight: 200,
  },
});

const onRenderPlainCard = (renderData: ReactNode): JSX.Element => {
  return <div className={tooltipStyling.plainCard}>{renderData}</div>;
};

export function Tooltip({ children, componentRef }: Readonly<TooltipProps>) {
  const theme = useTheme();
  const tooltipStyled = useMemo(() => tooltipStyle(theme), [theme]);
  const { icon } = tooltipStyled;

  const plainCardProps: IPlainCardProps = {
    onRenderPlainCard: onRenderPlainCard,
    renderData: children,
  };

  return (
    <HoverCard
      instantOpenOnClick={true}
      cardDismissDelay={100}
      type={HoverCardType.plain}
      plainCardProps={plainCardProps}
      componentRef={componentRef}
      onCardVisible={disableGlobalScrolling}
      onCardHide={enableGlobalScrolling}
      className={tooltipStyling.hoverCardHost}
    >
      <Stack className={icon}>
        <InfoClear />
      </Stack>
    </HoverCard>
  );
}
