import { IProcessedStyleSet } from "@fluentui/react";
import { Stack } from "@fluentui/react/lib/Stack";

import { ParameterTO } from "@encoway/c-services-js-client";
import { IEpriStyles } from "@encoway/cui-configurator-components";

import { TooltipText } from "./TooltipText";

export interface ParameterItemProps {
  parameter: ParameterTO;
  classNames: IProcessedStyleSet<IEpriStyles>;
  value?: string;
}

export const ParameterItem = (props: ParameterItemProps) => {
  const { classNames, parameter, value } = props;
  let displayValue = null;
  if (value) {
    displayValue = (
      <div className={classNames.parameterValue}>
        <TooltipText text={value} classNames={classNames} />
      </div>
    );
  }
  return (
    <Stack
      key={parameter.id}
      horizontal
      horizontalAlign="space-between"
      className={classNames.parameter}
    >
      <div className={classNames.parameterName}>
        <TooltipText text={parameter.translatedName} classNames={classNames} />
      </div>
      {displayValue}
    </Stack>
  );
};
