import { ExtendedProduct } from "product";
import { Path, equals, not, pathOr } from "ramda";

import { SingleCell } from "../pages/configurator/components/confTableUtils";

export const IMAGE_URI_PATH: Path = [
  "characteristicValues",
  "ref_primary_image",
  "values",
  0,
];

export const isImage = (cell: SingleCell) => equals(cell.type, "image");

export const getImageUri = (
  mediaID: string,
  imageUri: string,
  foundProduct: ExtendedProduct,
) => {
  if (not(equals(mediaID, "MISSING_MEDIA_ID"))) {
    return imageUri;
  }
  return pathOr<string>("", IMAGE_URI_PATH, foundProduct);
};
