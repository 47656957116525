import {
  createContext,
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

import { Visualization } from "@encoway/visual-editor";

import { useConfigurationContext } from "../../../../../../context/useConfiguration";
import { useSettings } from "../../../../../../context/useSettings";
import { VisualizationSettings } from "../../../../../../settings";

interface VisualizationContextProps {
  vis: useVisualizationState;
  visRef: MutableRefObject<any>;
  visSettings: typeof VisualizationSettings.visualization;
  load(): Promise<void>;
  showPopup: boolean;
  setShowPopup: Dispatch<SetStateAction<boolean>>;
}

export const VisualizationContext = createContext<
  VisualizationContextProps | undefined
>(undefined);

/**
 *  A provider component for the Visualization.
 */
export const VisualizationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [vis, setVis] = useState<VisualizationState>({
    node: undefined,
    context: undefined,
  });
  const [showPopup, setShowPopup] = useState(false);
  const { http, settings } = useSettings();
  const { article, cfg } = useConfigurationContext();

  const { baseUrl, version, token } = VisualizationSettings.visualization;

  const visRef = useRef<any>();

  const load = useCallback(async () => {
    const visualizationContext = await Visualization.load(
      baseUrl,
      version,
      token,
    );

    visualizationContext.cloud.addServer({
      name: "default",
      baseUrl: settings.showroom.url,
      //baseUrl: "https://showroom.encoway-services.de/commerce/",
      http: http,
      locale: "de-DE",
    });

    const node = await visualizationContext.cloud.mount(
      null,
      "product",
      "article",
      {
        id: article,
        configurationId: cfg?.configurationId,
      },
      "default",
      true,
    );

    setVis({ node: node, context: visualizationContext });
  }, [article, cfg]);

  return (
    <VisualizationContext.Provider
      value={useMemo(
        () => ({
          vis,
          visRef,
          visSettings: VisualizationSettings.visualization,
          load,
          showPopup,
          setShowPopup,
        }),
        [vis, visRef, load, showPopup, setShowPopup],
      )}
    >
      {children}
    </VisualizationContext.Provider>
  );
};

/**
 * A hook exposing the visualization context.
 */
export const useVisualization = () => {
  const context = useContext(VisualizationContext);
  if (!context) {
    throw new Error(
      "visualizationProvider must be used within a VisualizationProvider",
    );
  }
  return context;
};
