import { useMemo } from "react";
import {
  RouterProvider,
  createHashRouter,
  createMemoryRouter,
} from "react-router-dom";

import { isStandalone } from "../utils";
import { routes } from "./routes";

export function LappRouter() {
  const router = useMemo(
    () =>
      isStandalone() ? createHashRouter(routes) : createMemoryRouter(routes),
    [],
  );

  return <RouterProvider router={router} />;
}
