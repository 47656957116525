export function EpriStyles(theme: any): any {
  return {
    modal: {
      selectors: {
        ".ms-Dialog-main": {
          border: "none",
          borderRadius: "6px",
        },
        ".ms-Dialog-title": {
          color: theme.palette.black,
        },
      },
    },
    title: {
      color: theme.palette.black,
    },
    content: {
      color: theme.palette.black,
    },
    section: {
      marginBottom: "2em",
    },
    headline: {
      color: theme.palette.black,
      fontWeight: "bold",
    },
    parameter: {
      paddingRight: "1em",
    },
    parameterName: {
      width: "30%",
    },
    parameterValue: {
      width: "60%",
    },
    tooltip: {},
    text: { color: theme.palette.black },
    footer: {},
    acceptButton: {
      width: "auto",
      height: "auto",
      padding: ".75em",
      color: theme.palette.white,
      backgroundColor: theme.palette.themePrimary,
      borderRadius: "6px",
    },
    rejectButton: {
      width: "auto",
      height: "auto",
      padding: ".75em",
      color: theme.palette.themePrimary,
      backgroundColor: theme.palette.white,
      border: `1px solid ${theme.palette.themePrimary}`,
      borderRadius: "6px",
      ":hover, :focus, :active": {
        color: theme.palette.themeDarkAlt,
        border: `1px solid ${theme.palette.themeDarkAlt}`,
        borderRadius: "6px",
      },
    },
  };
}
