import {
  Image,
  ImageFit,
  ITheme,
  mergeStyleSets,
  Stack,
  Text,
  useTheme,
} from "@fluentui/react";
import { BomProduct } from "bom";
import { find, isEmpty, isNil, not, path, propEq } from "ramda";
import { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useConfigurationContext } from "../../../context/useConfiguration";
import { ProductContext } from "../../../context/useProducts";
import { getImageUri } from "../../../utils/imageUtils";
import { BomSection } from "./BomSection";
import { retrieveBomIndividualCharacteristic } from "./bomUtils";

function bomItemStyle(theme: ITheme) {
  return mergeStyleSets({
    root: {
      alignItems: "flex-start",
      borderBottom: "1px solid #9e9e9e",
      display: "flex",
      flexDirection: "column",
      padding: ".5em 0",
      gap: ".25em",
    },
    imgWrapper: {
      borderRadius: 8,
    },
    textContainer: {
      display: "flex",
      flexDirection: "row",
    },
    textWrapper: {
      textAlign: "left",
    },
    textAmountWrapper: {
      display: "flex",
      flexFlow: "row nowrap",
      gap: "2em",
    },
    text: {
      color: theme.palette.black,
      fontWeight: "600",
      lineHeight: "1.2",
    },
    textAmountLabel: {
      color: theme.palette.black,
      fontWeight: "600",
      lineHeight: "1.2",
    },
    textAmountText: {
      color: theme.palette.black,
      lineHeight: "1.2",
    },
  });
}

export function BomItem({
  bomProduct,
  withId = false,
}: Readonly<{
  bomProduct: BomProduct;
  withId?: boolean;
}>) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { products, getProducts } = useContext(ProductContext);
  const { article } = useConfigurationContext();
  const {
    imageUri,
    translatedProductName,
    amount,
    mediaID,
    isCable,
    productId,
  } = bomProduct;
  const bomItemStyled = useMemo(() => bomItemStyle(theme), [theme]);
  const {
    root,
    textAmountWrapper,
    imgWrapper,
    textWrapper,
    text,
    textAmountLabel,
    textAmountText,
  } = bomItemStyled;
  const articleId = useMemo(
    () =>
      find(
        propEq("characteristicId", "Artikelnummer"),
        bomProduct.productCharacteristics,
      )?.values[0] || bomProduct.productId,
    [bomProduct],
  );
  const finalImageUri = products
    ? getImageUri(mediaID, imageUri, products[productId])
    : "";

  useEffect(() => {
    if (isNil(path([productId], products))) {
      getProducts(productId);
    }
  }, [productId]);

  return (
    <Stack className={root}>
      {not(isEmpty(finalImageUri)) && (
        <Stack>
          <Image
            className={imgWrapper}
            imageFit={ImageFit.contain}
            height={60}
            width={60}
            src={finalImageUri}
            alt={translatedProductName}
          />
        </Stack>
      )}
      <Stack className={textWrapper}>
        <Text className={text}>{translatedProductName}</Text>
      </Stack>
      <Stack className={textAmountWrapper}>
        <Text className={textAmountLabel}>
          {isCable
            ? t("t:glossary.checkout.metres_short")
            : t("t:glossary.checkout.pieces_short")}
          :
        </Text>
        <Text className={textAmountText}>{amount}</Text>
      </Stack>
      {withId && <Stack className={textAmountWrapper}>{articleId}</Stack>}
      <BomSection
        individualSectionNames={retrieveBomIndividualCharacteristic(
          article!,
          products,
        )}
      />
    </Stack>
  );
}
