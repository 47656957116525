import {
  DefaultButton,
  ITheme,
  Stack,
  Text,
  mergeStyleSets,
  useTheme,
} from "@fluentui/react";
import { equals, gt, isEmpty, length, map, not, slice } from "ramda";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ChevronDownIcon, ChevronUpIcon } from "../../../components/Icons";
import { Section } from "../../../components/Section";
import { BomContext } from "../../../context/useBom";
import { mediaQuery } from "../../../theme";
import { BomButtons } from "./BomButtons";
import { BomDialogs } from "./BomDialogs";
import { BomItem } from "./BomItem";
import { BomTotal } from "./BomTotal";
import { useBomDialog } from "./useBomDialog";

function bomStyle(theme: ITheme) {
  return mergeStyleSets({
    root: {
      background: theme.palette.white,
      borderRadius: "6px",
      boxShadow: "rgba(33, 33, 33, 0.2) 0px 2px 8px 0px",
      boxSizing: "border-box",
      padding: 0,
      selectors: {},
    },
    bomContainer: {
      borderRadius: "6px",
      overflow: "hidden",
      padding: "1em",
    },
    container: {
      padding: "0",
      justifyContent: "center",
      selectors: {
        ":first-child": {
          textAlign: "center",
          padding: "0 0 10px 0",
        },
      },
    },
    label: {
      color: theme.palette.black,
    },
    buttonContainer: {
      display: "grid",
      gridTemplateColumns: "1fr",
      padding: ".5em 0 .5em",
      gap: "10px",
      selectors: {
        ".ms-Button": {
          borderRadius: ".25em",
        },
      },
    },
    articleContainer: {
      padding: "0 0",
      color: theme.palette.black,
      justifyContent: "center",
      selectors: {
        ":first-child": {
          textAlign: "center",
          padding: "0 0 10px 0",
        },
      },
    },
    article: {
      display: "flex",
      flexFlow: "column nowrap",
      gap: ".5em",
      padding: ".5em .5em 3em",
      maxHeight: "468px",
      overflowY: "auto",
      [mediaQuery.md]: {
        maxHeight: "auto",
      },
    },
    additionalInformationContainer: {
      padding: "0",
      justifyContent: "center",
      selectors: {
        ":first-child": {
          textAlign: "center",
          padding: "0",
        },
        span: {
          color: theme.palette.black,
        },
      },
    },
    expandBomArticlesButton: {
      alignItems: "center",
      background: "none",
      color: theme.palette.black,
      display: "flex",
      fontSize: "18px",
      fontWeight: "100",
      justifyContent: "flex-start",
      padding: "24px 0",
      maxWidth: "100%",
      span: {
        fontWeight: "300",
        gap: "10px",
      },
      ":focus": {
        backgroundColor: "none",
        boxShadow: "none",
        color: theme.palette.black,
        fontWeight: "600",
      },
      ":hover": {
        backgroundColor: "none",
        boxShadow: "none",
        color: theme.palette.black,
        fontWeight: "600",
      },
      ":active": {
        backgroundColor: "none",
        boxShadow: "none",
        color: theme.palette.themePrimary,
        fontWeight: "600",
      },
    },
    textWrapper: {
      padding: ".5em 0 0",
    },
    texts: {
      maxHeight: "40em",
      minHeight: "8em",
      width: "100%",
      padding: ".5em",
      borderRadius: "5px",
      border: `1px solid ${theme.palette.neutralTertiary}`,
      color: theme.palette.black,
      resize: "vertical",
    },
    textCounter: {
      color: theme.palette.black,
      textAlign: "right",
      margin: "0",
      fontWeight: "600",
    },
    textHeading: {
      borderBottom: "2px solid #bebebe",
      padding: "0",
    },
    chevron: {},
  });
}

const MAX_NUMBER_OF_VISIBLE_BOM_ITEMS = 3;

type ShowAllBomItemsButtonProps = {
  isBomExpanded: boolean;
  onClick: () => void;
};

function ShowAllBomItemsButton(props: Readonly<ShowAllBomItemsButtonProps>) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { bom } = useContext(BomContext);
  const { isBomExpanded, onClick } = props;
  const bomStyled = useMemo(() => bomStyle(theme), [theme]);
  const { chevron, expandBomArticlesButton } = bomStyled;

  if (gt(length(bom), MAX_NUMBER_OF_VISIBLE_BOM_ITEMS)) {
    return (
      <DefaultButton className={expandBomArticlesButton} onClick={onClick}>
        {isBomExpanded ? (
          <>
            <Stack.Item>
              <Stack className={chevron}>
                <ChevronUpIcon />
              </Stack>
            </Stack.Item>
            <Stack.Item>{t("t:glossary.checkout.collapseBom")}</Stack.Item>
          </>
        ) : (
          <>
            <Stack.Item>
              <Stack className={chevron}>
                <ChevronDownIcon />
              </Stack>
            </Stack.Item>
            <Stack.Item>{t("t:glossary.checkout.expandBom")}</Stack.Item>
          </>
        )}
      </DefaultButton>
    );
  }

  return null;
}

export function Bom() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { bom, visibility, setText } = useContext(BomContext);
  const bomDialogStore = useBomDialog(false);
  const { sendToCart, openMail, toggle, loading } = bomDialogStore;
  const bomStyled = useMemo(() => bomStyle(theme), [theme]);
  const {
    additionalInformationContainer,
    articleContainer,
    article,
    bomContainer,
    buttonContainer,
    container,
    label,
    root,
    textCounter,
    textHeading,
    texts,
    textWrapper,
  } = bomStyled;
  const [internalText, setInternalText] = useState<string>("");
  const [showAllBomItems, setShowAllBomItems] = useState<boolean>(false);

  return (
    <>
      <BomDialogs store={bomDialogStore} />
      <Stack className={root}>
        <Stack className={bomContainer}>
          <Stack className={textHeading}>
            <Section
              variant="large"
              className={label}
              label={t("t:glossary.checkout.yourConfiguration")}
            />
          </Stack>
          <Stack className={container}>
            <BomTotal />
          </Stack>
          <Stack className={buttonContainer}>
            <BomButtons
              openMail={openMail}
              sendToCart={sendToCart}
              loading={loading}
              toggle={toggle}
            />
          </Stack>
          {!isEmpty(bom) && (
            <Stack className={articleContainer}>
              <Stack.Item className={article}>
                {map(
                  (bomProduct) => (
                    <BomItem
                      key={bomProduct.translatedProductName}
                      bomProduct={bomProduct}
                    />
                  ),
                  showAllBomItems
                    ? bom
                    : slice(0, MAX_NUMBER_OF_VISIBLE_BOM_ITEMS, bom),
                )}
                <Stack>
                  <ShowAllBomItemsButton
                    isBomExpanded={showAllBomItems}
                    onClick={() => setShowAllBomItems((prev) => !prev)}
                  />
                </Stack>
              </Stack.Item>
            </Stack>
          )}
          {not(equals(visibility.commentary, "hidden")) && (
            <Stack className={additionalInformationContainer}>
              <Stack.Item className={textWrapper}>
                <Stack.Item>
                  <Section
                    variant="mediumPlus"
                    label={t("t:glossary.checkout.additionalInformation")}
                  />
                </Stack.Item>
                <textarea
                  maxLength={1024}
                  className={texts}
                  value={internalText}
                  onBlur={() => setText(internalText)}
                  onChange={(e) => setInternalText(e.currentTarget.value)}
                  placeholder={t(
                    "t:glossary.checkout.doYouWantToAddInformation",
                  )}
                />
                <Stack>
                  <Text variant="small" className={textCounter}>
                    {t("t:glossary.checkout.charsLeft", {
                      currentChars: 1024 - internalText.length,
                      maxChars: 1024,
                    })}
                  </Text>
                </Stack>
              </Stack.Item>
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
}
