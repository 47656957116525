import { ITheme, mergeStyleSets, Text, useTheme } from "@fluentui/react";
import classNames from "classnames";
import { ConfiguratorComponentProps } from "configuration";
import { equals, pathOr, slice } from "ramda";
import React, { useMemo } from "react";

import { ParameterTO } from "@encoway/c-services-js-client";

import { useSettings } from "../../../../context/useSettings";
import { ConfParameterTooltip } from "../../components/ConfParameterTooltip";
import { useConfParameter } from "../../components/useConfParameter";

function styledParameter(theme: ITheme) {
  return mergeStyleSets({
    root: {
      display: "flex",
      alignItems: "center",
      margin: "15px 0 0 0",
    },
    text: {
      padding: "0 2px 0 0",
      color: theme.palette.black,
      fontWeight: "600",
      selectors: {
        "&.terminal": {
          color: theme.palette.themePrimary,
        },
      },
    },
    wrapper: {
      display: "flex",
      flexDirection: "column",
    },
  });
}

type ConnectorParameterProps = ConfiguratorComponentProps<ParameterTO>;

export function ConnectorParameter(props: Readonly<ConnectorParameterProps>) {
  const { settings } = useSettings();
  const theme = useTheme();
  const { root, text, wrapper } = useMemo(
    () => styledParameter(theme),
    [theme],
  );
  const { data, children } = props;
  const newProps = useConfParameter(props);
  const modalHidden = pathOr(
    "",
    [settings.studio.boms.configuration.questionMark],
    props.data.viewPortProperties,
  );
  const newChild = pathOr(<></>, [0], children);
  return (
    <div className={wrapper}>
      <div className={classNames("parameter", root)}>
        <Text
          className={classNames(text, {
            terminal: !data.terminal && data.mandatory,
          })}
          variant="small"
        >
          {data.translatedName || data.name}
          {data.mandatory && "*"}
        </Text>
        {!equals(slice(2, 3, modalHidden), "1") && (
          <ConfParameterTooltip {...props} />
        )}
      </div>
      {React.cloneElement(newChild, newProps)}
    </div>
  );
}
