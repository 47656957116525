import { mergeStyleSets } from "@fluentui/react";
import classNames from "classnames";
import { useMemo } from "react";

import { VisProperty } from "../../context/VisualizationProvider";

function componentStyle() {
  return mergeStyleSets({
    image: {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      height: "100%",
      "&.flip": {
        transform: "scaleX(-1)",
      },
    },
  });
}

export function VisImage({
  uri,
  alt,
  visProperty,
}: Readonly<{
  uri: string;
  alt: string;
  visProperty: VisProperty;
}>) {
  const { image } = useMemo(() => componentStyle(), []);

  if (visProperty.vis_background_image) {
    return (
      <div
        style={{
          background: `url(${uri})${
            visProperty.vis_centered && " center center"
          } repeat-x`,
          width: visProperty.vis_min_width,
          height: "100%",
          flex: visProperty.vis_stretched ? 1 : 0,
        }}
        className={classNames(image, { flip: visProperty.vis_mirror })}
      ></div>
    );
  }
  return (
    <div>
      <img
        src={uri}
        className={classNames(image, { flip: visProperty.vis_mirror })}
        style={{ width: visProperty.vis_min_width }}
        alt={alt}
      />
    </div>
  );
}
