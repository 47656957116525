import { ITheme, mergeStyleSets, useTheme } from "@fluentui/react";
import { ReactNode, useMemo } from "react";

function loadingStyle(theme: ITheme) {
  return mergeStyleSets({
    root: {
      display: "flex",
      height: "95vh",
      alignItems: "center",
      justifyContent: "center",
    },
    spinner: {
      margin: "0 0 10vh 0",
    },
    customSpinner: {
      boxSizing: "border-box",
      display: "block",
      border: "3px solid",
      margin: "auto",
      borderTopColor: "#e0e0e0",
      borderBottomColor: "#e0e0e0",
      borderRightColor: "#e0e0e0",
      borderLeftColor: theme.palette.themePrimary,
      borderRadius: "50%",
      pointerEvents: "none",
      width: "20px",
      height: "20px",
      animation: "custom-lapp-spinner 1s infinite linear",
    },
  });
}

export function Loading({ children }: Readonly<{ children?: ReactNode }>) {
  const theme = useTheme();
  const { root, spinner, customSpinner } = useMemo(
    () => loadingStyle(theme),
    [theme],
  );

  return (
    <div className={root}>
      <div className={spinner}>
        {children ? { children } : <div className={customSpinner} />}
      </div>
    </div>
  );
}
