import { AxiosInstance } from "axios";
import { replace } from "ramda";

import { Language } from "@encoway/c-services-js-client";

export async function fetchLanguages(
  axios: AxiosInstance,
  language: string,
): Promise<Language[]> {
  const res = await axios.get<{ languages: Language[] }>(
    `api/catalog/languages`,
    { headers: { "Accept-Language": language } },
  );
  return res.data.languages;
}

export async function fetchResource<T>(
  axios: AxiosInstance,
  uri: string,
  language: string,
): Promise<T> {
  const replacedSSLUri = replace(
    /https?:\/\/([^/:]+)(:\d+)?/,
    "https://$1",
    uri,
  );
  const translationRes = await axios.get<T>(replacedSSLUri, {
    headers: { "Accept-Language": language },
  });
  return translationRes.data;
}
