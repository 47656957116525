import { useAuth0 } from "@auth0/auth0-react";
import { mergeStyleSets, PrimaryButton } from "@fluentui/react";
import { ExtendedProduct } from "product";
import { drop, isNil, map, path } from "ramda";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { AppContext } from "../../context/useApp";
import { usePartner } from "../../context/usePartner";
import { ProductContext } from "../../context/useProducts";

const styles = mergeStyleSets({
  navbar: {
    height: "116px",
    padding: "24px .5em",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  end: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    alignItems: "flex-end",
  },
  button: {
    marginTop: ".25em",
    borderRadius: "0.25em",
    padding: "1em",
  },
  headline: {
    padding: "0 .25em",
    fontSize: "34px",
    fontWeight: 600,
    color: "#212121",
    display: "inline",
    paddingBottom: "1em",
    paddingTop: "1em",
  },
  logo: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
    maxWidth: "200px",
    maxHeight: "75px",
  },
  headerContent: {
    padding: "1em 0.5em",
    p: {
      margin: 0,
    },
    "ol, ul": {
      margin: "0 0 0 1.1em",
      padding: 0,
    },
    table: {
      width: "100%",
    },
    "table, tr, td": {
      border: 0,
    },
    a: {
      color: "rgb(156, 156, 155)",
      textDecoration: "none",
      ":hover": {
        textDecoration: "underline",
      },
    },
  },
});

export function Header() {
  const partnerInfo = usePartner();
  const { auth } = useContext(AppContext);
  const { products } = useContext(ProductContext);
  const { article, locale } = useParams();
  const { logout } = useAuth0();
  const { t } = useTranslation();
  const product = useMemo(
    () => path<ExtendedProduct>([article + ""], products),
    [products],
  );
  const navigate = useNavigate();
  const location = useLocation();

  if (!partnerInfo && !product) {
    return null;
  }

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    navigate(`/${event.target.value}/${drop(7, location.pathname)}`);
  };

  return (
    <nav role="navigation" aria-label="main navigation">
      <div className={styles.navbar}>
        <div className="navbar-brand">
          <a className="navbar-item" href="#/">
            <img
              className={styles.logo}
              src={partnerInfo?.logoUrl}
              alt="Logo"
            />
          </a>
        </div>
        <div className={styles.end}>
          <select
            id="language-selection"
            className="language-selection"
            onChange={handleChange}
            value={locale}
          >
            {map(
              (lang) => (
                <option key={lang.value} value={lang.value}>
                  {lang.label}
                </option>
              ),
              partnerInfo?.languages ?? [],
            )}
          </select>
          {!isNil(auth?.user) && (
            <PrimaryButton
              className={styles.button}
              onClick={() => {
                logout({
                  returnTo: `${window.location.origin}${window.location.pathname}`,
                });
                sessionStorage.removeItem("auth");
              }}
            >
              {t("t:common.button.logout")}
            </PrimaryButton>
          )}
        </div>
      </div>
      <h1 className={styles.headline}>{product?.name}</h1>
      {partnerInfo?.headerContent && (
        <div
          className={styles.headerContent}
          dangerouslySetInnerHTML={{ __html: partnerInfo.headerContent }}
        />
      )}
    </nav>
  );
}
