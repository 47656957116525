import { RefObject, useRef, useState } from "react";

/**
 * Interface defining the properties of the `ClipboardStore`
 */
interface ClipboardStore {
  /**
   * Copies the given link to the clipboard
   * @param {string} newLink The link to copy
   * @returns {void}
   */
  copy(newLink: string): void;

  /**
   * Closes the clipboard by resetting the copied flag to false
   * @returns {void}
   */
  close(): void;

  /**
   * Indicates whether the link has been copied to the clipboard
   */
  copied: boolean;
  /**
   * A reference to a DOM element that can be used to trigger a copy action
   */
  ref: RefObject<HTMLElement>;
}

/**
 * Custom hook that allows for copying links to the clipboard
 * @returns An object with `copy`, `close`, `copied`, and `ref` properties
 */
export function useClipboard(): ClipboardStore {
  const [copied, setCopied] = useState(false);
  const ref = useRef<HTMLElement>(null);

  function copy(newLink: string): void {
    setCopied(false);

    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(newLink)
        .then(() => setCopied(true))
        .catch(() => {
          execCopy(newLink);
        });
    } else {
      execCopy(newLink);
    }
  }

  /**
   * Executes the copying of the given link to the clipboard using a fallback method if the Clipboard API is not available
   * @param {string} newLink The link to copy
   * @returns {void}
   */
  function execCopy(newLink: string) {
    const textArea = document.createElement("textarea");
    textArea.value = newLink;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      document.execCommand("copy");
      setCopied(true);
    } catch (err) {
      console.error("Copy failed: ", err);
    }

    document.body.removeChild(textArea);
  }

  function close() {
    setCopied(false);
  }

  return { copy, close, copied, ref };
}
