import { ITheme, Stack, mergeStyleSets, useTheme } from "@fluentui/react";
import { useEffect, useMemo, useState } from "react";

import { Constants } from "@encoway/react-configurator";
import { VisualEditor } from "@encoway/visual-editor";

import { useConfigurationContext } from "../../../../../context/useConfiguration";
import { refineVisualizationSettings } from "../visualizationHelper/VisualizationSettingsRefiner";
import { VisualizationOverlay } from "./VisualizationOverlay";
import { useShadow } from "./hooks/useShadow";
import { useVisualization } from "./hooks/useVisualization";

function componentStyle(theme: ITheme) {
  return mergeStyleSets({
    visualizationStyle: {
      backgroundColor: theme.palette.white,
      display: "flex",
      height: "100%",
      justifyContent: "center",
      position: "relative",
    },
  });
}
/**
 * Visualization component rendering the Visual-Editor and overlay components.
 */
export function Visualization({
  popupButtonHandler,
  inPopup,
}: Readonly<IVisualisationProps>): any {
  const [visualizationIsReady, setVisualizationIsReady] = useState(false);
  const { vis, visRef, visSettings, load } = useVisualization();
  const { eventBus } = useConfigurationContext();
  const theme = useTheme();
  const { visualizationStyle } = useMemo(() => componentStyle(theme), [theme]);

  useShadow();
  useEffect(() => {
    if (vis.context === undefined) {
      load();
    }
  }, [load, vis.context]);

  useEffect(() => {
    if (vis.node && eventBus) {
      return eventBus
        .filter((e: any) => {
          return (
            e.event === Constants.Events.InitialState ||
            e.event === Constants.Events.UpdateState
          );
        })
        .onValue(() => {
          vis?.node?.update();
        });
    }
  }, [vis]);

  if (vis.context) {
    return (
      <Stack className={visualizationStyle}>
        <VisualEditor
          key={"encoway_visualisation"}
          onReady={() => {
            visRef.current.performAction("zoom", { viewport: "isometric" });
            setVisualizationIsReady(true);
          }}
          style={{ height: "100%" }}
          visualization={vis.context}
          settings={refineVisualizationSettings(visSettings.settings)}
          ref={visRef}
        />
        {visualizationIsReady && (
          <VisualizationOverlay
            inPopup={inPopup}
            popupButtonHandler={popupButtonHandler}
          />
        )}
      </Stack>
    );
  }

  return null;
}
