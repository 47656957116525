import {
  addIndex,
  compose,
  find,
  findIndex,
  isNil,
  join,
  length,
  map,
  path,
  pathOr,
  propEq,
  slice,
  split,
} from "ramda";

import {
  ConfigurationService,
  ContainerTO,
  GuiTO,
} from "@encoway/c-services-js-client";

// the sum of all three value sections mustn't be greater than 31
// example: Linke Konfektion: Steckertyp: E
// see: https://jira.encoway-services.de/browse/LCC-434
const MAX_VALUE_LENGTH = 9; // (31 - 4) / 3 = 9
const CHILDREN_PATH = ["rootContainer", "children"];
export const CONFIGURATION_ID_PATH = ["cfg", "configurationId"];
export const ARTICLE_NAME_PATH = ["cfg", "configuration", "articleName"];

const TEST_STECKER_INDEX_MAP: Record<number, number | undefined> = {
  0: 0,
  1: 1,
  2: 2,
  3: Infinity,
  4: 0,
  5: 1,
  6: 2,
};

const CONFIGURATOR_MAP: Record<
  string,
  Record<number, number | undefined> | undefined
> = {
  Test_Stecker: TEST_STECKER_INDEX_MAP,
};

export const determineEmosIndex = (
  cfg: ConfigurationService,
  index: number,
) => {
  const configuratorName = path<string>(ARTICLE_NAME_PATH, cfg);
  if (isNil(configuratorName)) {
    return index;
  }
  return pathOr(index, [configuratorName, index], CONFIGURATOR_MAP);
};

export const findRootChildren = (
  name: string,
  guiTO: GuiTO,
): Array<ContainerTO> =>
  pathOr(
    [],
    ["children"],
    find(propEq("name", name), pathOr([], CHILDREN_PATH, guiTO)),
  );

export const findParent = (
  name: string,
  rootChildren: Array<ContainerTO>,
): ContainerTO | undefined =>
  find<ContainerTO>(propEq("name", name), rootChildren);

export const findElement = (
  name: string,
  parent: ContainerTO,
): ContainerTO | undefined =>
  find<ContainerTO>(propEq("name", name), pathOr([], ["parameters"], parent));

export const findColumnIndex = (
  name: string,
  rootChildren: Array<ContainerTO>,
): number => findIndex(propEq("name", name), rootChildren);

const sliceWords = (value: string) => {
  const words = split(/\s/, String(value));
  const wordCount = length(words);
  const allowedLength = MAX_VALUE_LENGTH - wordCount + 1;
  const allowedLengthPerWord = Math.trunc(allowedLength / wordCount);
  const emptySpace = allowedLength % wordCount;
  const slicedWords = addIndex<string>(map)((word, index) => {
    const toIndex =
      index < emptySpace ? allowedLengthPerWord + 1 : allowedLengthPerWord;
    return slice(0, toIndex, word);
  }, words);
  return join(" ", slicedWords);
};

const joinValues = compose(join(": "), map(sliceWords));

export const generateValue = (
  parent: ContainerTO,
  element: ContainerTO,
  value: string,
  keyFallback: string,
  valueKeyFallback: string,
): string =>
  joinValues([
    pathOr(keyFallback, ["translatedName"], parent),
    pathOr(valueKeyFallback, ["translatedName"], element),
    value,
  ]);
