import { mergeStyleSets, Stack, Text } from "@fluentui/react";
import { useMemo } from "react";

import { Section } from "../Section";
import { Dialog } from "./Dialog";
import { DialogButtons } from "./DialogButtons";

function simpleDialogStyle() {
  return mergeStyleSets({
    content: {
      padding: "5em 0",
    },
  });
}

type SimpleDialogProps = {
  isOpen: boolean;
  label: string;
  text: string;
  okText: string;
  cancelText?: string;
  dismiss(): void;
  ok(): void;
};

export function SimpleDialog({
  isOpen,
  dismiss,
  label,
  text,
  okText,
  cancelText,
  ok,
}: Readonly<SimpleDialogProps>) {
  const simpleDialogStyled = useMemo(() => simpleDialogStyle(), []);

  return (
    <Dialog isOpen={isOpen} onDismiss={dismiss}>
      <Stack>
        <Section label={label} />
        <Stack.Item className={simpleDialogStyled.content}>
          <Text variant="mediumPlus">{text}</Text>
        </Stack.Item>
      </Stack>
      <DialogButtons
        onCancel={cancelText ? dismiss : undefined}
        cancelText={cancelText}
        okText={okText}
        onOk={ok}
      />
    </Dialog>
  );
}
