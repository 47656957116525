import {
  ITheme,
  mergeStyleSets,
  Modal,
  Stack,
  Text,
  useTheme,
} from "@fluentui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ORDER_TYPE } from "../../pages/configurator/bom/request/useRequest";
import { EpriClear } from "../Icons";
import { Section } from "../Section";

function thanksDialogStyle(theme: ITheme) {
  return mergeStyleSets({
    root: {
      border: `4px solid ${theme.palette.themePrimary}`,
      background: theme.palette.white,
      padding: "20px",
      height: "176px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    label: {
      paddingRight: "0.75em",
    },
    icon: {
      svg: {
        height: "22px",
      },
    },
  });
}

interface TextContentProps {
  text?: string;
  type?: ORDER_TYPE;
}

function TextContent({ text, type }: TextContentProps): React.JSX.Element {
  const { t } = useTranslation();

  let content: string | undefined = text;
  if (!content) {
    switch (type) {
      case ORDER_TYPE.STANDARD_ORDER:
        content = t("t:glossary.checkout.confirmationModal.standardOrder.text");
        break;
      case ORDER_TYPE.E_PLAN_ORDER:
        content = t("t:glossary.checkout.confirmationModal.ePlanOrder.text");
        break;
    }
  }
  return content ? <Text variant="mediumPlus">{content}</Text> : <></>;
}

type ThanksDialog = {
  isOpen: boolean;
  label?: string;
  text?: string;
  type?: ORDER_TYPE;
  dismiss(): void;
};

export function ConfirmationDialog({
  isOpen,
  label,
  text,
  type,
  dismiss,
}: Readonly<ThanksDialog>) {
  const { t } = useTranslation();

  const theme = useTheme();
  const thanksDialogStyled = useMemo(() => thanksDialogStyle(theme), [theme]);

  function getLabel(
    label: string | undefined,
    type: ORDER_TYPE | undefined,
  ): string {
    if (label) {
      return label;
    }

    switch (type) {
      case ORDER_TYPE.STANDARD_ORDER:
        return t("t:glossary.checkout.confirmationModal.standardOrder.label");
      case ORDER_TYPE.E_PLAN_ORDER:
        return t("t:glossary.checkout.confirmationModal.ePlanOrder.label");
      default:
        return "No label specified!";
    }
  }

  return (
    <Modal isOpen={isOpen} onDismiss={dismiss}>
      <Stack onClick={dismiss} className={thanksDialogStyled.root}>
        <Stack className={thanksDialogStyled.label}>
          <Section label={getLabel(label, type)} />
          <TextContent text={text} type={type} />
        </Stack>
        <Stack onClick={dismiss} className={thanksDialogStyled.icon}>
          <EpriClear />
        </Stack>
      </Stack>
    </Modal>
  );
}
