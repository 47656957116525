import { ITheme, mergeStyleSets, TextField, useTheme } from "@fluentui/react";
import { ConfiguratorComponentProps } from "configuration";
import { equals, pathOr } from "ramda";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ParameterTO, selectedValue } from "@encoway/c-services-js-client";

import { useConfigurationContext } from "../../../context/useConfiguration";
import { CUSTOM_CONSTANTS } from "../../../utils";
import { numberUtils } from "../../../utils/numberUtils";

function inputStyle(theme: ITheme) {
  return mergeStyleSets({
    root: {
      "&.is-active div": {
        boxShadow: `0 0 0 1px ${theme.palette.neutralLight}`,
        borderColor: theme.palette.neutralLight,
      },
      "&.is-active span div": {
        boxShadow: "none",
        borderColor: theme.palette.white,
      },
    },
    wrapper: {
      border: `1px solid ${theme.palette.neutralQuaternary}`,
      borderRadius: "5px",
      overflow: "hidden",
      ":hover": {
        borderColor: theme.palette.neutralTertiary,
      },
    },
    fieldGroup: {
      border: "none",
      "::after": {
        display: "none",
      },
    },
    textField: {
      'div:has(input[aria-invalid="true"])': {
        border: `1px solid ${theme.palette.red}`,
      },
    },
  });
}

type InputState = { error: string | undefined; value: string | undefined };

export function ConfInput(
  props: Readonly<ConfiguratorComponentProps<ParameterTO>>,
) {
  const theme = useTheme();
  const inputStyled = useMemo(() => inputStyle(theme), [theme]);
  const { i18n, t } = useTranslation();
  const numUtils = numberUtils(i18n.language);
  const { onValueChanged, data } = props;
  const [{ error, value }, setInputState] = useState<InputState>({
    error: undefined,
    value: selectedValue(data),
  });
  const {
    eventBus,
    actions: { checkIdentical },
  } = useConfigurationContext();

  function numberValueChange(
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    const currentValue = event.currentTarget.value;
    if (equals(currentValue, "")) {
      setInputState((prev) => ({
        ...prev,
        value: currentValue,
        error: undefined,
      }));
    } else {
      const numValue = numUtils.convertToNumber(currentValue);
      const maxValue = Number(data.unformattedOriginalMaxValue);
      const minValue = Number(data.unformattedOriginalMinValue);
      if (isNaN(numValue)) {
        setInputState((prev) => ({
          ...prev,
          value: currentValue,
          error: `${currentValue} ${t("t:common.input.notValid")}`,
        }));
      } else if (maxValue < numValue) {
        setInputState((prev) => ({
          ...prev,
          value: currentValue,
          error: `${numValue} ${t(
            "t:common.input.higherValue",
          )} ${maxValue}${data.baseUnit?.translatedUnit}`,
        }));
      } else if (minValue > numValue) {
        setInputState((prev) => ({
          ...prev,
          value: currentValue,
          error: `${numValue} ${t(
            "t:common.input.lowerValue",
          )} ${minValue}${data.baseUnit?.translatedUnit}`,
        }));
      } else {
        setInputState((prev) => ({
          ...prev,
          value: currentValue,
          error: undefined,
        }));
      }
    }
  }

  function stringValueChange(
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    const currentValue = event.currentTarget.value;
    if (currentValue.length <= 15) {
      setInputState((prev) => ({
        ...prev,
        value: currentValue,
        error: undefined,
      }));
    }
  }

  useEffect(() => {
    setInputState((prev) => ({
      ...prev,
      value: selectedValue(data) || "",
      error: undefined,
    }));
  }, [data]);

  function onValueBlur() {
    if (value === undefined || equals(value, selectedValue(data)) || error) {
      return;
    }

    if (
      equals(data.valueType, "DRANGE") ||
      equals(data.valueType, "RANGE_DOUBLE")
    ) {
      if (equals(value, "") && data.undoable) {
        checkIdentical(data.name, "_undo_");
        return onValueChanged(data, "_undo_");
      }
      checkIdentical(data.name, numUtils.convertToNumber(value));
      return onValueChanged(data, numUtils.convertToNumber(value));
    } else if (equals(data.valueType, "STRING")) {
      if (value.length <= 15) {
        checkIdentical(data.name, value);
        return onValueChanged(data, value);
      }
    }
  }

  function onValueEnter(event: any) {
    if (event.key === "Enter") {
      event.currentTarget.blur();
    }
  }

  const onChange = {
    STRING: stringValueChange,
    DRANGE: numberValueChange,
    RANGE_DOUBLE: numberValueChange,
  };

  useEffect(() => {
    return eventBus!.onValue(async (e) => {
      if (equals(e.event, CUSTOM_CONSTANTS.MOQ_TRIGGERED)) {
        if (equals(e.name, data.name)) {
          onValueChanged(data, value || "1");
        }
      }
    });
  }, [eventBus, value]);

  return (
    <TextField
      onChange={onChange[pathOr("STRING", ["valueType"], data)]}
      className={inputStyled.textField}
      styles={inputStyled}
      value={value}
      defaultValue={selectedValue(data)}
      errorMessage={error}
      onBlur={onValueBlur}
      onKeyDown={onValueEnter}
    />
  );
}
