import {
  DefaultButton,
  ITheme,
  mergeStyleSets,
  Stack,
  useTheme,
} from "@fluentui/react";
import classNames from "classnames";
import { ConfiguratorComponentProps } from "configuration";
import { equals, map, pathOr, pipe } from "ramda";
import { useMemo } from "react";

import { ParameterTO } from "@encoway/c-services-js-client";

import { useConfigurationContext } from "../../../context/useConfiguration";

function radioStyle(theme: ITheme) {
  return mergeStyleSets({
    wrapper: {
      flexDirection: "row",
      alignItems: "center",
      ".icButtonRow": {
        width: "100%",
      },
      ".icButton": {
        paddingLeft: "0px",
        paddingRight: "0px",
        minWidth: "0px",
      },
    },
    button: {
      color: theme.palette.black,
      border: `1px solid ${theme.palette.neutralQuaternary}`,
      borderRadius: "4px",
      background: theme.palette.neutralLighterAlt,
      selectors: {
        "&:hover": {
          color: theme.palette.black,
          border: `1px solid ${theme.palette.neutralQuaternary}`,
          span: {
            fontWeight: "800",
          },
        },
        "&.selected": {
          border: `2px solid ${theme.palette.neutralPrimary}`,
          backgroundColor: theme.palette.white,
          span: {
            fontWeight: "800",
          },
        },
        "&.selected:hover": {
          border: `2px solid ${theme.palette.neutralQuaternary}`,
          span: {
            fontWeight: "800",
          },
        },
        span: {
          fontSize: "14px",
          fontWeight: "400",
        },
      },
    },
  });
}

export function ConfRadio(
  props: Readonly<ConfiguratorComponentProps<ParameterTO>>,
) {
  const {
    data: { viewPortProperties },
  } = props;
  const theme = useTheme();
  const { wrapper, button } = useMemo(() => radioStyle(theme), [theme]);
  const {
    actions: { checkIdentical },
  } = useConfigurationContext();

  const isFullwidth = useMemo(() => {
    return pipe(pathOr("", ["fullwidth"]), equals('{"1"}'))(viewPortProperties);
  }, [viewPortProperties]);

  function onChange(data: ParameterTO, value: string) {
    checkIdentical(data.name, value);
    props.onValueChanged(data, value);
  }

  return (
    <Stack className={wrapper}>
      {map(
        (value) => (
          <Stack.Item
            className={classNames({ icButtonRow: isFullwidth })}
            key={value.value}
          >
            <DefaultButton
              className={classNames(button, {
                selected: value.selected,
                icButton: isFullwidth,
              })}
              onClick={() =>
                value.selected ? null : onChange(props.data, value.value)
              }
            >
              {value.translatedValue}
            </DefaultButton>
          </Stack.Item>
        ),
        props.data.values || [],
      )}
    </Stack>
  );
}
