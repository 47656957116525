import { styled } from "@fluentui/react/lib/Utilities";

import { EpriStyles as EncowayEpriStyles } from "@encoway/cui-configurator-components";

import { EpriStyles } from "../../../../components/Dialog/EpriStyle";
import { EpriDialog } from "./EpriDialog";

export const defaultDialogMinWidth = "50rem";

const Epri = styled(EpriDialog, EncowayEpriStyles);
export const ConfEpri = styled(Epri, EpriStyles);
ConfEpri.displayName = "Epri";
