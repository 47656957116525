import {
  equals,
  findIndex,
  inc,
  isEmpty,
  isNil,
  nth,
  path,
  pathOr,
  pipe,
  prop,
  propEq,
  reject,
} from "ramda";
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { ContainerTO, ParameterTO } from "@encoway/c-services-js-client";

import { useConfigurationContext } from "./useConfiguration";
import { ProductContext } from "./useProducts";

export const VALUE_AMOUNT = 25;
const TABS_PATH = ["rootContainer", "children", 0, "children"];

type TabState = {
  openTab: OpenTab;
  setOpen: (containerName: string) => void;
  onMore: () => void;
  next: () => void;
};

interface OpenTab {
  active: string | undefined;
  last: string | undefined;
  numValues: number;
}

const INITIAL_STATE: TabState = {
  openTab: {
    active: undefined,
    last: undefined,
    numValues: VALUE_AMOUNT,
  },
  setOpen: () => new Error("setOpen is not implemented"),
  onMore: () => new Error("onMore is not implemented"),
  next: () => new Error("next is not implemented"),
};

export const TabContext = createContext<TabState>(INITIAL_STATE);

function filterTabs(tabs: ContainerTO[]): ContainerTO[] {
  return reject<ContainerTO>(
    (tab) =>
      propEq("name", "Gegenstueck", tab) ||
      isEmpty(pathOr<ParameterTO[]>([], ["parameters"], tab)),
  )(tabs);
}

export function TabContextProvider({ children }: PropsWithChildren<unknown>) {
  const { getProducts } = useContext(ProductContext);
  const { article } = useConfigurationContext();
  const [openTab, setOpenTab] = useState<OpenTab>({
    active: undefined,
    last: undefined,
    numValues: VALUE_AMOUNT,
  });
  const { guiTO } = useConfigurationContext();

  const tabs: ContainerTO[] = useMemo(
    () => filterTabs(pathOr([], TABS_PATH, guiTO)),
    [guiTO],
  );

  function onOpenTab(containerName: string): void {
    if (equals(containerName, openTab.active)) {
      setOpenTab((prev) => ({
        numValues: VALUE_AMOUNT,
        active: undefined,
        last: prev.active,
      }));
    } else {
      setOpenTab((prev) => ({
        numValues: VALUE_AMOUNT,
        active: containerName,
        last: prev.active,
      }));
    }
  }

  function onMore() {
    setOpenTab((prev) => ({
      ...prev,
      numValues: prev.numValues + VALUE_AMOUNT,
    }));
  }

  function next() {
    const newOpenTabName: string | undefined = pipe(
      findIndex(propEq("name", openTab.active)),
      inc,
      (idx: number) => nth<ContainerTO>(idx, tabs),
      prop("name"),
    )(tabs);
    if (!isNil(newOpenTabName)) {
      onOpenTab(newOpenTabName);
    }
  }

  useEffect(() => {
    (async () => {
      const data = await getProducts(article!);
      const DEFAULT_SECTION_PATH = [
        article!,
        "characteristicValues",
        "default_open_section",
        "values",
        0,
      ];
      const defaultSection = path<string>(DEFAULT_SECTION_PATH, data);
      if (defaultSection) {
        onOpenTab(defaultSection);
      }
    })();
  }, []);

  const value = useMemo(
    () => ({
      openTab: openTab,
      setOpen: onOpenTab,
      onMore,
      next,
    }),
    [openTab],
  );

  return <TabContext.Provider value={value}>{children}</TabContext.Provider>;
}

export function useTab() {
  const context = useContext(TabContext);
  if (isNil(context)) {
    throw new Error("useTab must be used within TabContext");
  }
  return context;
}
