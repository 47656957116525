import { mergeStyleSets } from "@fluentui/react";
import classNames from "classnames";
import { addIndex, map } from "ramda";
import React from "react";

import { usePartner } from "../../context/usePartner";

interface FooterLink {
  text: string;
  url: string;
  isCookie?: boolean;
  isOrange?: boolean;
}

const styles = mergeStyleSets({
  footer: {
    padding: "40px 0 20px",
    fontSize: "16px",
  },
  container: {
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "0 15px",
  },
  columns: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  column: {
    width: "22%",
    marginBottom: "20px",
  },
  columnTitle: {
    fontWeight: "bold",
    marginBottom: "15px",
    paddingBottom: "10px",
    borderBottom: "1px solid #ddd",
  },
  link: {
    color: "#9c9c9b",
    textDecoration: "none",
    display: "block",
    marginBottom: "10px",
    "&:hover": {
      textDecoration: "underline",
    },
    "&.orange": {
      color: "#ff7100",
    },
  },
  footerContent: {
    padding: "1em",
    p: {
      margin: 0,
    },
    "ol, ul": {
      margin: "0 0 0 1.1em",
      padding: 0,
    },
    table: {
      width: "100%",
    },
    "table, tr, td": {
      border: 0,
    },
    a: {
      color: "rgb(156, 156, 155)",
      textDecoration: "none",
      ":hover": {
        textDecoration: "underline",
      },
    },
  },
});

function FooterLink({ link }: { link: FooterLink }) {
  return (
    <a
      key={link.text}
      className={classNames(styles.link, {
        "ot-sdk-show-settings": link.isCookie,
        orange: link.isOrange,
      })}
      target={link.url.startsWith("http") ? "_blank" : undefined}
      rel={link.url.startsWith("http") ? "noopener noreferrer" : undefined}
      {...(!link.isCookie && { href: link.url })}
    >
      {link.text}
    </a>
  );
}

export function Footer() {
  const partnerInfo = usePartner();

  if (!partnerInfo || !partnerInfo.footer) return null;
  const footerLinks: FooterLink[][] = partnerInfo.footer;

  return (
    <footer className={styles.footer}>
      {partnerInfo.footerContent && (
        <div
          className={styles.footerContent}
          dangerouslySetInnerHTML={{ __html: partnerInfo.footerContent }}
        />
      )}
      <div className={styles.container}>
        <div className={styles.columns}>
          {addIndex<FooterLink[]>(map)(
            (column, columnIndex) => (
              <div key={columnIndex} className={styles.column}>
                {map(
                  (link) => (
                    <FooterLink key={link.text} link={link} />
                  ),
                  column,
                )}
              </div>
            ),
            footerLinks,
          )}
        </div>
      </div>
    </footer>
  );
}
