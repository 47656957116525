import { all, includes, length } from "ramda";

import { ParameterTO, Value } from "@encoway/c-services-js-client";

const NUMBER_PARAMETER_VALUE_TYPES = [
  "DOUBLE",
  "INTEGER",
  "RANGE_DOUBLE",
  "RANGE_INTEGER",
  "DRANGE",
];

/**
 * Hides infinity ranges in number values DropDown if currently not configurable
 *
 * @param {Container} data parameter container
 * @returns {Array<Value>} empty array if not yet configurable
 */
export function hiddenInvalidNumberValues(data: ParameterTO): Value[] {
  const {
    viewPort,
    valueType,
    values,
    unformattedOriginalMaxValue,
    unformattedOriginalMinValue,
  } = data;

  if (!values) {
    return [];
  }

  if (
    viewPort === "dropDown" &&
    length(values) === 1 &&
    includes(valueType, NUMBER_PARAMETER_VALUE_TYPES)
  ) {
    if (
      all(includes("Infinity"), [
        unformattedOriginalMaxValue + "",
        unformattedOriginalMinValue + "",
      ])
    ) {
      return [];
    }
  }

  return values;
}
