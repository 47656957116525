import { mergeStyleSets, Stack } from "@fluentui/react";
import { ConfiguratorComponentProps } from "configuration";
import {
  addIndex,
  all,
  equals,
  filter,
  gt,
  head,
  isEmpty,
  length,
  map,
  pathOr,
} from "ramda";
import { useContext, useMemo } from "react";

import { ContainerTO, ParameterTO } from "@encoway/c-services-js-client";
import { ComponentFactory } from "@encoway/react-configurator";

import { CustomSpinner } from "../../../../components/CustomSpinner";
import { Section } from "../../../../components/Section";
import { getColumnWidthArray } from "../../../../context/configurationUtils";
import { mediaQuery } from "../../../../theme";
import { ServoContext } from "../useServo";

function sectionStyle() {
  return mergeStyleSets({
    entryWrapper: {
      height: "100%",
      alignItems: "stretch",
      display: "flex",
      gap: "2em",
      [mediaQuery.xl]: {
        flexFlow: "column nowrap",
        height: "auto",
      },
    },
    heading: {},
    entry: {
      background: "#FFF",
      padding: "1em 0",
      flex: "1",
      [mediaQuery.md]: {
        padding: "0 0.5em",
      },
      // needed for the button selection
      [mediaQuery.sm]: {
        ".ms-Stack > .ms-Stack": {
          flexWrap: "wrap",
        },
      },
    },
    spinner: {
      padding: "2em",
    },
  });
}

function isHiddenContainer(data: ContainerTO) {
  return (
    equals(length(data.children), 0) &&
    all((parameter) => equals(parameter.viewPort, "hidden"), data.parameters)
  );
}

type ServoSectionParametersProps = {
  data: ContainerTO;
  onValueChanged: (data: ParameterTO, value: string | number) => void;
};

function ServoSectionParameters(props: Readonly<ServoSectionParametersProps>) {
  const sectionStyled = useMemo(() => sectionStyle(), []);

  const { dataSheet, isLoading, undo } = useContext(ServoContext);
  const { data, onValueChanged } = props;

  function undoValuesAndChange(parameter: ParameterTO, value: string) {
    const name = head(data.parameters)?.name;
    if (name) {
      undo(parameter.name, name).then(() => onValueChanged(parameter, value));
    }
    onValueChanged(parameter, value);
  }

  if (equals(data.name, "Datasheet_Container") && isLoading) {
    return <CustomSpinner className={sectionStyled.spinner} />;
  }

  if (data.parameters) {
    return (
      <>
        {map(
          (container) =>
            ComponentFactory.instanceOf(container.viewPort!, {
              ...props,
              onValueChanged: undoValuesAndChange,
              data: container,
              key: container.id,
            }),
          equals(data.name, "Datasheet_Container")
            ? dataSheet?.parameters || []
            : data.parameters,
        )}
      </>
    );
  }

  return null;
}

export function ServoSection({
  data,
  ...props
}: Readonly<ConfiguratorComponentProps<ContainerTO>>) {
  const sectionStyled = useMemo(() => sectionStyle(), []);

  if (isHiddenContainer(data)) {
    return null;
  }

  const containerTOs = filter(
    (c: ContainerTO) => !isHiddenContainer(c),
    data.children,
  );
  const columnWidthArray = getColumnWidthArray(data);

  return (
    <Stack key={data.id}>
      {gt(length(data.parameters), 0) && (
        <Stack className={sectionStyled.heading}>
          <Section variant={"large"} label={data.translatedName} />
        </Stack>
      )}
      {!isEmpty(containerTOs) && (
        <div className={sectionStyled.entryWrapper}>
          {addIndex<ContainerTO>(map)(
            (container: ContainerTO, index) => (
              <div
                key={container.id}
                className={sectionStyled.entry}
                style={{ flex: pathOr(1, [index], columnWidthArray) }}
              >
                <ServoSection
                  key={container.id}
                  {...{ ...props, data: container }}
                />
              </div>
            ),
            containerTOs,
          )}
        </div>
      )}
      <ServoSectionParameters
        data={data}
        onValueChanged={props.onValueChanged}
      />
    </Stack>
  );
}
