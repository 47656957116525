import { ITheme, mergeStyleSets, useTheme } from "@fluentui/react";
import { findLastIndex, gt, includes, pathOr } from "ramda";
import { useEffect, useMemo } from "react";
import { Outlet } from "react-router-dom";

import { Provider } from "../../Provider";
import { useSettings } from "../../context/useSettings";
import { pingSessionTracker } from "../../service/sessionService";
import { isStandalone } from "../../utils";
import { Footer } from "./Footer";
import { Header } from "./Header";

function configuratorStyled(theme: ITheme) {
  return mergeStyleSets({
    root: {
      display: "flex",
      flexDirection: "column",
      background: theme.palette.white,
      gap: "1em",
    },
  });
}

export function Layout() {
  const theme = useTheme();
  const { root } = useMemo(() => configuratorStyled(theme), [theme]);
  const { settings, axiosSimple } = useSettings();
  const isStandAloneApp = isStandalone();
  useEffect(() => {
    pingSessionTracker(settings, axiosSimple);
    const cleanupInterval = setInterval(
      () => pingSessionTracker(settings, axiosSimple),
      settings.tracking.sessionTrackingInterval,
    );
    return () => clearInterval(cleanupInterval);
  }, []);

  useEffect(() => {
    window.onhashchange = function (e) {
      if (!isStandAloneApp) {
        e.preventDefault();

        const { dataLayer } = window;
        const url = findLastIndex(
          (elem: DataLayerItem) =>
            !includes(
              pathOr("", ["gtm.oldUrl"], elem),
              pathOr("", ["location", "href"], window),
            ),
        )(dataLayer);
        window.location.href = gt(url, -1)
          ? dataLayer[url]["gtm.oldUrl"]
          : window.location.origin;
      }
    };
  }, []);

  return (
    <Provider>
      <div id="configurator" className={root}>
        {isStandAloneApp && <Header />}
        <Outlet />
        {isStandAloneApp && <Footer />}
      </div>
    </Provider>
  );
}
