import {
  DefaultButton,
  ITheme,
  mergeStyleSets,
  Stack,
  useTheme,
} from "@fluentui/react";

import { BasicMinus, BasicPlus } from "../../../components/Icons";

type BomAmountInputProps = {
  value: number;
  onChange: (e: any) => void;
  onDecrease: () => void;
  onIncrease: () => void;
};

function bomAmountInputStyles(theme: ITheme) {
  return mergeStyleSets({
    root: {
      border: "2px solid #bebebe",
      borderRadius: "5px",
      display: "grid",
      gridTemplateColumns: "40px 68px 40px",
      gridTemplateRows: "40px",
      gridTemplateAreas: ". . .",
      gridAutoFlow: "row",
      overflow: "hidden",
      position: "relative",
      width: "152px",
    },
    button: {
      background: theme.palette.white,
      color: theme.palette.black,
      minWidth: "none",
      boxSizing: "border-box",
      fontSize: "28px",
      fontWeight: "bold",
      outline: 0,
      padding: 0,
      height: "40px",
      ":hover": {
        background: theme.palette.white,
        color: theme.palette.black,
      },
      ":active": {
        background: theme.palette.white,
        color: theme.palette.black,
      },
      ":focus": {
        background: theme.palette.white,
        color: theme.palette.black,
      },
    },
    buttonIcon: {
      display: "flex",
      alignItems: "center",
      height: "24px",
      width: "12px",
      svg: {
        maxWidth: "100%",
      },
    },
    inputWrapper: {
      display: "flex",
      flexFlow: "column",
      position: "relative",
      "::before": {
        background: "#bebebe",
        content: "''",
        height: "28px",
        width: "2px",
        position: "absolute",
        left: 0,
        top: "calc(50% - 14px)",
        zIndex: 20,
      },
      "::after": {
        background: "#bebebe",
        content: "''",
        height: "28px",
        width: "2px",
        position: "absolute",
        right: 0,
        top: "calc(50% - 14px)",
        zIndex: 20,
      },
    },
    input: {
      background: theme.palette.white,
      border: 0,
      flexGrow: "1",
      fontFamily: "Source Sans Pro",
      fontSize: "20px",
      fontWeight: "bold",
      textAlign: "center",
      minWidth: "none",
      outline: "0",
      padding: 0,
      position: "relative",
    },
  });
}

export function BomAmountInput(props: Readonly<BomAmountInputProps>) {
  const theme = useTheme();
  const styles = bomAmountInputStyles(theme);

  const { value, onChange, onDecrease, onIncrease } = props;

  return (
    <Stack className={styles.root}>
      <DefaultButton onClick={onDecrease} className={styles.button}>
        <Stack className={styles.buttonIcon}>
          <BasicMinus />
        </Stack>
      </DefaultButton>
      <Stack className={styles.inputWrapper}>
        <input
          value={value}
          className={styles.input}
          onChange={onChange}
          type="text"
          step="1"
        />
      </Stack>
      <DefaultButton onClick={onIncrease} className={styles.button}>
        <Stack className={styles.buttonIcon}>
          <BasicPlus />
        </Stack>
      </DefaultButton>
    </Stack>
  );
}
