import { ITheme, mergeStyleSets, Stack, Text, useTheme } from "@fluentui/react";
import classNames from "classnames";
import { all, isEmpty, isNil, map, path } from "ramda";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ParameterTO } from "@encoway/c-services-js-client";

import { ChevronDownIcon, ChevronUpIcon } from "../../../components/Icons";
import { useConfigurationContext } from "../../../context/useConfiguration";
import { extractParameters, findSections } from "./bomUtils";

function bomSectionStyle(theme: ITheme) {
  return mergeStyleSets({
    root: {},
    textContainer: {
      webkitUserSelect: "none",
      msUserSelect: "none",
      userSelect: "none",
      cursor: "pointer",
      gap: ".5rem",
      ":hover": {
        span: {
          textDecoration: "underline",
        },
      },
    },
    icon: {
      justifyContent: "center",
      svg: {
        width: "1.2rem",
      },
    },
    collapsableLabel: {
      fontSize: "0.9rem",
      color: theme.palette.black,
    },
    text: {
      paddingLeft: "1.7rem",
      webkitUserSelect: "none",
      msUserSelect: "none",
      userSelect: "none",
      color: theme.palette.black,
    },
    label: {
      textOverflow: "ellipsis",
      fontWeight: 600,
    },
  });
}

function BomSectionItem({ entry }: Readonly<{ entry: ParameterTO }>) {
  const theme = useTheme();
  const bomSectionStyled = useMemo(() => bomSectionStyle(theme), [theme]);
  const value = path(["selectedValues", 0, "translatedValue"], entry);

  if (isNil(value)) {
    return null;
  }

  return (
    <Stack>
      <Text
        className={classNames(bomSectionStyled.text, bomSectionStyled.label)}
        variant={"small"}
        nowrap
      >
        {entry.translatedName}:
      </Text>
      <Text className={bomSectionStyled.text} variant={"small"}>
        {value}
      </Text>
    </Stack>
  );
}

export function BomSection({
  individualSectionNames,
}: Readonly<{ individualSectionNames?: string[] }>) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const { guiTO } = useConfigurationContext();
  const theme = useTheme();
  const bomSectionStyled = useMemo(() => bomSectionStyle(theme), [theme]);
  const [parameters, setParameters] = useState<ParameterTO[]>([]);

  useEffect(() => {
    if (guiTO?.rootContainer.children && !isNil(individualSectionNames)) {
      setParameters(
        extractParameters(
          findSections(guiTO.rootContainer, individualSectionNames, []),
        ),
      );
    }
  }, [individualSectionNames, guiTO]);

  if (all((parameter) => isEmpty(parameter.selectedValues), parameters)) {
    return null;
  }

  return (
    <Stack styles={bomSectionStyled}>
      <Stack
        className={bomSectionStyled.textContainer}
        horizontal
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <Stack className={bomSectionStyled.icon}>
          {isOpen ? <ChevronDownIcon /> : <ChevronUpIcon />}
        </Stack>
        <Text className={bomSectionStyled.collapsableLabel}>
          {t("t:glossary.checkout.individualProductData")}
        </Text>
      </Stack>
      {isOpen &&
        map((parameter) => <BomSectionItem entry={parameter} />, parameters)}
    </Stack>
  );
}
