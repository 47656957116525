import { AxiosInstance } from "axios";

import { CheckoutState } from "../pages/configurator/bom/request/useRequest";

export async function getContactDataFromSAP(
  axiosSimple: AxiosInstance,
  locale: string,
  token: string,
): Promise<CheckoutState> {
  const { data } = await axiosSimple.get(`lapp-api/secure/sap/contact-data`, {
    headers: { "Accept-Language": locale, Authorization: `Bearer ${token}` },
  });
  return data;
}
