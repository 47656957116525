import {
  ChoiceGroup,
  IChoiceGroupOption,
  mergeStyleSets,
} from "@fluentui/react";
import { useMemo } from "react";

type RequestChoiceGroupProps = {
  options: IChoiceGroupOption[];
};

function choiceGroupStyles() {
  return mergeStyleSets({
    root: {
      display: "flex",
      flexFlow: "row",
      lineHeight: "1.2",
    },
  });
}

export function RequestChoiceGroup(props: Readonly<RequestChoiceGroupProps>) {
  const { options } = props;
  const styles = useMemo(() => choiceGroupStyles(), []);

  return (
    <ChoiceGroup
      className={styles.root}
      styles={{
        flexContainer: {
          display: "flex",
          gap: "2rem",
        },
      }}
      options={options}
    />
  );
}
