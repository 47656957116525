import { Slider, mergeStyleSets, useTheme } from "@fluentui/react";
import { useMemo } from "react";

import { useRelativeCameraPosition } from "./hooks/useRelativeCameraPosition";

function componentStyle() {
  return mergeStyleSets({
    slider: {
      paddingBottom: "1em",
      height: "60%",
      cursor: "pointer",
      pointerEvents: "auto",
    },
  });
}

export function VisualizationZoomSlider() {
  const { relativeCameraPosition, onChange } = useRelativeCameraPosition();
  const theme = useTheme();
  const { slider } = useMemo(() => componentStyle(), []);
  return (
    <Slider
      className={slider}
      min={0}
      max={100}
      vertical
      value={relativeCameraPosition}
      valueFormat={(value: number) => `${value}%`}
      styles={{
        thumb: {
          borderColor: theme.palette.themePrimary,
        },
      }}
      onChange={onChange}
    />
  );
}
