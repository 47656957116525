import { ITheme, mergeStyleSets, useTheme } from "@fluentui/react";
import { pathOr } from "ramda";
import { useMemo } from "react";
import Select, { GroupBase, OptionsOrGroups } from "react-select";

type RequestDropdownProps = {
  options: OptionsOrGroups<unknown, GroupBase<unknown>>;
  placeholder: string;
  value?: any;

  onChange(value: string): void;
};

function selectStyle(theme: ITheme) {
  return mergeStyleSets({
    root: {
      fontSize: "14px",
      selectors: {
        "&.mandatory > div": {
          borderColor: theme.palette.neutralLight,
        },
        div: {
          color: theme.palette.black,
        },
      },
    },
  });
}

export function RequestDropdown(props: Readonly<RequestDropdownProps>) {
  const { options, placeholder, value, onChange } = props;
  const theme = useTheme();
  const { root } = useMemo(() => selectStyle(theme), [theme]);

  const _onChange = (value: unknown) => {
    onChange(pathOr("", ["value"], value));
  };

  return (
    <Select
      className={root}
      theme={(iTheme) => ({
        ...iTheme,
        borderRadius: 5,
        colors: {
          ...iTheme.colors,
          text: "orangered",
          primary25: theme.palette.neutralLighter,
          primary50: theme.palette.white,
          primary75: theme.palette.white,
          primary: theme.palette.neutralLight,
        },
      })}
      isSearchable={false}
      placeholder={placeholder}
      components={{ IndicatorSeparator: null }}
      styles={{
        control: (baseStyle) => ({
          ...baseStyle,
          overflow: "hidden",
        }),
      }}
      onChange={_onChange}
      value={value}
      options={options}
    />
  );
}
