import { AxiosInstance } from "axios";
import { gt, isNil, subtract } from "ramda";
import { SettingsProps } from "setting";
import { v4 as uuidv4 } from "uuid";

const SESSION_TRACKING_URL = "/api/showroom/sessiontracking/id";
const SESSION_LOCAL_STORAGE_KEY = "lapp_session_id";

export function pingSessionTracker(
  settings: SettingsProps,
  axiosSimple: AxiosInstance,
): void {
  const storedInfo = window.sessionStorage.getItem(SESSION_LOCAL_STORAGE_KEY);
  const uuidFromStorage = storedInfo ? JSON.parse(storedInfo) : null;

  const sessionInfo =
    isNil(uuidFromStorage) ||
    isNil(uuidFromStorage?.uuid) ||
    isNil(!uuidFromStorage.lastUpdated)
      ? { uuid: uuidv4(), lastUpdated: Date.now() }
      : uuidFromStorage;

  const timeSinceLastUpdate = subtract(Date.now(), sessionInfo.lastUpdated);
  const uuidToSend = gt(
    timeSinceLastUpdate,
    settings.tracking.validSessionLimit,
  )
    ? uuidv4()
    : sessionInfo.uuid;

  const itemToStore = {
    uuid: uuidToSend,
    lastUpdated: Date.now(),
  };
  window.sessionStorage.setItem(
    SESSION_LOCAL_STORAGE_KEY,
    JSON.stringify(itemToStore),
  );

  axiosSimple.post(`${SESSION_TRACKING_URL}/${uuidToSend}`).catch(() => null);
}
