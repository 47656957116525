import { Unsub } from "baconjs";
import { useEffect, useRef, useState } from "react";
import { Mesh, Object3D } from "three";

import { useScene } from "./useScene";
import { useVisualization } from "./useVisualization";

window.THREE = window.THREE || {};

/**
 * Hook for adding a dynamic shadow under the objects in the scene.
 * The plane object resizes dynamically, when a node in the scene is added, removed or updated
 */
export const useShadow = () => {
  const scene = useScene();
  const [shadowPlane, setShadowPlane] = useState<Mesh | null>(null);

  const shadowPlaneRef = useRef(shadowPlane);
  const sceneRef = useRef(scene);

  const { vis } = useVisualization();

  useEffect(() => {
    shadowPlaneRef.current = shadowPlane;
    sceneRef.current = scene;
  }, [scene, shadowPlane]);

  useEffect(() => {
    if (!scene || shadowPlane || scene?.getObjectByName("#ShadowPlane")) return;

    const geometry = new window.THREE.PlaneGeometry(1, 1);
    const material = new window.THREE.ShadowMaterial();
    material.opacity = 0.1;

    const plane = new window.THREE.Mesh(geometry, material);
    plane.receiveShadow = true;

    updatePlaneSize();

    plane.rotation.set(-Math.PI / 2, 0, 0);
    plane.name = "#ShadowPlane";

    setShadowPlane(plane);
    scene.add(plane);

    return () => {};
  }, [scene, shadowPlane]);

  const updatePlaneSize = () => {
    if (shadowPlaneRef.current && sceneRef.current) {
      const meshes: Object3D[] = [];
      sceneRef.current.traverse((object: any) => {
        if (
          object.type === "Mesh" &&
          object.name !== "#ShadowPlane" &&
          object.name !== "placeholder_mesh"
        ) {
          meshes.push(object);
        }
      });

      if (meshes.length >= 1) {
        const box = new window.THREE.Box3().setFromObject(meshes[0]);
        meshes.forEach((mesh) =>
          box.union(new window.THREE.Box3().setFromObject(mesh)),
        );
        const boxSize = box.getSize(new window.THREE.Vector3());
        shadowPlaneRef.current.scale.set(boxSize.x * 2, boxSize.y * 2, 1);
        const planeY = box.min.y - Math.abs(box.min.y) * 0.8;
        shadowPlaneRef.current.position.set(0, planeY, 0);
      }
    }
  };

  useEffect(() => {
    let unsubscribe: Unsub;
    if (vis.context) {
      unsubscribe = vis.context.cloud
        .graph()
        .eventBus()
        .filter(
          (e: any) =>
            e.event === "NodeUpdated" ||
            e.event === "NodeMounted" ||
            e.event === "NodeRemoved",
        )
        .onValue(updatePlaneSize);
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [shadowPlane, vis]);
};
