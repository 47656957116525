import { all, equals, length } from "ramda";

import { ContainerTO } from "@encoway/c-services-js-client";

export function isHiddenContainer(data: ContainerTO) {
  return (
    (equals(length(data.children), 0) &&
      all(
        (parameter) => equals(parameter.viewPort, "hidden"),
        data.parameters,
      )) ||
    equals(data.name, "Visualization")
  );
}
