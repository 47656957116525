import { BomCharacteristic, BomProduct } from "bom";
import { Products } from "product";
import {
  equals,
  filter,
  gte,
  includes,
  multiply,
  pathOr,
  pipe,
  reduce,
} from "ramda";

export type InvalidProduct = {
  id: string;
  currentProduct: string;
  description: string;
  alternateProductId: string;
  enabled: string;
  value: string;
};

export function isMoqReached(
  currentValue: number,
  currentAmount: number,
  expectedValue: number,
): boolean {
  return gte(multiply(currentValue, currentAmount), expectedValue);
}

export function toInvalidProducts(
  amount: number,
): (acc: InvalidProduct[], elem: BomProduct) => InvalidProduct[] {
  const RELEVANT_CHARACTERISTICS = [
    "moq_description",
    "moq_alternate_product",
    "moq_enabled",
    "moq_value",
  ];

  const filterBy = filter<BomCharacteristic>((characteristic) =>
    includes(characteristic.characteristicId, RELEVANT_CHARACTERISTICS),
  );

  const toCharacteristicValues = pipe(
    filterBy,
    reduce(
      (acc, cur) => ({
        ...acc,
        [pathOr("", ["characteristicId"], cur)]: pathOr("", ["values", 0], cur),
      }),
      {},
    ),
  );

  return function (
    acc: InvalidProduct[],
    product: BomProduct,
  ): InvalidProduct[] {
    const characteristicValues = toCharacteristicValues(
      pathOr<BomCharacteristic[]>([], ["productCharacteristics"], product),
    );

    const currentValue = pathOr(0, ["amount"], product);
    const expectedValue = parseInt(
      pathOr("0", ["moq_value"], characteristicValues),
    );

    const moqEnabled = equals(
      "1",
      pathOr("0", ["moq_enabled"], characteristicValues),
    );
    const moqReached = isMoqReached(currentValue, amount, expectedValue);

    if (moqReached || !moqEnabled) {
      return acc;
    }

    const added: InvalidProduct = {
      id: pathOr("", ["productId"], product),
      currentProduct: pathOr("", ["translatedProductName"], product),
      description: pathOr("", ["moq_description"], characteristicValues),
      alternateProductId: pathOr(
        "",
        ["moq_alternate_product"],
        characteristicValues,
      ),
      enabled: pathOr("", ["moq_enabled"], characteristicValues),
      value: pathOr("", ["moq_value"], characteristicValues),
    };

    return [...acc, added];
  };
}

function toProductNames(products: Products) {
  return (acc: { [key: string]: string }, product: InvalidProduct) => {
    return {
      ...acc,
      [product.alternateProductId]: pathOr(
        "",
        [product.alternateProductId, "name"],
        products,
      ),
    };
  };
}

export function getInvalidProductNames(
  invalidProducts: InvalidProduct[],
  products: Products,
) {
  return reduce<InvalidProduct, { [key: string]: string }>(
    toProductNames(products),
    {},
    invalidProducts,
  );
}
