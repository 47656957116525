import { ITheme, mergeStyleSets, useTheme } from "@fluentui/react";
import { useMemo } from "react";

import { mediaQuery } from "../../../theme";
import { Visualization } from "../../visualization/Visualization";
import { Configurator } from "../Configurator";
import { Bom } from "../bom/Bom";

function configurationStyle(theme: ITheme) {
  return mergeStyleSets({
    root: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gap: "0",
    },
    configurator: {
      backgroundColor: theme.palette.white,
      flex: "0 1 75%",
      padding: ".5em",
      [mediaQuery.md]: {
        padding: ".5em 0 0",
      },
    },
    bom: {
      flex: "0 0 286px",
      padding: ".5em",
      [mediaQuery.md]: {
        padding: ".5em .5em 1em",
      },
    },
    wrapper: {
      display: "flex",
      flexDirection: "row",
      gap: "1em",
      [mediaQuery.md]: {
        flexDirection: "column",
      },
    },
  });
}

export function TrolleyConfigurator() {
  const theme = useTheme();
  const configurationStyled = useMemo(() => configurationStyle(theme), [theme]);
  const { root, configurator, bom, wrapper } = configurationStyled;

  return (
    <div className={root}>
      <Visualization />
      <div className={wrapper}>
        <div className={configurator}>
          <Configurator />
        </div>
        <div className={bom}>
          <Bom />
        </div>
      </div>
    </div>
  );
}
