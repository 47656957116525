import {
  always,
  equals,
  filter,
  find,
  identity,
  ifElse,
  pipe,
  replace,
  split,
  toLower,
} from "ramda";

import { ContainerTO, ParameterTO } from "@encoway/c-services-js-client";

import { ViewPortProperties } from "./VisualizationProvider";

export const parseValues: Record<string, (val: string) => any> = {
  vis_ref: split(","),
  vis_default_value: identity,
  vis_min_width: ifElse(equals("0"), always("inherit"), identity),
  vis_order: Number,
  vis_centered: pipe(Number, Boolean),
  vis_mirror: pipe(Number, Boolean),
  vis_stretched: pipe(Number, Boolean),
  vis_background_image: pipe(Number, Boolean),
  vis_type: toLower,
};

export const escapeProperties =
  (props: any) =>
  (
    acc: ViewPortProperties,
    ele: keyof ViewPortProperties,
  ): ViewPortProperties => {
    const newValue = replace(/^{"?([^}"]+)"?}$/, "$1", props[ele] || "");
    const fn = parseValues[ele] ?? ((val: any) => val);
    return { ...acc, [ele]: newValue === "{}" ? fn("0") : fn(newValue) };
  };

const parseParameterReference = (
  ref: string,
): { name: string; index: number } => {
  const match = ref.match(/^(.+)\[(\d+)]$/);
  if (match) {
    return { name: match[1], index: parseInt(match[2], 10) };
  }
  return { name: ref, index: 0 };
};

export const getReference = (
  rootContainer: ContainerTO[],
  vis_ref: string[],
): ParameterTO | undefined => {
  if (equals(vis_ref.length, 0)) return undefined;

  const [head, ...tail] = vis_ref;
  const container = find((c) => equals(c.name, head), rootContainer);

  if (!container) return undefined;

  if (equals(tail.length, 1)) {
    const { name, index } = parseParameterReference(tail[0]);
    const matchingParams = filter(
      (param) => param.name === name,
      container.parameters,
    );

    return matchingParams[index];
  }

  return getReference(container.children, tail);
};
