import { ITheme, mergeStyleSets, Stack, Text, useTheme } from "@fluentui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Dialog } from "../../../components/Dialog/Dialog";
import { DialogButtons } from "../../../components/Dialog/DialogButtons";
import { Section } from "../../../components/Section";

function loginDialogStyle(theme: ITheme) {
  return mergeStyleSets({
    content: {
      padding: "4em 0",
      color: "black",
    },
    text: {
      color: theme.palette.black,
    },
  });
}

type SimpleDialogProps = {
  isOpen: boolean;
  dismiss(): void;
  login(): void;
  request(): void;
};

export function BomLoginReset({
  isOpen,
  dismiss,
  login,
  request,
}: Readonly<SimpleDialogProps>) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { content, text } = useMemo(() => loginDialogStyle(theme), [theme]);

  return (
    <Dialog isOpen={isOpen} onDismiss={dismiss}>
      <Stack>
        <Section label={t("t:common.login.label")} />
        <Stack.Item className={content}>
          <Text className={text} variant="mediumPlus">
            {t("t:common.login.text")}
          </Text>
        </Stack.Item>
      </Stack>
      <DialogButtons
        onCancel={login}
        cancelText={t("t:common.button.login")}
        okText={t("t:common.button.guestOrder")}
        onOk={request}
      />
    </Dialog>
  );
}
