import { AxiosInstance, AxiosPromise } from "axios";
import { replace, slice } from "ramda";

import { ConfigurationStatus, GuiTO } from "@encoway/c-services-js-client";

/**
 * Loads a configuration from a saved ID.
 *
 * @param axios
 * @param uuid The UUID (Universally Unique Identifier) of the saved configuration.
 * @return An AxiosPromise that resolves to a Configuration object.
 */
export function loadConfigurationFromSavedId(
  axios: AxiosInstance,
  uuid: string,
): AxiosPromise<ConfigurationStatus> {
  return axios.get<ConfigurationStatus>(
    `lapp-api/configured-material/load/${uuid}`,
  );
}

/**
 * Saves a configuration from a session ID.
 *
 * @param axios
 * @param configurationId The ID of the configuration to be saved.
 * @return An AxiosPromise that resolves to an object containing the saved UUID.
 */
export function saveConfigurationFromSessionId(
  axios: AxiosInstance,
  configurationId: string,
): AxiosPromise<{ uuid: string }> {
  return axios.post<{ uuid: string }>(
    `lapp-api/configured-material/save/${configurationId}`,
  );
}

/**
 * Sets the right confection identical to the left one.
 *
 * @param axios
 * @param configurationId The ID of the configuration.
 * @return An AxiosPromise that resolves to an object containing the GuiTO.
 */
export function doChangeStateIdenticalConfiguration(
  axios: AxiosInstance,
  configurationId: string,
): AxiosPromise<GuiTO> {
  return axios.get<GuiTO>(
    `lapp-api/custom-configuration/checkedIdentical/${configurationId}`,
  );
}

/**
 * Sets the new value to all parameters with the same name.
 *
 * @param axios
 * @param configurationId The ID of the configuration.
 * @param parameterName The Parameter Name from the changed Value.
 * @param newValue The new Value which will be set.
 */
export function forceParameter(
  axios: AxiosInstance,
  configurationId: string,
  parameterName: string,
  newValue: string | number,
): AxiosPromise<GuiTO> {
  return axios.get<GuiTO>(
    `lapp-api/custom-configuration/configure/${configurationId}/force/?parameterName=${parameterName}&value=${newValue}`,
  );
}

/**
 * gets a custom gui view
 * @param axios
 * @param configurationId
 * @param guiModel
 * @param language
 * @param mappingProfile
 */
export function getCustomConfigurationView(
  axios: AxiosInstance,
  configurationId: string,
  guiModel: string,
  language: string,
  mappingProfile: string = "MINIMUM_CONTENT_MAPPING",
) {
  // de-AT will be "de" - custom hack for PSU Error will be fixed in 25.0.x
  const acceptLanguage = replace(/-[^-]+/, "", language);
  return axios.get<GuiTO>(
    `api/configuration/configurations/${configurationId}?mappingProfile=${mappingProfile}&guiModel=${guiModel}`,
    {
      headers: {
        "Accept-Language": acceptLanguage,
        Accept: "application/vnd.encoway.conf.src.gui+json",
      },
    },
  );
}

/**
 * Gets the saved configuration
 * @param axios
 * @param configurationId
 * @param language
 */
export async function getConfigurationBlob(
  axios: AxiosInstance,
  configurationId: string,
  language: string,
): Promise<string> {
  const {
    data: { data },
  } = await axios.get<{ data: string }>(
    `api/configuration/configurations/${configurationId}`,
    {
      headers: {
        Accept: "application/vnd.encoway.conf.save+json",
        "Accept-Language": slice(0, 2, language),
      },
    },
  );
  return data;
}

/**
 * Gets the datasheet of a selector product
 *
 */
export async function getSelectorDatasheet(
  axios: AxiosInstance,
  configurationId: string,
): Promise<any> {
  const { data } = await axios.get<any>(
    `lapp-api/servocable/datasheet/${configurationId}`,
  );
  return data;
}

export async function deleteParameter(
  axios: AxiosInstance,
  configurationId: string,
  parameterId: string,
): Promise<{ message?: string; status: "FAILED" | "SUCCESS" }> {
  const { data } = await axios.delete<{
    message?: string;
    status: "FAILED" | "SUCCESS";
  }>(
    `api/configuration/configurations/${configurationId}/parameters/${parameterId}`,
  );
  return data;
}
