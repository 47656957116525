import { ConfiguratorComponentProps } from "configuration";
import { equals, findIndex, propEq, reduce, splitAt, toLower } from "ramda";

import { ContainerTO, ParameterTO } from "@encoway/c-services-js-client";

export function deepParameterToUndoSearch(
  parameterId: string,
  containerId: string,
  containers: ContainerTO[],
): [ParameterTO[], ParameterTO[]] | undefined {
  const container = reduce(findContainer(containerId), undefined, containers);
  if (container) {
    const index = findIndex(propEq("name", parameterId), container.parameters);
    return splitAt(index, container.parameters);
  }
  return undefined;
}

function findContainer(name: string) {
  return function (
    acc: ContainerTO | undefined,
    container: ContainerTO,
  ): ContainerTO | undefined {
    if (acc) {
      return acc;
    }
    if (equals(toLower(container.name!), toLower(name))) {
      return container;
    }
    return reduce(findContainer(name), undefined, container.children);
  };
}

export function prepareSectionProps(
  props: ConfiguratorComponentProps<ParameterTO>,
  data: ParameterTO,
) {
  return {
    ...props,
    data,
  };
}
