import { useEffect, useState } from "react";

import { useScene } from "./useScene";

export const useCameraControls = () => {
  const [cameraDistance, setCameraDistance] = useState(0);
  const [cameraControls, setCameraControls] = useState<any>();

  const scene = useScene();

  useEffect(() => {
    if (scene) {
      const camera =
        scene.getObjectByName("#Camera") ||
        new window.THREE.PerspectiveCamera();
      if (camera.cameraControls) {
        setCameraControls(camera.cameraControls);
      }
    }
  }, [scene]);

  useEffect(() => {
    if (cameraControls) {
      cameraControls.mouseButtons.right = 0;
      cameraControls.addEventListener("update", updateCameraStates);
    }

    return () => {
      if (cameraControls) {
        cameraControls.removeEventListener("update", updateCameraStates);
      }
    };
  }, [cameraControls]);

  function updateCameraStates() {
    setCameraDistance(cameraControls.distance);
  }

  return {
    cameraControls,
    cameraDistance,
  };
};
