import { ITheme, mergeStyleSets, TextField, useTheme } from "@fluentui/react";
import { useMemo } from "react";

type RequestTextFieldProps = {
  value: string;
  placeholder?: string;
  disabled?: boolean;

  onChange(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ): void;
};

function inputStyle(theme: ITheme) {
  return mergeStyleSets({
    root: {
      "&.is-active div": {
        boxShadow: `0 0 0 1px ${theme.palette.neutralLight}`,
        borderColor: theme.palette.neutralLight,
      },
      "&.is-active span div": {
        boxShadow: "none",
        borderColor: theme.palette.white,
      },
    },
    wrapper: {
      border: `1px solid ${theme.palette.neutralQuaternary}`,
      borderRadius: "5px",
      overflow: "hidden",
      ":hover": {
        borderColor: theme.palette.neutralTertiary,
      },
    },
    fieldGroup: {
      border: "none",
      height: "36px",
      "::after": {
        display: "none",
      },
    },
  });
}

export function RequestTextField(props: Readonly<RequestTextFieldProps>) {
  const {
    value,

    onChange,
  } = props;

  const theme = useTheme();
  const inputStyled = useMemo(() => inputStyle(theme), [theme]);

  return <TextField onChange={onChange} styles={inputStyled} value={value} />;
}
